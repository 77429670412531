import React from 'react';
import type { FC, ReactNode } from 'react';
import {useCallback,useEffect,useState } from 'react';
import { Redirect,useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import axios from 'src/utils/axios';
interface Redirect301GuardProps {
  children?: ReactNode;
}

const Redirect301Guard: FC<Redirect301GuardProps> = ({ children }) => {
 
  const [config, setConfig] = useState('');
  const [data, setData] = useState<any>();
  const responseSetback = async ()=>{


  let url = 'api/website/redirecturl/config';


  try {

    let response = await axios.get(url);
   

    if (response.data.status) {
      let redirectToken=localStorage.getItem('redirectToken');
     
   

      if(redirectToken!=response.data.data)
      {
        responseSetbackNew();
        localStorage.setItem('redirectToken', response.data.data);
       
      }
     
}
  } catch (err) {
    console.error(err);

   
  }
}
function search(nameKey, myArray){
  for (let i=0; i < myArray.length; i++) {
      if (myArray[i].old_url === nameKey) {
          return myArray[i];
      }
  }
}

const responseSetbackNew = async ()=>{


  let url = 'api/website/redirecturl/get?page=1&size=300';


  try {

    let response = await axios.get(url);

    localStorage.setItem('redirecturls',JSON.stringify(response.data.data) );
    if (response.data.status) {

    
}
  } catch (err) {
    console.error(err);

   
  }
}
responseSetback();

  let redirecturls= JSON.parse(localStorage.getItem('redirecturls'));
 

  const location = useLocation();
  if(redirecturls)
  {

 
    const resultObject = search(location.pathname, redirecturls);

    if (resultObject && resultObject.new_url) {
      return <Redirect to={resultObject.new_url} />;
    }
  }
  return (
    <>
      {children}
    </>
  );
};

Redirect301Guard.propTypes = {
  children: PropTypes.node
};

export default Redirect301Guard;
