import React, { FC } from 'react';
import './styles.css';

const Breadcrumbs: FC<{ recognition: any }> = ({ recognition }) => {

    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">
                           {/*  <h4>Recognition and <span>Approval</span></h4> */}
                            {/* <div className="spac_02"></div> */}
                            <div className="tab-pane fade show active" id="overview">
                            <span dangerouslySetInnerHTML={{ __html: recognition.editor }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;