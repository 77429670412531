import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import FeaturedNews from './FeaturedNews'; 
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const NewsList: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const { slug } = useParams();
  const [page, setPage] = useState(1);
  const [page1, setPage1] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetching1, setIsFetching1] = useState(false);
  const [newsListing, setNewsListing] = useState([]);
  const [newsListingNew, setNewsListingNew] = useState([]);  
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPages1, setTotalPagesNew] = useState(1);
  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [topNewsArr, setNewsArr] = useState([]);  
  const [isFetNewsLoaded, setFetNewsLoaded] = useState(false);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);
    setCourseStreamArr(heroResp.data.data.course_streams_head);    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const getNewsData = useCallback(async (prevPageNumber,newsListing) => {
    setIsFetching(true);
    try {     
      const params = {}
      params['page'] = prevPageNumber;
      params['news_type'] = 'entrance_exam';
      params['size'] = '8';
      params['columnname'] = 'created_at';
      params['orderby'] = 'desc';
      const response = await axios.get('api/website/home/news', { params: params  });
      if (isMountedRef.current){
        setTotalPages(response.data.totalPages);        
        setPage(prevPageNumber);
        setNewsListing(response.data.data);
      //  setNewsListing([...newsListing,...response.data.data]);
        setIsFetching(false);        
      }      
    } catch (err) {
      // console.error(err);
    }
  }, [isMountedRef]);

  

  const getNewsEventData = useCallback(async (prevPageNumber1,newsListingNew) => {
    setIsFetching1(true);
    try {
      const paramss = {}
      paramss['page'] = prevPageNumber1;
      paramss['news_type'] = 'general_news'; //general_news
      paramss['size'] = '8';
      paramss['columnname'] = 'created_at';
      paramss['orderby'] = 'desc';
      const response1 = await axios.get('api/website/home/news', { params: paramss  });
      if (isMountedRef.current) {
        setTotalPagesNew(response1.data.totalPages);
        setPage1(prevPageNumber1);
        setNewsListingNew(response1.data.data);
        //setNewsListingNew([...newsListingNew, ...response1.data.data]);        
        setIsFetching1(false); 
      }
    } catch (err) {
      // console.error(err);
    }
  }, [isMountedRef]);

  const getFetNewsData = async ()=>{
    const paramss = {}
    paramss['orderby'] = 'asc';
    paramss['is_top_featured'] = 1;
    paramss['columnname'] = 'top_featured_order';
    paramss['size'] = 20;
    const blogResp = await axios.get('api/website/home/news', { params: paramss  });
    setNewsArr(blogResp.data.data);
    setFetNewsLoaded(true);
  }

  const getCollageUnivData = async ()=>{
    const params = {}
    params['page'] =page;
    params['size'] = '8';
    const univColgResp = await axios.get('api/website/home/topcollegebangalore', {params: params});
    //console.log(univColgResp);
    //console.log('her');
    setTopCollegeArr(univColgResp.data.data);    
  }

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=All_News_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getnewspagedata = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/news-and-event');
      if (isMountedRef.current) {
          //setboardpagedata(response.data.data);
          setmetatitle(response.data.data.meta_title)
          setmetadescription(response.data.data.meta_description)  
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);


  const handlePageChange = (event: any, value: number): void => {
    getNewsData(value, newsListing);
  };
  const handlePageChange1 = (event: any, value: number): void => {
    getNewsEventData(value,newsListingNew);
  };


  useEffect(()=>{
    getHeroData();
    getNewsData(page,newsListing);
    getNewsEventData(page1,newsListingNew);
    getCollageUnivData();
    getFetNewsData();
    getpromobanner();
    getnewspagedata();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}news-and-event`} />
        </Helmet>
        <Header newsListing={newsListing}/>
        <Breadcrumbs newsListing={newsListing}/>
        {isFetNewsLoaded && <>
          <FeaturedNews topNewsArr={topNewsArr}/>
        </>}
        <OverviewSection newsListing={newsListing} topCollegeArr={topCollegeArr} totalPages={totalPages} totalPages1={totalPages1} handlePageChange={handlePageChange} handlePageChange1={handlePageChange1} newsListingNew={newsListingNew} isFetching={isFetching} isFetching1={isFetching1} getNewsData={getNewsData} getNewsEventData={getNewsEventData} page={page} page1={page1} promoBannerArr={promoBannerArr} />
        {/* <Talk/> */}
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default NewsList;