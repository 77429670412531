import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';

import { pConfig } from 'src/config';
import Helmet from 'react-helmet';
import FaqJsonPage from 'src/components/FaqJsonPage';


const useStyles = makeStyles(() => ({
    root: {}
}));

const CourseViewPage: FC = () => {
    const classes = useStyles();
    const { slug } = useParams();

    const setCtx = useContext(SettingsContext);
    const history = useHistory();
    const [courseStreamArr, setCourseStreamArr] = useState([]);
    const [topCollegeArr, setTopCollegeArr] = useState([]);
    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [isTopColLoaded, setTopColLoaded] = useState(false);
    const [isBlogLoaded, setBlogLoaded] = useState(false);
    const isMountedRef = useIsMountedRef();
    const [coursedetail, setcoursedetail] = useState<any>([]);

    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);
    const [newcourseStreamArr, setNewCourseStreamArr] = useState<any>([]);
    const [courseCollegedata, setCourseCollegedata] = useState<any>([]);

    const [isPageLoaded, setPageLoaded] = useState(false);
    const [isCourseLoaded, setCourseLoaded] = useState(false);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);
        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        setHeroLoaded(true);
    }

    const getCollageUnivData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "college" }]));
        const univColgResp = await axios.post('api/website/home/topcrousel', formData);

        setTopCollegeArr(univColgResp.data.data.topcollege.data);
    }
    
    const getdatabystreamid = useCallback(async () => {
        try {
            const id = slug;//'engineering';
            const response = await axios.get('api/admin/stream/get/' + id);
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                if(response.data.data1 && response.data.data1.colllegegroup)
                    setCourseCollegedata(response.data.data1.colllegegroup);
                setcoursedetail(response.data.data);
                setmetatitle(response.data.data.meta_title)
                setmetadescription(response.data.data.stream_description)
                //console.log(response.data.data1.colllegegroup) 
                setPageLoaded(true);
                setCourseLoaded(true);
            }
        } catch (err) {
            history.push("/404");
            console.error(err);
        }
    }, [isMountedRef]);

    const getCourseData = async ()=>{
        const formData = new FormData();
        //formData.append('getdata', JSON.stringify([{data: "college"},{data: "university"},{data: "exam"},{data: "testimonial"}]));
        const getstreams = await axios.get('api/website/home/streams?columnname=listing_order&orderby=asc&home_view_status=home_page&page=1&size=10000');
        setNewCourseStreamArr(getstreams.data.data);
    }

    const formattedData = coursedetail.faqs && coursedetail.faqs.map((item) => ({
        "@type": "Question",
        "name": item.questions,
        "acceptedAnswer": {
            "@type": "Answer",
            "text": item.answers,
        },
    }));

    useEffect(() => {
        getHeroData();
        getCollageUnivData();
        getdatabystreamid();
        getCourseData();
    }, [getdatabystreamid, slug]);

    if (!slug) {
        return null;
    }

    return (
        <>
            {
            isCourseLoaded ? <>
            <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription} />
                <link rel="canonical" href={`${pConfig.siteUrl}courses/${slug}`} />
                <script type="application/ld+json">
                {JSON.stringify(
                    {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": formattedData,
                    }
                )}
                </script>
            </Helmet>
            <Header coursedetail={coursedetail} />
            <Breadcrumbs  coursedetail={coursedetail}/>
            <OverviewSection coursedetail={coursedetail} newcourseStreamArr={newcourseStreamArr} />
            {/* {isTopColLoaded && <> */}
                <TopColleges topCollegeArr={topCollegeArr} courseCollegedata={courseCollegedata}  coursedetail={coursedetail}/>
            {/* </>} */}
            </>
            :
            <div style={{ display: 'flex', justifyContent: 'center', height:'600px',flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div> }
        </>
    );
};

export default CourseViewPage;