import React, { FC, useState } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses.jpg';
import CallNow from 'src/assets/img/call-now.svg';
import SearchIcon from 'src/assets/img/icons/search.svg';
import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
const SearchHeader: FC < {coursedetail:any,subcourse:any}> = ({coursedetail,subcourse})=> {
    const [num, setNum] = React.useState(331231);
    const imageUrl = coursedetail.logo ? config.baseUrlfromconfig + coursedetail.logo : ManagementCourseBG;
    return (
        <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${imageUrl})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>{subcourse.course_stream_name}</h1>
                            <div className='floatleft'>
                            <GlobalPopupEnquiry  className="apply-now-btn" />
                            </div>
                            
{/*                             <p>Diploma in Dental Mechanic is a study of diagnosing dental problems of a patient. The basic eligibility criteria to get admission in diploma in a dental mechanic is 10th class or its equivalent course from a recognised board.</p>
 */}                            
                            
                            {/* <ul> */}
                                {/* {coursedetail.str && coursedetail.str.slice(0, 10).map(i => {
                                    return (
                                        <>
                                            <li>{i.course_stream_name}</li>
                                        </>
                                    )
                                })} */}
                                {/* <li>Post  Graduate Diploma in Management - PGDM</li> 
                                <li>Master of Commerce - M.com</li>
                                <li>Master in Computer Management - MCM</li>
                                <li>Post Graduate Diploma in Business Analytics - PGDBA</li>
                                <li>Post Graduate Program in Management - PGM</li>
                                <li>Master of Financial Management - MFM</li>
                                <li>Master in HOspital Management - MHM </li>
                                <li>Master of Philosophy in Management - Phil</li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="spac_02"></div>
                    {/* <div className="col-lg-12 button-section">
                        <a href="#" className="medical-button btn">Apply Now</a>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;