import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const BscNursing: FC = () => {


  return (
    <> <Helmet>
      <title>Admission Open for Nursing Colleges in Bangalore.</title>
      <meta name="description"
        content="Admissions are open in top Nursing colleges in Bangalore. Bangalorestudy offers admission guidelines to get your direct seat in a Nursing course. Apply Now." />
      <link rel="canonical" href="https://bangalorestudy.com/bsc-nursing" />
      <meta name="designer" content="Imagino, https://imaginosolutions.com" />
      <meta
        name="viewport"
        content="width=device-width; initial-scale=1.0; maximum-scale=1.0; user-scalable=0;"
      />
      <link rel="stylesheet" href="preprod/landing_sites_asset/css/style.css" />
      <link rel="stylesheet" href="preprod/landing_sites_asset/css/resp.css" />
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.css"
      />
      <script>
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'AW-990332405');
        `}
      </script>


      <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
        new Date().getTime(), event: 'gtm.js'
			}); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-MGDJ5RD');
    `}</script>

      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-990332405"></script>
      <script src="preprod/medical_admission_asset/js/jquery.min.js"></script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-140595938-1"></script>





      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.1.3/jquery.min.js"></script>
      <script src="https://cdn.jsdelivr.net/jquery.slick/1.4.1/slick.min.js"></script>

      <script src="preprod/landing_sites_asset/script/script.js"></script>



      <script type="text/javascript">{`

setTimeout(function () {
  $('.widget-visible').remove();
}, 15000);
setTimeout(function () {
  $('.widget-visible').remove();
}, 10000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 5000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 3000);

setTimeout(function () {
  $('.widget-visible').remove();
}, 2000);


setTimeout(function () {
  $('.widget-visible').remove();
}, 1000);
$(document).ready(function () {
  $("#formoid").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/

    var posting = $.post(url, {
      name: $('#name').val(),
      email: $('#email').val(),
      contact: $('#contact').val(),
      college_name: $('#college_name').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location').val(),
      // description: $('#description').val(),

    });

    // console.log($('#name').val(),$('#email').val(), $('#contact').val(),$('#college_name').val(),);

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid').each(function () {
        this.reset();
      });
    });
  });
  $("#formoid1").submit(function (event) {
    console.log("12");
    /* stop form from submitting normally */
    event.preventDefault();

    /* get the action attribute from the <form action=""> element */
    var $form = $(this),
      url = $form.attr('action');

    /* Send the data using post with element id name and name2*/
    var posting = $.post(url, {
      name: $('#name1').val(),
      email: $('#email1').val(),
      contact: $('#contact1').val(),
      college_name: $('#college_name1').val(),
      current_url: window.location.href,
      // course_in_mind: $('#course_name').val(),
      location: $('#location1').val(),
      // description: $('#description1').val(),

    });

    /* Alerts the results */
    posting.done(function (data) {
      // console.log(data);
      // console.log("1");
      $('#result').text('Thank you we will get back to you');
      $('#result').addClass('alert alert-success');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();

      $('#formoid1').each(function () {
        this.reset();
        location.href = "https://bangalorestudy.com/thank-you"
      });

    });
    posting.fail(function () {
      // console.log(data);
      // console.log("2");
      $('#result').text('Sorry please try again');
      $('#result').addClass('alert alert-warning');
      $('#result').addClass('mt-1');
      $("#result").show().delay(5000).fadeOut();
      $('#formoid1').each(function () {
        this.reset();
      });
    });
  });
})


`}
      </script>

    </Helmet>


      <header>
        <div className="container">
          <div className="header">
            <div className="logo">
              <a href="#">
                <img
                  src="preprod/landing_sites_asset/images/logo.png"
                  alt="Bangalore Study"
                />
              </a>
            </div>
            <div className="tollfree">
              <a href="tel:18001208696" className="tollfree-no">
                Toll Free: 1800 120 8696
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="banner01-section">
        <div className="container">
          <div className="banner01-contents">
            <div className="row">
              <div className="col-sm-6 banner-head">
                <h2>B.Sc Nursing</h2>
                <p>
                  Become an angel for the sick and cure them by providing care and
                  encouragement
                </p>
              </div>
              <div className="col-sm-6 form-content">
                {/* <form action="{{route('pro.enquiry" method="post" class="bpt-form"> */}
                <form
                  id="formoid"
                  action=" https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                  className="bpt-form"
                  title=""
                  method="post"
                >
                  <h2>Admission Guidance</h2>
                  <input
                    placeholder="Name"
                    id="name"
                    name="name"
                    type="text"
                    required
                  />
                  <input
                    placeholder="Email"
                    id="email"
                    type="email"
                    required
                    name="email"
                  />
                  <input
                    placeholder="Contact Number"
                    id="contact"
                    type="number"
                    required
                    name="contact"
                  />
                  <input
                    placeholder="College"
                    id="college_name"
                    type="text"
                    name="college_name"
                  />
                  {/* <input name="current_url" id="current_url" class="d-none" type="text" required value="{{url()->current()}}" /> */}
                  <input
                    placeholder="Location"
                    type="text"
                    id="location"
                    name="location"
                  />
                  <input
                    type="submit"
                    id="submitButton"
                    className="signup-form-btn"
                    name="Apply Now"
                    defaultValue="Submit"
                  />
                  {/* <input type="submit" class="form-submit-button" value="Apply Now"> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banner02-section">
        <div className="container">
          <div className="banner-heads">
            <div className="row">
              <div className="col-sm-6">
                <div className="banner02-head"></div>
              </div>
              <div className="col-sm-6" />
            </div>
          </div>
        </div>
        <div className="list-contents">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="list-content">
                  <p className="list-para list-para01">Duration: 4 years</p>
                  <p className="list-para list-para01">
                    Teaches how to treat and care for patients
                  </p>
                  <p className="list-para list-para01">
                    Helps students develop a compassionate attitude
                  </p>
                  <p className="list-para">Teaches the use of different medicines</p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="list-contents02">
                  <div className="eligibility">
                    <p className="eligible-head">Eligibility</p>
                    <p className="eligible-para">
                      Minimum Academic Requirement: 10+2 in PCB with Minimum 45-50%
                      Aggregate
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="slide-section p-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-10">
              <div className="slider-head">
                <h2>
                  Admission Processes at Top Colleges have Commenced.{" "}
                  <span
                    className="red-text"
                    data-toggle="modal"
                    data-target="#hs-apply-form"
                  >
                    Apply Now!
                  </span>
                </h2>
              </div>
            </div>
            <div className="col-sm-2" />
          </div>
          {/* Modal */}
          <div
            className="modal fade"
            id="hs-apply-form"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="hs-apply-form"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Interested? Send us an enquiry
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    id="formoid1"
                    className="form bs-focused-form"
                    action=" https://api.bangalorestudy.com:3000/api/website/home/landingpageenquiry"
                    title=""
                    method="post"
                  >
                    <div className="modal-body mx-3">
                      <div className="md-form mb-2">
                        <label
                          data-error="wrong"
                          data-success="right"
                          htmlFor="form3"
                        >
                          Name
                        </label>
                        <input
                          placeholder="Name"
                          id="name1"
                          name="name"
                          className="form-control validate"
                          type="text"
                          required
                        />
                        <small className="text-danger" />
                      </div>
                      <div className="md-form mb-2">
                        <label
                          data-error="wrong"
                          data-success="right"
                          htmlFor="form2"
                        >
                          Email
                        </label>
                        <input
                          placeholder="Email"
                          id="email1"
                          type="email"
                          className="form-control validate"
                          required
                          name="email"
                        />
                        <small className="text-danger" />
                      </div>
                      <div className="md-form mb-2">
                        <label
                          data-error="wrong"
                          data-success="right"
                          htmlFor="form2"
                        >
                          Contact Number
                        </label>
                        <input
                          placeholder="Contact Number"
                          className="form-control validate"
                          id="contact1"
                          type="number"
                          required
                          name="contact"
                        />
                        <small className="text-danger" />
                      </div>
                      <div className="md-form mb-2">
                        <label
                          data-error="wrong"
                          data-success="right"
                          htmlFor="form2"
                        >
                          College
                        </label>
                        <input
                          placeholder="College"
                          id="college_name1"
                          className="form-control validate"
                          type="text"
                          name="college_name"
                        />
                        <small className="text-danger" />
                      </div>
                      <div className="md-form mb-2">
                        <label
                          data-error="wrong"
                          data-success="right"
                          htmlFor="form2"
                        >
                          Location
                        </label>
                        <input
                          placeholder="Location"
                          id="location1"
                          className="form-control validate"
                          type="text"
                          name="location1"
                        />
                        <small className="text-danger" />
                      </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-center">
                      <input
                        type="submit"
                        id="submitButton1"
                        className="form-control btn btn-primary"
                        name="Submit"
                        defaultValue="Submit"
                      />
                      {/* <button class="form-control btn btn-primary">submit <i
											class="fas fa-paper-plane-o ml-1"></i></button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="colleges">
   
            <div className="collge-box">
              <div className="college">
                <img
                  className="img-fluid"
                  src=" /preprod/landing_sites_asset/images/nursing_college/kempegowda.png"
                  alt="Kempegowda Institute Of Nursing"
                />
                <p>Kempegowda Institute Of Nursing</p>
              </div>
              <div className="apply-cta">
                <div className="wrap">
                  <button
                    type="button"
                    className="btn apply-now"
                    data-toggle="modal"
                    data-target="#hs-apply-form"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
            <div className="collge-box">
              <div className="college">
                <img
                  className="img-fluid"
                  src=" /preprod/landing_sites_asset/images/nursing_college/Vydehi.png"
                  alt="Vydehi Institute of Medical Science and Research Centre"
                />
                <p>Vydehi Institute of Medical Science and Research Centre</p>
              </div>
              <div className="apply-cta">
                <div className="wrap">
                  <button
                    type="button"
                    className="btn apply-now"
                    data-toggle="modal"
                    data-target="#hs-apply-form"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
            <div className="collge-box">
              <div className="college">
                <img
                  className="img-fluid"
                  src=" /preprod/landing_sites_asset/images/nursing_college/Acharya.jpg"
                  alt="Acharya Institute of Nursing"
                />
                <p>Acharya Institute of Nursing</p>
              </div>
              <div className="apply-cta">
                <div className="wrap">
                  <button
                    type="button"
                    className="btn apply-now"
                    data-toggle="modal"
                    data-target="#hs-apply-form"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
            <div className="collge-box">
              <div className="college">
                <img
                  className="img-fluid"
                  src=" /preprod/landing_sites_asset/images/nursing_college/akash-college.png"
                  alt="Akash Institute of Nursing"
                />
                <p>Akash Institute of Nursing</p>
              </div>
              <div className="apply-cta">
                <div className="wrap">
                  <button
                    type="button"
                    className="btn apply-now"
                    data-toggle="modal"
                    data-target="#hs-apply-form"
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div> */}

          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to={0}
                className="active bgcolour"
              />
              <li data-target="#carouselExampleIndicators" data-slide-to={1} className="active bgcolour"/>
              {/* <li data-target="#carouselExampleIndicators" data-slide-to={2} /> */}
            </ol>
            <div className="carousel-inner pb-5">
              <div className="carousel-item active">

                <div className="card-deck">
                  <div className="card border17">
                    <img className="card-img-top h300" src="preprod/landing_sites_asset/images/nursing_college/kempegowda.png"
                      alt="Kempegowda Institute Of Nursing" />
                    <div className="card-body">
                      <p className="card-text font-weight-bold f24">
                        Kempegowda Institute Of Nursing
                      </p>
                    </div>
                    <div className="apply-cta">
                      <div className="wrap">
                        <button
                          type="button"
                          className="btn apply-now"
                          data-toggle="modal"
                          data-target="#hs-apply-form"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="card border17">
                    <img className="card-img-top h300" src="preprod/landing_sites_asset/images/nursing_college/Vydehi.png"
                      alt="Vydehi Institute of Medical Science and Research Centre" />
                    <div className="card-body">
                      <p className="card-text font-weight-bold f24">
                        Vydehi Institute of Medical Science and Research Centre
                      </p>
                    </div>
                    <div className="apply-cta">
                      <div className="wrap">
                        <button
                          type="button"
                          className="btn apply-now"
                          data-toggle="modal"
                          data-target="#hs-apply-form"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="card border17">
                    <img className="card-img-top h300" src="preprod/landing_sites_asset/images/nursing_college/Acharya.jpg"
                      alt="Acharya Institute of Nursing" />
                    <div className="card-body">
                      <p className="card-text font-weight-bold f24">
                        Acharya Institute of Nursing
                      </p>


                    </div>
                    <div className="apply-cta">
                      <div className="wrap">
                        <button
                          type="button"
                          className="btn apply-now"
                          data-toggle="modal"
                          data-target="#hs-apply-form"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>

                  </div>
                </div>

                {/* <img className="d-block w-100" src="preprod/landing_sites_asset/images/nursing_college/kempegowda.png" alt="First slide" /> */}
              </div>
              <div className="carousel-item">
                <div className="card-deck">

                  <div className="card border17">
                    <img className="card-img-top h300" src="preprod/landing_sites_asset/images/nursing_college/Acharya.jpg"
                      alt="Acharya Institute of Nursing" />
                    <div className="card-body">
                      <p className="card-text font-weight-bold f24">
                        Acharya Institute of Nursing
                      </p>

                    </div>
                    <div className="apply-cta">
                      <div className="wrap">
                        <button
                          type="button"
                          className="btn apply-now"
                          data-toggle="modal"
                          data-target="#hs-apply-form"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="card border17">
                    <img className="card-img-top h300" src="preprod/landing_sites_asset/images/nursing_college/akash-college.png"
                      alt="Akash Institute of Nursing" />
                    <div className="card-body">
                      <p className="card-text font-weight-bold f24">
                        Akash Institute of Nursing
                      </p>



                    </div>
                    <div className="apply-cta">
                      <div className="wrap">
                        <button
                          type="button"
                          className="btn apply-now"
                          data-toggle="modal"
                          data-target="#hs-apply-form"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="card border17">
                    <img className="card-img-top h300" src="preprod/landing_sites_asset/images/nursing_college/kempegowda.png"
                      alt="Kempegowda Institute Of Nursing" />
                    <div className="card-body">
                      <p className="card-text font-weight-bold f24">
                        Kempegowda Institute Of Nursing
                      </p>
                    </div>
                    <div className="apply-cta">
                      <div className="wrap">
                        <button
                          type="button"
                          className="btn apply-now"
                          data-toggle="modal"
                          data-target="#hs-apply-form"
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div className="carousel-item">
                <img className="d-block w-100" src="..." alt="Third slide" />
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
            </a>
          </div>





        </div>
      </section >
      <section className="list-section">
        <div className="simple-text">
          <div className="container">
            <p className="simple-para">
              Bangalorestudy ensures a robust and dynamic counselling facility for the
              students of distinguished Educational Experts and Counsellors.
              <br />
              <span style={{ color: "#009AD9" }}>
                The Experts shall guide you with your best options and steer your way
                to excellence.
              </span>
            </p>
          </div>
        </div>
        <div className="list-content02">
          <div className="container">
            <p className="list-para list-para02">
              Free educational counselling to students and their parents
            </p>
            <p className="list-para list-para02">
              Special guidance to overseas students joining colleges and universities
              in India
            </p>
            <p className="list-para list-para02">Seat reservation facilities</p>
            <p className="list-para list-para02">
              Direct meeting with college authorities
            </p>
            <p className="list-para list-para02">
              Provide information about documents required at the time of admission
            </p>
            <p className="list-para list-para02">
              Assist in securing educational loans and Scholarships.
            </p>
            <p className="list-para list-para02">Pre and post admission care</p>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer">
          <div className="container">
            <div className="footer-logo">
              <a href="#">
                <img
                  src="preprod/landing_sites_asset/images/logo02.png"
                  alt="Bangalore Study"
                />
              </a>
            </div>
            <div className="row foot-contents">
              <div className="col-sm-5 address">
                <img
                  src="preprod/landing_sites_asset/images/map.png"
                  alt="Bangalore Study"
                />
                <p>
                  Bangalore Office
                  <br />
                  #3/75, 'D' Main, East End, 9th Block
                  <br />
                  Jayanagar, Bangalore-560 069
                  <br />
                  Tel: 080-22454991, 26631169
                  <br />
                  Fax: 080 22454991
                  <br />
                  PRO Admission : +91 9036020005/16
                  <br />
                </p>
              </div>
              <div className="col-sm-4 contact">
                <img
                  src="preprod/landing_sites_asset/images/phone.png"
                  alt="Bangalore Study"
                />
                <a href="tel:08026631169">080-26631169</a>
              </div>
              <div className="col-sm-3 message">
                <img
                  src="preprod/landing_sites_asset/images/messagabox.png"
                  alt="Bangalore Study"
                />
                <a href="mailto:info@bangalorestudy.com">info@bangalorestudy.com</a>
              </div>
            </div>
            <div className="copyright">
              <p> © 2023 Bangalore Study, All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>



    </>

  );
};

export default BscNursing;