import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import type { User } from '../../src/types/user';
import SplashScreen from '../../src/components/SplashScreen';
import axios from '../../src/utils/axios';
import { useHistory } from 'react-router';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  isforgotpassword : boolean;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  loginuser: (email: string, password: string) => Promise<void>;
  logout: () => void;
  sociallogin : (userId: string, providername: string, email: string,names: string,accessTokens: string) => Promise<void>;
  register: (email: string, name: string, password: string,confirmpassword: string,mobile:string) => Promise<void>;
 forgotpassword: (email: string) => Promise<void>;
 resetpassword: (email: string, password: string,token: string, confirmpassword:string) => void; 
}
interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type ResetPasswordAction = {
  type: 'RESETPASSWORD';
  payload: {
    user: User;
  };
};

type ForgotpasswordAction = {
type: 'FORGOTPASSWORD';
payload: {};
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | ResetPasswordAction
  | ForgotpasswordAction
  | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  isforgotpassword: false,
  user: null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common["x-access-token"] = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common["x-access-token"];
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }

    case 'RESETPASSWORD': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }


    
    case 'FORGOTPASSWORD': {
      return {
        ...state,
        isforgotpassword: true
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  loginuser: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  sociallogin: () => Promise.resolve(),
  forgotpassword: () => Promise.resolve(),
  resetpassword: () => Promise.resolve()
  
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();


  const forgotpassword = async (email: string) => {
    const response = await axios.post('api/auth/user/forgotPassword', { email});
    dispatch({
      type: 'FORGOTPASSWORD',
      payload: {}
    });
  };
  
/**
   * This method Reset Password with Vrification Code 
   */

const resetpassword = async (email: string, password: string,token: string, confirmpassword:string) => {
  const response = await axios.post('api/auth/user/newPassword', { email, password,token,confirmpassword });
  return response;
  
 };
  const login = async (email: string, password: string,) => {
 
    const response = await axios.post('api/auth/signinadmin', { email, password });



    const accessToken = response.data.data.accessToken;

    if(accessToken){
      const user = response.data.data;
      user['id'] = response.data.data.id;
      let myJSON = JSON.stringify(user);
      localStorage.setItem('user', myJSON);


      

      setSession(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });

    }
    history.push('/app/dashboard');


  };

  const loginuser = async (email: string, password: string) => {
    //alert(email);
    //const response = await axios.post<{ accessToken: string; user: User }>('/api/account/login', { email, password });
    const response = await axios.post('api/auth/user/signin', { email, password });

    //const { accessToken, user } = response.data;

    const accessToken = response.data.data.accessToken;

    if(accessToken){
      const user = response.data.data;
      user['id'] = response.data.data.id;
      let myJSON = JSON.stringify(user);
      localStorage.setItem('user', myJSON);
      localStorage.setItem('user', myJSON);
    
  
      

      setSession(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });

    }
    history.goBack();
    //history.push('/');


  };

  const sociallogin = async (userId: string, providername: string, email: string,name: string,accessTokens: string) => {

    const response = await axios.post('api/auth/user/signup/sociallogin', {userId,providername,email,name,accessTokens });


    const accessToken = response.data.data.accessToken;

    if(accessToken){
      const user = response.data.data;
      user['id'] = response.data.data.id;
      let myJSON = JSON.stringify(user);
      localStorage.setItem('user', myJSON);
      localStorage.setItem('user', myJSON);

      

      setSession(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });

    }
    history.goBack();
    //history.push('/');


  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, name: string, password: string,confirmpassword: string,mobile: string) => {
    const response = await axios.post('api/auth/user/signup', {
      email,
      name,
      password,
      confirmpassword,
      mobile

    });

const accessToken = response.data.data.accessToken;

if(accessToken){
  const user = response.data.data;
  user['id'] = response.data.data.id;
  let myJSON = JSON.stringify(user);
  localStorage.setItem('user', myJSON);
  localStorage.setItem('user', myJSON);

  

  setSession(accessToken);

  dispatch({
    type: 'LOGIN',
    payload: {
      user
    }
  });
  history.push('/');
    }
    

    /**dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });*/
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          //alert(user);
          setSession(accessToken);


         //const response = await axios.get<{ user: User; }>('/api/account/me');
        //  const { user } = response.data;
          const user = JSON.parse(window.localStorage.getItem('user'));
         


          //const users = JSON.parse(window.localStorage.getItem('user'));
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        loginuser,
        logout,
        register,
        sociallogin,
        forgotpassword,
        resetpassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;