import React, { FC, useEffect } from 'react';
import axios from 'src/utils/axios';
import Carousel from 'react-bootstrap/Carousel';
import 'animate.css';
import './styles.css';
import EnquiryForm from '../EnquiryForm';
import SearchIcon from 'src/assets/img/icons/search.svg';

import axios1 from 'axios';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

interface schlorshiptype {
    name: string;
    slug: string;
    type: string;
}
// const EnquiryForm = React.lazy(() => import('../EnquiryForm'));
const HeroArea: FC<{ bannerArr: any }> = ({ bannerArr }) => {


    let cancelToken;
    const [collegelist, setcollegelist] = React.useState<schlorshiptype[]>([]);
    const [loading, setloading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const getSuggestions = async (value) => {
        setloading(true);

        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        cancelToken = axios1.CancelToken.source()

        const params = {}

        params['searchText'] = value;
        params['searchfrom'] = 'name';
        params['size'] = '10';
        const response = await axios.get('api/website/home/topcollegebangalore', { cancelToken: cancelToken.token, params: params });
        const tags = await response.data.data;
        setcollegelist(tags);
        setloading(false);
        setOpen(true);
    }

    const handleupdateBlog = (event) => {
        if (event && event.target && typeof event.target.value != typeof undefined && event.target.value != null && event.target.value.length > 1) {
            getSuggestions(event.target.value);
        }
        else {
            setcollegelist([]);
            setOpen(false);
        }
    };

  

    useEffect(() => {
        //getSuggestions('');
    }, []);

    //const loremtxt = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit';

    return (
        <>



            <div className="crslCont mh1000 homepageslider" data-aos="fade-up">
                <Carousel

                >
                    {
                        bannerArr.map((el, idx) => (
                            <Carousel.Item>
                                <a href={el.link} ><img
                                    className="d-block w-100"
                                    src={process.env.REACT_APP_IMG_URI + el.image}
                                    // src={HeroBg}
                                    alt={el.title}
                                    loading="lazy"
                                />
                                    <Carousel.Caption className="custcrowseritem">
                                        <h3>{el.title}</h3>
                                        {/* <p>{el.description ? el.description : loremtxt}***</p> */}
                                    </Carousel.Caption>
                                </a>
                            </Carousel.Item>
                        ))
                    }
                </Carousel>

                <div className="maskSection ">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row d-flex ">
                                <div className="col-lg-8 slider-main">
                                    <div className='sidebarsearch hero_explore'>
                                        <Autocomplete
                                            open={open}
                                            options={collegelist}
                                            filterOptions={(collegelist) => collegelist}
                                            onInputChange={(e) => {
                                                handleupdateBlog(e);
                                            }}
                                            onFocus={(e) => {
                                                setcollegelist([]);
                                                setOpen(false);
                                            }}
                                            value=""
                                            getOptionLabel={(option) => {
                                                // Value selected with enter, right from the input
                                                if (typeof option === 'string') {
                                                    return option;
                                                }
                                                // Add "xxx" option created dynamically
                                                if (option.name) {
                                                    return option.name;
                                                }
                                                // Regular option
                                                return option.name;
                                            }}
                                            renderOption={(option) => {
                                                if (option.type == 'college') {
                                                    return (
                                                        <li>
                                                            <a style={{ color: "#000" }} href={`/college/${option.slug}`}>{option.name}</a>
                                                        </li>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <li>
                                                            <a style={{ color: "#000" }} href={`/university/${option.slug}`}>{option.name}</a>
                                                        </li>
                                                    )
                                                }
                                            }}
                                            noOptionsText=""
                                            onChange={(e, value) => {
                                                //console.log(value)
                                            }}
                                            renderInput={(params) => <TextField className="serachinlinepadding ex-field" style={{ background: `#fff url(${SearchIcon})`, backgroundRepeat: "no-repeat", backgroundPosition: "20px center" }} placeholder="Search" name="text" {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // className: "search-box",
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />}
                                        />
                                        {/* <form method="post" className="frmconts">
                                            <img src={SearchIcn} />
                                            <input type="text" placeholder="Search" name="text" className="ex-field" />
                                            <input type="submit" className="ex-submit" value="Explore" />
                                        </form> */}
                                    </div>
                                </div>
                                <div className="header-form col-lg-4 d-flex flex-column justify-content-center">

                                    <div id="header-form" className="mt-30">
                                        <EnquiryForm />


                                    </div>
                                </div>

                            </div> {/* end row  */}
                        </div> {/* end container  */}
                    </div> {/* end container fluid  */}
                </div>
            </div>
        </>
    )
};

export default HeroArea;