import React, { FC } from 'react';
import './styles.css';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import { config } from 'src/config';

import GetSchoarship1 from 'src/assets/img/get-scholorship1.jpg';


import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { makeStyles } from '@material-ui/core';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Breadcrumbs: FC<{schlorshiplist:any}> = ({schlorshiplist}) => {
    const [num, setNum] = React.useState(331231);

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    return (
        <section className="top-management-colleges get-scholorship container-fluid spac_01 getscholorshipscroll" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal scholarshipsection newscholarship">
                        <h3 className="main-heading">Get <b>Scholarship</b></h3>
                        {/* <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br />sed do tempor incididunt ut labore et dolore. </p>
                        <div className="spac_02"></div> */}

                        <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">

                            {schlorshiplist && schlorshiplist.map((el, idx)=>(
                                <div className="item" key={`colgs${idx}`}>
                                    <div className="testi_wrp">
                                        <div className="testi_hover"></div>
                                        <div className="inner_wrp">
                                            <div className="test_content_wrp col-lg-12 heightboxcontent">
                                                <img alt={el.name} src={ el.logo ? config.baseUrlfromconfig + el.logo : GetSchoarship1}className="full_wdth" />
                                                <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{el.name}</span>}>
                                                    <h3>{el.name}</h3>
                                                </BootstrapTooltip>
                                                <div className="college_button_wrp enquirybtnwrap">
                                                    <GlobalPopupEnquiry />
                                                    {/* <a href="/scholarship">View Details</a> */}
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/* <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship1} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship2} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div></div>


                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship3} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div></div>
                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship4} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div></div>
                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship1} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div></div>
                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship2} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div></div>
                            <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <img src={GetSchoarship3} className="full_wdth" />
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}

                        </OwlCarousel>


                    </div>
                </div>

                <div className="spac_02"></div>
                <div className="button-section text-center">
                    <a href="/scholarship" className="button btn">See All</a>
                </div>


            </div>
        </section>



    );
};

export default Breadcrumbs;