import React, { FC } from 'react';
//import './styles.css';

const Breadcrumbs: FC < {subcourse:any,streamslug: any,}> = ({subcourse,streamslug})=> {
    console.log(streamslug,"streamslug")
    console.log(subcourse,"subcourse")
    return (
        <div className="breadcrumbs">	
            <div className="container" >
                <ol>
                    <li><a href="/" className="active blue-color">Home</a> </li>
                    <li><a href="/courses">Courses</a></li>
                    <li><a href={`/courses/${streamslug}`}>{subcourse.streams  ? subcourse.streams.stream_name : ""}</a></li>
                    <li><a href="">{subcourse.course_stream_name}</a></li>
                </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;