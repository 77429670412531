import React, { FC } from 'react';
import './styles.css';

import { config } from 'src/config';

const Features: FC<{ schooldetail: any }> = ({ schooldetail }) => {
    //const [num, setNum] = React.useState(331231);
    
    return (
        <section className="features-infra container-fluid spac_01 light-grey-bg pt-0"  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <h3 className="main-heading">Facilities and <b>Infrastructure</b></h3>
                    <div className="spac_02"></div>
                    <ul>   
                        {schooldetail.schoolamenities && schooldetail.schoolamenities.map((i, index) => {
                            return (
                            <>
                                <li><a href="" onClick={ (event) => event.preventDefault() } className='disabled'><img alt={i.schamenitiename.amenities_name} width="100%" height="80" src={i.schamenitiename.amenities_logo ? (config.baseUrlfromconfig + i.schamenitiename.amenities_logo):''}/><h3>{i.schamenitiename.amenities_name}</h3></a></li>
                            </>
                            )
                        })}                       
                        {/*  <li><a href=""><img src={Hostel} /><h3>Hostel</h3></a></li>
                        <li><a href=""><img src={Transport} /><h3>Trasportation</h3></a></li>
                        <li><a href=""><img src={Lab} /><h3>Lab</h3></a></li>
                        <li><a href=""><img src={Canteen} /><h3>Canteen</h3></a></li>
                        <li><a href=""><img src={Auditorium} /><h3>Auditorium</h3></a></li>
                        <li><a href=""><img src={Libarary} /><h3>Libarary</h3></a></li>
                        <li><a href=""><img src={Sport} /><h3>Sport</h3></a></li> */}
                    </ul>
                    </div>             
                </div>     
            </div>
        </section>
    );
};

export default Features;