import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import Redirect301Guard from 'src/components/Redirect301Guard';
import Redirect301401Guard from 'src/components/Redirect301Guard';
import FrontLayout from 'src/layouts/FrontLayout';
import HomePage from 'src/views/HomeNew';
import CoursePage from 'src/views/home/CourseList';
import CourseDetailPage from 'src/views/home/CourseView';
import SubCoursesDetailPage from 'src/views/home/SubCoursesView';
import CollegeDetailPage from 'src/views/home/CollegeView';
import SchoolsViewPage from 'src/views/home/SchoolsView';
import UniversityViewPage from 'src/views/home/UniversityView';
import RecognitionPage from 'src/views/home/Recognition';
import PrivacyPage from 'src/views/home/Privacy'; 
import TermsConditionPage from 'src/views/home/TermsCondition';
import CookiesPolicyPage from 'src/views/home/CookiesPolicy';
import DisclaimerPage from 'src/views/home/Disclaimer';

import CollegeListPage from 'src/views/home/CollegeList';
import UniversityListPage from 'src/views/home/UniversityList';
import SchoolListPage from 'src/views/home/SchoolList';
import ExamListPage from 'src/views/home/ExamList';
import LearntechApp from 'src/views/home/LearntechApp';
import ExamViewPage from 'src/views/home/ExamView';
import BoardsListPage from 'src/views/home/Boards';
import BoardViewPage from 'src/views/home/BoardsView';
import BlogListPage from 'src/views/home/BlogList';
import BlogViewPage from 'src/views/home/BlogView';
import NewsListPage from 'src/views/home/NewsList';
import NewsViewPage from 'src/views/home/NewsView';
import NriQuota from 'src/views/home/NriQuota';
import ScholarshipPage from 'src/views/home/ScholarshipView';
import AdmissionPage from './views/home/AdmissionView';
import ContactUsPage from './views/home/ContactUsView';
import StudyAbroadPage from './views/home/StudyAbroadView';
import AboutUsPage from './views/home/AboutUsView';
import ThankYouPage from './views/home/ThankYouPage';
import StudentsSpeakPage from './views/home/StudentsSpeak';
import SignInPage from './views/home/SignupPage';
import ServicePage from './views/home/Services';
import TeamPage from './views/home/TeamView';
import Newcollgecoresepage from './views/home/NewCollegeCourseView';
// import SitemapPage from './views/home/SitemapView';

import BscNursing from './views/home/LandingPage/BscNursing';
// import Yenepoya from './views/home/LandingPage/Yenepoya';
// import Mohanbabu from './views/home/LandingPage/Mohanbabu';
import Mbbs from './views/home/LandingPage/Mbbs';
import Bds from './views/home/LandingPage/Bds';
import BNYS from './views/home/LandingPage/BNYS';
import Ambedkar from './views/home/LandingPage/Ambedkar';
// import Bams from './views/home/LandingPage/Bams';
import Mbbsadmission from './views/home/LandingPage/Mbbsadmission';
import BdsKarnataka from './views/home/LandingPage/BdsKarnataka';
import Mscyenepoya from './views/home/LandingPage/Mscyenepoya';
// import Srivenkateshwara from './views/home/LandingPage/Srivenkateshwara';
import MDS from './views/home/LandingPage/MDS';
// import PGDM from './views/home/LandingPage/PGDM';
// import Horizonschools from './views/home/LandingPage/Horizonschools';
import YenepoyaHomoeopathic from './views/home/LandingPage/YenepoyaHomoeopathic';

// import Nursingadmission from './views/home/LandingPage/Nursingadmission';
import KarnatakaBscNursing from './views/home/LandingPage/KarnatakaBscNursing';
// import kcetregistration from './views/home/LandingPage/kcetregistration';


import AllCourses from './views/home/LandingPage/AllCourses/index';

// import CourseDetaildata from 'src/views/home/CourseView/CourseDetail_old';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  guard301?: any;
  guard301401?:any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];


const SitemapPage = React.lazy(() => import('src/views/home/SitemapView'));
// const CoursePage = React.lazy(() => import('src/views/home/CourseList'));
// const CollegeListPage = React.lazy(() => import('src/views/home/CollegeList'));
// const CourseDetailPage = React.lazy(() => import('src/views/home/CourseView'));
// const SubCoursesDetailPage = React.lazy(() => import('src/views/home/SubCoursesView'));
// const CollegeDetailPage = React.lazy(() => import('src/views/home/CollegeView'));
// const Newcollgecoresepage = React.lazy(() => import('src/views/home/NewCollegeCourseView'));
// const SchoolListPage = React.lazy(() => import('src/views/home/SchoolList'));
// const SchoolsViewPage = React.lazy(() => import('src/views/home/SchoolsView'));
// const UniversityListPage = React.lazy(() => import('src/views/home/UniversityList'));
// const UniversityViewPage = React.lazy(() => import('src/views/home/UniversityView'));
// const ExamListPage = React.lazy(() => import('src/views/home/ExamList'));
// const ExamViewPage = React.lazy(() => import('src/views/home/ExamView'));

const kcetregistration = React.lazy(() => import('src/views/home/LandingPage/kcetregistration'));
const Mountcarmel = React.lazy(() => import('src/views/home/LandingPage/Mountcarmel'));
const Nursingadmission = React.lazy(() => import('src/views/home/LandingPage/Nursingadmission'));
const Christuniversity = React.lazy(() => import('src/views/home/LandingPage/Christuniversity'));
const Svyasauniversity = React.lazy(() => import('src/views/home/LandingPage/Svyasauniversity'));
const Alliancecollege = React.lazy(() => import('src/views/home/LandingPage/Alliancecollege'));
const Bgsinstitutions = React.lazy(() => import('src/views/home/LandingPage/Bgsinstitutions'));
const StJosephUniversity = React.lazy(() => import('src/views/home/LandingPage/StJosephUniversity'));
const Srinivasuniversity = React.lazy(() => import('src/views/home/LandingPage/Srinivasuniversity'));
const Svyasaallieduniversity = React.lazy(() => import('src/views/home/LandingPage/Svyasaallieduniversity'));



// const YenepoyaHomoeopathic = React.lazy(() => import('src/views/home/LandingPage/YenepoyaHomoeopathic'));
const Horizonschools = React.lazy(() => import('src/views/home/LandingPage/Horizonschools'));
const PGDM = React.lazy(() => import('src/views/home/LandingPage/PGDM'));
// const MDS = React.lazy(() => import('src/views/home/LandingPage/MDS'));
const Srivenkateshwara = React.lazy(() => import('src/views/home/LandingPage/Srivenkateshwara'));
// const Mscyenepoya = React.lazy(() => import('src/views/home/LandingPage/Mscyenepoya'));
// const BdsKarnataka = React.lazy(() => import('src/views/home/LandingPage/BdsKarnataka'));
// const Mbbsadmission = React.lazy(() => import('src/views/home/LandingPage/Mbbsadmission'));
const Bams = React.lazy(() => import('src/views/home/LandingPage/Bams'));
// const Ambedkar = React.lazy(() => import('src/views/home/LandingPage/Ambedkar'));
// const BNYS = React.lazy(() => import('src/views/home/LandingPage/BNYS'));
const Mohanbabu = React.lazy(() => import('src/views/home/LandingPage/Mohanbabu'));
const Yenepoya = React.lazy(() => import('src/views/home/LandingPage/Yenepoya'));
// const Mbbs = React.lazy(() => import('src/views/home/LandingPage/Mbbs'));
// const Bds = React.lazy(() => import('src/views/home/LandingPage/Bds'));
// const BscNursing = React.lazy(() => import('src/views/home/LandingPage/BscNursing'));
// const KarnatakaBscNursing = React.lazy(() => import('src/views/home/LandingPage/KarnatakaBscNursing'));
// const Nursingadmission = React.lazy(() => import('src/views/home/LandingPage/Nursingadmission'));
// const AllCourses = React.lazy(() => import('src/views/home/LandingPage/AllCourses'));

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const Redirect301Guard = route.guard301 || Fragment;
        const Redirect301401Guard = route.guard301401 || Fragment;
      
        

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Redirect301401Guard>
              <Redirect301Guard>
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard></Redirect301Guard>  </Redirect301401Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/svyasa-university-allied-health-courses',
    component: Svyasaallieduniversity
  },
  {
    exact: true,
    path: '/srinivas-university-mangalore',
    component: Srinivasuniversity
  },
  {
    exact: true,
    path: '/st-joseph-university-bangalore',
    component: StJosephUniversity
  },
  {
    exact: true,
    path: '/bgs-and-sjb-group-of-institutions-bangalore',
    component: Bgsinstitutions
  },

  {
    exact: true,
    path: '/alliance-ascent-college-mba-admission',
    component: Alliancecollege
  },
  {
    exact: true,
    path: '/svyasa-deemed-university-bangalore',
    component: Svyasauniversity
  },

 
  {
    exact: true,
    path: '/christ-university-bangalore',
    component: Christuniversity
  },


  {
    exact: true,
    path: '/mount-carmel-college-bangalore',
    component: Mountcarmel
  },

  {
    exact: true,
    path: '/kcet-registration',
    component: kcetregistration
  },


  {
    exact: true,
    path: '/bhms-yenepoya-homoeopathic-medical-college-mangalore',
    component: YenepoyaHomoeopathic
  },

  
  {
    exact: true,
    path: '/new-horizon-schools-bangalore',
    component: Horizonschools
  },

  {
    exact: true,
    path: '/gibs-pgdm-admission',
    component: PGDM
  },
  
  {
    exact: true,
    path: '/mds-admission',
    component: MDS
  },
  {
    exact: true,
    path: '/sri-venkateshwara-dental-college-svdc-bds-admission',
    component: Srivenkateshwara
  },
  {
    exact: true,
    path: '/msc-courses-yenepoya-university-bangalore',
    component: Mscyenepoya
  },
  {
    exact: true,
    path: '/bds-merit-seat-guidance',
    component: BdsKarnataka
  },

  {
    exact: true,
    path: '/mbbs-admission',
    component: Mbbsadmission
  },
  {
    exact: true,
    path: '/bams-admission',
    component: Bams
  },
  {
    exact: true,
    path: '/bds-mradc-admission',
    component: Ambedkar
  },
  {
    exact: true,
    path: '/svyasa-bnys-admission',
    component: BNYS
  },
 
  {
    exact: true,
    path: '/mohan-babu-university-tirupati',
    component: Mohanbabu
  },
  {
    exact: true,
    path: '/yenepoya-university-admission',
    component: Yenepoya,
    guard301: Redirect301Guard,
  },
  {
    exact: true,
    path: '/mbbs-counselling',
    component: Mbbs
  },
  {
    exact: true,
    path: '/bds-admission',
    component: Bds
  },
  {
    exact: true,
    path: '/bsc-nursing',
    component: BscNursing
  },
  {
    exact: true,
    path: '/bsc-nursing-entrance-exams-in-karnataka',
    component: KarnatakaBscNursing
  },
  {
    exact: true,
    path: '/nursing-admission',
    component: Nursingadmission
  },
  {
    exact: true,
    path: '/all_courses',
    component: AllCourses
  },
  {
    exact: true,
    path: '/linkedin',
    component: LinkedInCallback
  },
  {
    exact: true,
    path: '/404',
    guard: GuestGuard,
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/learntechapp',
    component: LearntechApp
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/admin/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },


  
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },

  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: [
          '/app/chat/new',
          '/app/chat/:threadKey'
        ],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/dashboard/stream/create',
        component: lazy(() => import('src/views/stream/StreamAdd/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/stream/edit/:id',
        component: lazy(() => import('src/views/stream/StreamAdd/add'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/invoice/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/orders',
        component: lazy(() => import('src/views/order/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/orders/:orderId',
        component: lazy(() => import('src/views/order/OrderDetailsView'))
      },
      // Homepage videos Board ROUTE 
      {
        exact: true,
        path: '/app/dashboard/homepagevideos',
        component: lazy(() => import('src/views/homepage videos/ListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/homepagevideos/edit/:id',
        component: lazy(() => import('src/views/homepage videos/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/homepagevideos/create',
        component: lazy(() => import('src/views/homepage videos/CreateView'))
      },
      // SCHOOL Board ROUTE 
      {
        exact: true,
        path: '/app/dashboard/schoolboard',
        component: lazy(() => import('src/views/school/board/ListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/schoolboard/edit/:id',
        component: lazy(() => import('src/views/school/board/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/schoolboard/create',
        component: lazy(() => import('src/views/school/board/CreateView'))
      },

      //SCHOOL Board END

      // SCHOOL ROUTE 
      {
        exact: true,
        path: '/app/dashboard/school',
        component: lazy(() => import('src/views/school/SchoolListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/school/edit/:id',
        component: lazy(() => import('src/views/school/SchoolCreateView/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/school/create',
        component: lazy(() => import('src/views/school/SchoolCreateView/add'))
      },

      //SCHOOL ROUTE END
      {
        exact: true,
        path: '/app/dashboard/stream',
        component: lazy(() => import('src/views/stream/StreamListView'))
      },
      /*  {
         exact: true,
         path: '/app/dashboard/stream/edit/:id',
         component: lazy(() => import('src/views/stream/StreamAdd'))
       }, */

      {
        exact: true,
        path: '/app/dashboard/generalcourses',
        component: lazy(() => import('src/views/generalcourses/GenCoursesListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/generalcourses/create',
        component: lazy(() => import('src/views/generalcourses/GenCoursesCreateView/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/generalcourses/edit/:id',
        component: lazy(() => import('src/views/generalcourses/GenCoursesCreateView/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/management',
        component: lazy(() => import('src/views/management/ManagementListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/management/create',
        component: lazy(() => import('src/views/management/ManagementCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/management/edit/:id',
        component: lazy(() => import('src/views/management/ManagementCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/cities',
        component: lazy(() => import('src/views/cities/CitiesListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/company/create',
        component: lazy(() => import('src/views/company/CompanyCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/company/edit/:id',
        component: lazy(() => import('src/views/company/CompanyCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/company',
        component: lazy(() => import('src/views/company/CompanyListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/affilition/create',
        component: lazy(() => import('src/views/affilition/AffilitionCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/affilition/edit/:id',
        component: lazy(() => import('src/views/affilition/AffilitionCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/affilition',
        component: lazy(() => import('src/views/affilition/AffilitionListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/recognitionandapproval/create',
        component: lazy(() => import('src/views/recognitionandapproval/RecognitionCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/recognitionandapproval/edit/:id',
        component: lazy(() => import('src/views/recognitionandapproval/RecognitionCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/recognitionandapproval',
        component: lazy(() => import('src/views/recognitionandapproval/RecognitionListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/cities/create',
        component: lazy(() => import('src/views/cities/CitiesCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/cities/edit/:id',
        component: lazy(() => import('src/views/cities/CitiesCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/area',
        component: lazy(() => import('src/views/area/AreaListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/area/create',
        component: lazy(() => import('src/views/area/AreaCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/area/edit/:id',
        component: lazy(() => import('src/views/area/AreaCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/        ',
        component: lazy(() => import('src/views/newsevents/ListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/newsandevents/create',
        component: lazy(() => import('src/views/newsevents/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/newsandevents/edit/:id',
        component: lazy(() => import('src/views/newsevents/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/banners',
        component: lazy(() => import('src/views/banners/BannersListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/banners/edit/:id',
        component: lazy(() => import('src/views/banners/BannersCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/banners/create',
        component: lazy(() => import('src/views/banners/BannersCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/accreditations',
        component: lazy(() => import('src/views/accreditations/AccreditationsListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/accreditations/edit/:id',
        component: lazy(() => import('src/views/accreditations/AccreditationsCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/accreditations/create',
        component: lazy(() => import('src/views/accreditations/AccreditationsCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/amenity',
        component: lazy(() => import('src/views/amenity/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/amenity/edit/:id',
        component: lazy(() => import('src/views/amenity/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/amenity/create',
        component: lazy(() => import('src/views/amenity/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/pages',
        component: lazy(() => import('src/views/pages/PagesListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/pages/edit/:id',
        component: lazy(() => import('src/views/pages/PagesCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/pages/create',
        component: lazy(() => import('src/views/pages/PagesCreateView'))
      },
      //  Start Abroad countries  */
      {
        exact: true,
        path: '/app/dashboard/abroadcountries',
        component: lazy(() => import('src/views/abroadpage/category/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/abroadcountries/edit/:id',
        component: lazy(() => import('src/views/abroadpage/category/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/abroadcountries/create',
        component: lazy(() => import('src/views/abroadpage/category/CreateView'))
      },



      {
        exact: true,
        path: '/app/dashboard/abroaduniversities',
        component: lazy(() => import('src/views/abroadpage/area/AreaListView'))
      },

      {
        exact: true,
        path: '/app/dashboard/abroaduniversities/create',
        component: lazy(() => import('src/views/abroadpage/area/AreaCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/abroaduniversities/edit/:id',
        component: lazy(() => import('src/views/abroadpage/area/AreaCreateView'))
      },

      /*** END Abroad countries */

      //  Start Blog Category */
      {
        exact: true,
        path: '/app/dashboard/blog/category',
        component: lazy(() => import('src/views/blog/category/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/blog/category/edit/:id',
        component: lazy(() => import('src/views/blog/category/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/blog/category/create',
        component: lazy(() => import('src/views/blog/category/CreateView'))
      },

      /*** END Blog Category */

      //  Start Blog Author  */

      {
        exact: true,
        path: '/app/dashboard/blog/author',
        component: lazy(() => import('src/views/blog/author/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/blog/author/edit/:id',
        component: lazy(() => import('src/views/blog/author/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/blog/author/create',
        component: lazy(() => import('src/views/blog/author/CreateView'))
      },

      /*** END Blog Author  ***/
      //  Start Blog  */

      {
        exact: true,
        path: '/app/dashboard/blog',
        component: lazy(() => import('src/views/blog/blog/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/blog/edit/:id',
        component: lazy(() => import('src/views/blog/blog/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/blog/create',
        component: lazy(() => import('src/views/blog/blog/CreateView'))
      },

      /*** END Blog   ***/

      /**   Start team  ***/

      {
        exact: true,
        path: '/app/dashboard/team',
        component: lazy(() => import('src/views/team/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/team/edit/:id',
        component: lazy(() => import('src/views/team/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/team/create',
        component: lazy(() => import('src/views/team/CreateView'))
      },

      //  End team   */

      //  Start job_vacancies */

      {
        exact: true,
        path: '/app/dashboard/job_vacancies',
        component: lazy(() => import('src/views/jobvacancies/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/job_vacancies/edit/:id',
        component: lazy(() => import('src/views/jobvacancies/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/job_vacancies/create',
        component: lazy(() => import('src/views/jobvacancies/CreateView'))
      },

      //  End Jobs   */

      //  Start News and events  */

      {
        exact: true,
        path: '/app/dashboard/news-event',
        component: lazy(() => import('src/views/newsevents/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/news-event/edit/:id',
        component: lazy(() => import('src/views/newsevents/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/news-event/create',
        component: lazy(() => import('src/views/newsevents/CreateView'))
      },

      /*** END  News and events   */

      //  Start Promo Page  */

      {
        exact: true,
        path: '/app/dashboard/promo-page',
        component: lazy(() => import('src/views/promopage/ListView'))

      },
      {
        exact: true,
        path: '/app/dashboard/promo-page/edit/:id',
        component: lazy(() => import('src/views/promopage/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/promo-page/create',
        component: lazy(() => import('src/views/promopage/CreateView'))
      },

      /*** END  Promo Page   */
      //  Start redirect url  */

      {
        exact: true,
        path: '/app/dashboard/redirect-url',
        component: lazy(() => import('src/views/redirecturl/ListView'))

      },
      {
        exact: true,
        path: '/app/dashboard/redirect-url/edit/:id',
        component: lazy(() => import('src/views/redirecturl/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/redirect-url/create',
        component: lazy(() => import('src/views/redirecturl/CreateView'))
      },

      /*** END  redirect url  */

      //  Start website-image  */

      {
        exact: true,
        path: '/app/dashboard/website-image/view',
        component: lazy(() => import('src/views/website/WebsiteListView'))

      },
      {
        exact: true,
        path: '/app/dashboard/website-image/edit/1',
        component: lazy(() => import('src/views/website/WebsiteCreateView'))
      },


      /*** END  website-image   */
      //  Start website-popup-image   */

      {
        exact: true,
        path: '/app/dashboard/website-popup-image/view',
        component: lazy(() => import('src/views/website/WebsitepopupListView'))

      },
      {
        exact: true,
        path: '/app/dashboard/website-popup-image/view/edit/1',
        component: lazy(() => import('src/views/website/WebsitepopupCreateView'))
      },


      /*** END  website-popup-image   */
      /*** start about  */

      {
        exact: true,
        path: '/app/dashboard/about',
        component: lazy(() => import('src/views/about/aboutCreateView'))
      },


      /*** END about  */
      /*** start Enquiry  */
      {
        exact: true,
        path: '/app/dashboard/enquiry',
        component: lazy(() => import('src/views/enquiry/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/enquiry/:id',
        component: lazy(() => import('src/views/enquiry/CreateView'))
      },



      /*** END Enquiry  */
      /*** start Enquiry  */
      {
        exact: true,
        path: '/app/dashboard/backup',
        component: lazy(() => import('src/views/databackup/ListView'))
      },
    



      /*** END Enquiry  */


      /*** start information  */

      {
        exact: true,
        path: '/app/dashboard/information',
        component: lazy(() => import('src/views/information/informationCreateView'))
      },


      /*** END information  */

      /*** start service  */

      {
        exact: true,
        path: '/app/dashboard/service',
        component: lazy(() => import('src/views/service/serviceCreateView'))
      },


      /*** END service  */

      /*** start recognition_editor  */

      {
        exact: true,
        path: '/app/dashboard/recognition_editor',
        component: lazy(() => import('src/views/recognitioneditor/recognitioneditorCreateView'))
      },


      /*** END recognition_editor  */

      /*** start nri  */

      {
        exact: true,
        path: '/app/dashboard/nri',
        component: lazy(() => import('src/views/nri/nriCreateView'))
      },


      /*** END nri  */
      /*** start Students From  */
      {
        exact: true,
        path: '/app/dashboard/students_from',
        component: lazy(() => import('src/views/studentform/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/students_from/edit/:id',
        component: lazy(() => import('src/views/studentform/StudentformCreateview'))
      },
      {
        exact: true,
        path: '/app/dashboard/students_from/create',
        component: lazy(() => import('src/views/studentform/StudentformCreateview'))
      },


      /*** End Students From  */
      /*** start testimonial From  */
      {
        exact: true,
        path: '/app/dashboard/testimonial',
        component: lazy(() => import('src/views/testimonial/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/testimonial/edit/:id',
        component: lazy(() => import('src/views/testimonial/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/testimonial/create',
        component: lazy(() => import('src/views/testimonial/CreateView'))
      },
      // testimonial video
      {
        exact: true,
        path: '/app/dashboard/video-testimonial',
        component: lazy(() => import('src/views/testimonial/VideoListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/video-testimonial/edit/:id',
        component: lazy(() => import('src/views/testimonial/VideoCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/video-testimonial/create',
        component: lazy(() => import('src/views/testimonial/VideoCreateView'))
      },
      /*** END testimonial From  */
      /*** start collage group  */
      {
        exact: true,
        path: '/app/dashboard/group',
        component: lazy(() => import('src/views/collegegroup/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/group/edit/:id',
        component: lazy(() => import('src/views/collegegroup/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/group/create',
        component: lazy(() => import('src/views/collegegroup/CreateView'))
      },
      /*** END collage group */
      /*** start polytechnic  */

      {
        exact: true,
        path: '/app/dashboard/polytechnic',
        component: lazy(() => import('src/views/polytechnic/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/polytechnic/edit/:id',
        component: lazy(() => import('src/views/polytechnic/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/polytechnic/create',
        component: lazy(() => import('src/views/polytechnic/CreateView'))
      },
      /*** END polytechnic  */

      /*** start scholarship  */

      {
        exact: true,
        path: '/app/dashboard/scholarship',
        component: lazy(() => import('src/views/scholarship/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/scholarship/edit/:id',
        component: lazy(() => import('src/views/scholarship/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/scholarship/create',
        component: lazy(() => import('src/views/scholarship/CreateView'))
      },
      /*** END scholarship  */
      /*** start College  */

      {
        exact: true,
        path: '/app/dashboard/college',
        component: lazy(() => import('src/views/college/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/college/edit/:id',
        component: lazy(() => import('src/views/college/CreateView/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/college/create',
        component: lazy(() => import('src/views/college/CreateView/add'))

      },
      {
        exact: true,
        path: '/app/dashboard/college/:id',
        component: lazy(() => import('src/views/college/DetailsView/add'))
      },


      /*** End College  */


      /*** start Exams  */

      {
        exact: true,
        path: '/app/dashboard/exam',
        component: lazy(() => import('src/views/exam/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/exam/edit/:id',
        component: lazy(() => import('src/views/exam/CreateView/add'))
      },

      {
        exact: true,
        path: '/app/dashboard/exam/create',
        component: lazy(() => import('src/views/exam/CreateView/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/exam/:id',
        component: lazy(() => import('src/views/exam/DetailsView/add'))
      },

      /*** END Exams  */





      /*** start Users  */

      {
        exact: true,
        path: '/app/dashboard/users',
        component: lazy(() => import('src/views/users/ListView'))
      },

      /*** END Users  */


      /*** start REviews  */

      {
        exact: true,
        path: '/app/dashboard/reviews',
        component: lazy(() => import('src/views/reviews/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/reviews/edit/:id',
        component: lazy(() => import('src/views/reviews/CreateView'))
      },

   
      /*** END REviews  */



      /*** start courses  */

      {
        exact: true,
        path: '/app/dashboard/course',
        component: lazy(() => import('src/views/course/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/course/edit/:id',
        component: lazy(() => import('src/views/course/CreateView/add'))
      },

      {
        exact: true,
        path: '/app/dashboard/course/create',
        component: lazy(() => import('src/views/course/CreateView/add'))
      },
      {
        exact: true,
        path: '/app/dashboard/course/:id',
        component: lazy(() => import('src/views/course/DetailsView/add'))
      },

      /*** END courses  */
      // upcoming_courses

      {
        exact: true,
        path: '/app/dashboard/upcoming_course',
        component: lazy(() => import('src/views/course/upcommingcourse/ListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/upcoming_course/edit/:id',
        component: lazy(() => import('src/views/course/upcommingcourse/CreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/upcoming_course/create',
        component: lazy(() => import('src/views/course/upcommingcourse/CreateView'))
      },
      //end upcoming_courses

      {
        exact: true,
        path: '/app/dashboard/substream',
        component: lazy(() => import('src/views/substream/SubstreamListView'))
      },
      {
        exact: true,
        path: '/app/dashboard/substream/edit/:id',
        component: lazy(() => import('src/views/substream/SubstreamCreateView'))
      },
      {
        exact: true,
        path: '/app/dashboard/substream/create',
        component: lazy(() => import('src/views/substream/SubstreamCreateView'))
      },





      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView'))
      },
      {
        exact: true,
        path: '/app/management/products/create',
        component: lazy(() => import('src/views/product/ProductCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard-alternative',
        component: lazy(() => import('src/views/reports/DashboardAlternativeView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        guard: GuestGuard,
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [

      
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
     
      {
        component: () => <Redirect to="/404?1" />
      }
    ]
  },

  {
    path: '/',
    layout: FrontLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomePage
      },
      {
        exact: true,
        path: '/sitemaps',
        component: SitemapPage
      },
      {
        exact: true,
        path: '/courses',
        component: CoursePage
      },
      {
        exact: true,
        path: '/colleges',
        component: CollegeListPage
      },
      {
        exact: true,
        path: '/courses/:slug',
        guard301: Redirect301Guard,
        component: CourseDetailPage
      },
      {
        exact: true,
        path: '/courses/:streamslug/:slug',
        guard301: Redirect301Guard,
        component: SubCoursesDetailPage
      },    
      {
        exact: true,
        path: '/college/:slug',
        guard301: Redirect301Guard,
        component: CollegeDetailPage
      },
      {
        exact: true,
        guard301: Redirect301Guard,
        path: '/:collegeslug/courses/:courseslug',
        component: Newcollgecoresepage
      },      
      {
        exact: true,
        path: '/school',
        component: SchoolListPage
      }, 
      {
        exact: true,
        path: '/school/:slug',
        guard301: Redirect301Guard,
        component: SchoolsViewPage
      },
      {
        exact: true,
        path: '/universities',
        component: UniversityListPage
      }, 
      {
        exact: true,
        path: '/university/:slug',
        guard301: Redirect301Guard,
        component: UniversityViewPage
      },
      {
        exact: true,
        path: '/exams',
        component: ExamListPage
      },
      
      {
        exact: true,
        path: '/exams/:slug',
        guard301: Redirect301Guard,
        component : ExamViewPage
      },
      {
        exact: true,
        path: '/boards',
        component : BoardsListPage
      },
      {
        exact: true,
        path: '/board/:slug',
        guard301: Redirect301Guard,
        component : BoardViewPage
      },
      {
        exact: true,
        path: '/blog',
        component : BlogListPage
      },
      {
        exact: true,
        path: '/blog/:slug',
        guard301: Redirect301Guard,
        component : BlogViewPage
      },
      {
        exact: true,
        path: '/news-and-event',
        component : NewsListPage
      },
      {
        exact: true,
        path: '/news-and-event/:slug',
        guard301: Redirect301Guard,
        component : NewsViewPage
      },
      {
        exact: true,
        path: '/nri-quota',
        component : NriQuota
      },
      {
        exact: true,
        path: '/scholarship',
        component: ScholarshipPage
      },
      {
        exact: true,
        path: '/admission',
        component: AdmissionPage
      },
      {
        exact: true,
        path: '/contact',
        component: ContactUsPage
      },
      {
        exact: true,
        path: '/about',
        component: AboutUsPage
      },
      {
        exact: true,
        path: '/thank-you',
        component: ThankYouPage
      },
      {
        exact: true,
        path: '/studyabroad',
        component: StudyAbroadPage
      },
      {
        exact: true,
        path: '/recognition-approval',
        component: RecognitionPage
      },
      {
        exact: true,
        path: '/privacy-policy',
        component: PrivacyPage
      },
      {
        exact: true,
        path: '/terms-and-conditions',
        component: TermsConditionPage
      },
      {
        exact: true,
        path: '/cookies-policy',
        component: CookiesPolicyPage
      },
      {
        exact: true,
        path: '/disclaimer',
        component: DisclaimerPage
      },
      {
        exact: true,
        path: '/students-speak',
        component: StudentsSpeakPage
      },
      {
        exact: true,
        path: '/services',
        component: ServicePage
      },
      {
        exact: true,
        path: '/team',
        component: TeamPage
      },
      {
        exact: true,
        path: '/login',
        component: SignInPage
      },
    
     /* {
        exact: true,
        path: '/newhome',
        component: HomePage
      },*/
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      {
        guard: Redirect301401Guard,
        path: '/:slug',
        component: () => <Redirect to="/404" />
      },
     
    ]
  }
];

export default routes;
