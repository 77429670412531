import React from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import AOS from 'aos'; 
import "aos/dist/aos.css";
// import ReactGA from "react-ga4";
// ReactGA.initialize("259269510");




interface FrontLayoutProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    // display: 'flex',
    height: '100%',
    // overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    // overflow: 'hidden',
    // paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
   
  }
}));

const FrontLayout: FC<FrontLayoutProps> = ({ children }) => {



  const classes = useStyles();
  window.addEventListener('load', () => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  });

  return (
    <div className={classes.root}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

FrontLayout.propTypes = {
  children: PropTypes.node
};

export default FrontLayout;
