import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopColleges from 'src/views/HomeNew/TopColleges/index';
import Interested from './Interested';
import Location from './Location';
import { pConfig } from 'src/config';  
import { useParams,useHistory } from "react-router-dom";
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
  root: {}
}));

const BoardDetail: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);

  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [isTopColLoaded, setTopColLoaded] = useState(false);
  const isMountedRef = useIsMountedRef();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const { slug } = useParams();
  const [collegedetail, setcollegedetail] = useState<any>([]);
  const [topUniversityArr, setTopUniversityArr] = useState([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }
  
  const getdatabystreamid = useCallback(async () => {
    try {
     
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/college/get/'+id);
      if (isMountedRef.current) {
        //  alert("asfd");
        //console.log("response.data.data");
        //console.log(response.data.data);

        if(response.data.data && response.data.data.type && response.data.data.type =='university')
        {
          history.push("/university/"+response.data.data.slug);
        }
        if(response.data.data && response.data.data.type && response.data.data.type =='college')
        {
          history.push("/college/"+response.data.data.slug);
        }
        setcollegedetail(response.data.data);
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description)
      }
    } catch (err) {
      console.error(err);
      history.push("/404");
    }
  }, [isMountedRef]);

  const getCollageUnivData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "college"},{data: "university"}]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);

    setTopCollegeArr(univColgResp.data.data.topcollege.data);
    // setTopUniversityArr(univColgResp.data.data.topuniversity.data);
    // setTopExamArr(univColgResp.data.data.topexam.data);
    // setTestimonialsArr(univColgResp.data.data.testimonials.data)
    setTopColLoaded(true);
  }

  const formattedData = collegedetail && collegedetail.faqs && collegedetail.faqs.map((item) => ({
      "@type": "Question",
      "name": item.questions,
      "acceptedAnswer": {
          "@type": "Answer",
          "text": item.answers,
      },
  }));

  useEffect(()=>{
    getHeroData();
    getdatabystreamid();
    getCollageUnivData();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}board/${slug}`} />
            <script type="application/ld+json">
              {JSON.stringify(
                  {
                      "@context": "https://schema.org",
                      "@type": "FAQPage",
                      "mainEntity": formattedData,
                  }
              )}
            </script>
        </Helmet>
        <Header collegedetail={collegedetail} slug={slug}/>
        <Breadcrumbs collegedetail={collegedetail}/>
        <OverviewSection collegedetail={collegedetail}/>
        <Interested collegedetail={collegedetail}/>
        <Location collegedetail={collegedetail}/>
        {isTopColLoaded && <>
          <TopColleges topCollegeArr={topCollegeArr}/>
        </>}
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default BoardDetail;