import React, { FC } from 'react';
import './styles.css';

import InterestedCourseBG from 'src/assets/img/interested-in-course-bg1.png';
import AbroadMBBSBannerBG from 'src/assets/img/abroadmbbsbanner.jpg';
//import { config } from 'src/config';

import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';


const Breadcrumbs: FC<{}> = ({ }) => {

    return (
        <>
        <section className="container-fluid spac_01 "  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className=" interested-in-course text-center" style={{ background: `url(${AbroadMBBSBannerBG})`, backgroundSize: '100%', backgroundPosition: 'center center', backgroundRepeat:'no-repeat' }}>
                        <div className="course-center">
                            {/* <h2>Start Your Medical Journey!</h2>
                            <p>Book a Flight to an Excellent Medical Education Abroad. </p> */}
                            <h5>&nbsp;</h5>
                            <h5>&nbsp;</h5>
                            <h5>&nbsp;</h5>
                            <h5>&nbsp;</h5>
                            <h5>&nbsp;</h5>
                            <h5>&nbsp;</h5>
                            
                        </div>
                    </div>
                </div>     
            </div>
        </section>
        <section className="container-fluid spac_01 "  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className=" interested-in-course text-center"  style={{ background: `#fff url(${InterestedCourseBG})` }}>
                        <div className="course-center">   
                            <p>Interested in Studying Abroad? Get Foreign Study Guidance Now.</p>
                            <div className="button-section text-center">
                                <GlobalPopupEnquiry  className="button btn" title="Talk to our Experts"/>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </section>
        </>
    );
};

export default Breadcrumbs;