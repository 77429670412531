import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import BoardsList from './BoardsList';
import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Boards: FC = () => {
  const [isgetload, setIsgetload] = useState<boolean>(true);
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(2);
  const [total, setTotal] = useState<number>(0);
  const [totalpage, setTotalpage] = useState<number>(0);
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const [isHeroLoaded, setHeroLoaded] = useState(false);

  const [boardArr, setBoardArr] = useState([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const handlePageChange = (event: any, value: number): void => {
    //alert(value);
    // setPage(value);
    getBoardData(value, limit);
  };
  const [boardpagedata, setboardpagedata] = useState([]);

  const getboardpagedata = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/board');
      if (isMountedRef.current) {
        //  alert("asfd");
          //console.log("response.data.data");
          //console.log(response.data.data);
          setboardpagedata(response.data.data);
          setmetatitle(response.data.data.meta_title)
          setmetadescription(response.data.data.meta_description)  
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getBoardData = useCallback(async (page, limit)=>{
    try {
      setIsgetload(true);
      let usePagination = true;
      let pages = page;
      let count = limit;
      const params = {}
      params['usePagination'] = usePagination;
      params['data_type'] = 'board';
      params['page'] = pages;
      params['size'] = count;
      const boards = await axios.get('api/website/home/topcollegebangalore', { params: params  });
      console.log(boards);
      if (isMountedRef.current) {
        setBoardArr(boards.data);
        let pagetobeshow = boards.data.currentPage;
        setIsgetload(false);
        setPage(pagetobeshow);
        setLimit(count);
        setTotal(boards.data.totalItems);
        let pagecount = Math.ceil(boards.data.totalItems / count);
        setTotalpage(pagecount);
      } 
      
    } catch (err) {
      console.error(err);
    }  
  },[isMountedRef]);

  useEffect(()=>{
    getHeroData();
    getBoardData(page, limit);
    getboardpagedata();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}boards`} />
        </Helmet>
        <Header/>
        <Breadcrumbs/>
        <OverviewSection boardpagedata={boardpagedata}/>
        <BoardsList boardArr={boardArr} page={page} totalpage={totalpage} handlePageChange={handlePageChange} isgetload={isgetload}/>
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default Boards;