import React, { FC } from 'react';
import './styles.css';
import moment from 'moment';

const LatestBlog: FC<{blogArr:any, newsArr:any}> = ({blogArr, newsArr}) => {

    return (

    <section className="container-fluid light_gray spac_01" data-aos="fade-up">
        <div className="container">
            {/* <div className="row">
                <div className="col-lg-8">
                    <h3 className="common_sec_h">Latest Blogs</h3>
                </div>
                <div className="col-lg-4">
                    <h3 className="common_sec_h">Latest News</h3>
                </div>
            </div> */}
            <div className="row">
                <div className="col-lg-12 our_portal">
                    <div className="loop_colleges loop_blogs_news loop_nav">
                        <div className="item">
                            <div className="row">
                                <div className="col-lg-8">
                                    <h3 className="common_sec_h">Latest Blogs</h3>
                                    <div className="row">
                                        {blogArr && blogArr[0] && 
                                        <div className="col-lg-6  col-md-6">
                                            <div className="testi_wrp">
                                                <div className="testi_hover"></div>
                                                <div className="inner_wrp">
                                                    <div className="test_content_wrp col-lg-12">
                                                        <img alt={blogArr[0].title} src={process.env.REACT_APP_IMG_URI+blogArr[0].cover_image} className="full_wdth" />
                                                        <h3>{blogArr[0].title}</h3>
                                                        <p>{blogArr[0].meta_description}</p>
                                                        <div className="full_wdth blog_footer">
                                               

                                                            <a href={`blog/${blogArr[0].slug}`} className="blog_bt">Read Blog</a>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    {blogArr && blogArr[1] && 
                                        <div className="col-lg-6  col-md-6">
                                            <div className="testi_wrp">
                                                <div className="testi_hover"></div>
                                                <div className="inner_wrp">
                                                    <div className="test_content_wrp col-lg-12">
                                                        <img alt={blogArr[1].title} src={process.env.REACT_APP_IMG_URI+blogArr[1].cover_image}  className="full_wdth" />
                                                        <h3>{blogArr[1].title}</h3>
                                                        <p>{blogArr[1].meta_description}</p>
                                                        <div className="full_wdth blog_footer">
                                                     
                                                            <a href={`blog/${blogArr[1].slug}`} className="blog_bt">Read Blog</a>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    </div>
                                    <div className="full_wdth">
                                        <a href="/blog" className="view_all">Explore More Blogs</a>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <h3 className="common_sec_h">Latest News</h3>
                                    <ul className="lt_news">
                                        <li>
                                            <div className="date_set">
                                                {/* <div>{moment(newsArr[0].created_at).format('D')}<sub>th</sub><br/>{moment(newsArr[0].created_at).format('MMM')}</div> */}
                                                {moment(newsArr[0].created_at).format('D')}
				                                <p>{moment(newsArr[0].created_at).format('MMMM')}</p>
                                            </div>
                                            <div className="news_ct">
                                                <a href={`news-and-event/${newsArr[0].slug}`}><h2>{newsArr[0]? newsArr[0].title : null}</h2></a>
                                                {/* <p><span>7 Feb, 22</span> <span className="bullets"></span> <span>29 mins read</span></p> */}
                                            </div>
                                        </li>

                                        <li>
                                            <div className="date_set">
                                                {moment(newsArr[1].created_at).format('D')}
                                                <p>{moment(newsArr[1].created_at).format('MMMM')}</p>
                                            </div>
                                            <div className="news_ct">
                                                <a href={`news-and-event/${newsArr[1].slug}`}><h2>{newsArr[1]? newsArr[1].title : null}</h2></a>
                                                {/* <p><span>7 Feb, 22</span> <span className="bullets"></span> <span>29 mins read</span></p> */}
                                            </div>
                                        </li>

                                        <li>
                                            <div className="date_set">
                                                {moment(newsArr[2].created_at).format('D')}
                                                <p>{moment(newsArr[2].created_at).format('MMMM')}</p>
                                            </div>
                                            <div className="news_ct">
                                                <a href={`news-and-event/${newsArr[2].slug}`}><h2>{newsArr[2]? newsArr[2].title : null}</h2></a>
                                                {/* <p><span>7 Feb, 22</span> <span className="bullets"></span> <span>29 mins read</span></p> */}
                                            </div>
                                        </li>

                                    </ul>
                                    <div className="full_wdth">
                                        <a href="/news-and-event" className="view_all">All Educational News</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default LatestBlog;