import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import College from 'src/assets/img/college.jpg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';
import moment from 'moment';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { any } from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {


    Hidden
} from '@material-ui/core';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OverviewSection: FC<{ pagedata: any, promoBannerArr: any, abroadCountriesArr: any, abroadUniversitiesArr: any }> = ({ pagedata, promoBannerArr, abroadCountriesArr, abroadUniversitiesArr }) => {

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
                <div className="row">
                    <div className="col-xl-8 col-lg-12 col-sm-12 overviews-course left-exam-section" >
                        <h3 className="main-heading left-align">Study <span>Abroad</span></h3>

                        <span dangerouslySetInnerHTML={{ __html: pagedata.description }} />



                        <div className="spac_02"></div>

                        <div id="lefttabs-block">
                            <div className="col-md-3 col-lg-3 left-tabs-nav-widgets d-none d-sm-block d-lg-block d-md-block" >
                                <ul className="nav nav-tabs" id="myTab">
                                    {
                                        abroadCountriesArr && abroadCountriesArr.map((i, index) => {
                                            return (
                                                <>
                                                    <li className="nav-item"><a href={`#country${i.id}`} className={index == 0 ? 'nav-link  active' : 'nav-link '} data-bs-toggle="tab">{i.country_name}</a></li>
                                                </>
                                            )
                                        })
                                    }
                                    {/* <li className="nav-item"><a href="#usa" className="nav-link active" data-bs-toggle="tab">USA</a></li>
                                    <li className="nav-item"><a href="#united-kingdom" className="nav-link" data-bs-toggle="tab">United Kingdom</a></li>
                                    <li className="nav-item"><a href="#australia" className="nav-link" data-bs-toggle="tab">Australia</a></li>
                                    <li className="nav-item"><a href="#canada" className="nav-link" data-bs-toggle="tab">Canada</a></li>
                                    <li className="nav-item"><a href="#new-zealand" className="nav-link" data-bs-toggle="tab">New Zealand</a></li>
                                    <li className="nav-item"><a href="#germany" className="nav-link" data-bs-toggle="tab">Germany</a></li>
                                    <li className="nav-item"><a href="#france" className="nav-link" data-bs-toggle="tab">France</a></li>
                                    <li className="nav-item"><a href="#singapore" className="nav-link" data-bs-toggle="tab">Singapore</a></li>
                                    <li className="nav-item"><a href="#ireland" className="nav-link" data-bs-toggle="tab">Ireland</a></li> */}
                                </ul>
                            </div>

                            <div className="col-md-9 col-lg-9 right-content-widgets mynewclass" >


                                <Hidden lgUp mdUp smUp>
                                    {abroadCountriesArr && abroadCountriesArr.map((i, index) => {
                                        return (
                                            <>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id={`panel${index}a-header`}
                                                    >
                                                        <Typography> {i.country_name}</Typography>
                                                        {/* <Typography>{index}</Typography> */}
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <ul>
                                                            {
                                                                abroadUniversitiesArr && abroadUniversitiesArr.filter(o => o.country.country_name == i.country_name).map((row, index) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <GlobalPopupEnquiry className="apply-btn cursorpointer" />
                                                                                <h4><a href="">{row.university_name}</a></h4>
                                                                            </li>

                                                                        </>
                                                                    )
                                                                }
                                                                )
                                                            }

                                                        </ul>
                                                    </AccordionDetails>
                                                </Accordion>
                                                {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                            </>
                                        )
                                    })}
                                </Hidden>
                                <div className="tab-content left-overviews-section d-none d-sm-block d-lg-block d-md-block">



                                    {
                                        abroadCountriesArr && abroadCountriesArr.map((i, index) => {
                                            return (
                                                <>
                                                    <div className={index == 0 ? 'tab-pane fade show active ' : 'tab-pane fade  '} id={`country${i.id}`}>
                                                        <ul>
                                                            {
                                                                abroadUniversitiesArr && abroadUniversitiesArr.filter(o => o.country.country_name == i.country_name).map((row, index) => {
                                                                    return (
                                                                        <>
                                                                            <li>
                                                                                <GlobalPopupEnquiry className="apply-btn cursorpointer" />
                                                                                <h4><a href="">{row.university_name}</a></h4>
                                                                            </li>

                                                                        </>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }

                                    {/* <div className="tab-pane fade show active" id="usa">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-pane fade" id="united-kingdom">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>


                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="australia">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>
                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="canada">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="new-zealand">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="germany">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>
                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="france">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>
                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="singapore">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>
                                        </ul>

                                    </div>

                                    <div className="tab-pane fade" id="ireland">
                                        <ul>
                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Texas A & M University, Texas</a></h4>
                                            </li>

                                            <li>
                                                <GlobalPopupEnquiry  className="apply-btn" />
                                                <h4><a href="">Harvard University, Massachusetts</a></h4>
                                            </li>
                                        </ul>

                                    </div> */}








                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-12 col-sm-12" >
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">Get Details Now</h4>
                            <EnquiryDetailForm type="admissions" />
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course" />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location" />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                </div>
                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}

                        </div>

                        {
                            promoBannerArr && promoBannerArr.map((i) => {
                                let banner_image = i.image ? config.baseUrlfromconfig + i.image : TopManagementCourseBG;
                                return (
                                    <>
                                        <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                            <div className="course-center">
                                                <h2>&nbsp;</h2>
                                                <p>&nbsp;</p>
                                                {/* <h2>Top Management Courses in Bangalore</h2>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                        {/* <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div>
                        <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;