import React, { FC, useState } from 'react';
import './styles.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const FeaturedNews: FC<{topNewsArr:any}> = ({topNewsArr}) => {

    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, nav:true},
            400: {items:1},
            600: {items: 1},
            700: {items: 2},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    const LongText = ({ content,limit}) => {
        const [showAll, setShowAll] = React.useState(false);
      
        const showMore = () => setShowAll(true);
        const showLess = () => setShowAll(false);
      
        if (content.length <= limit) {
          // there is nothing more to show
          return <div>{content}</div>
        }
        if (showAll) {
          // We show the extended text and a link to reduce it
          return <div> 
            {content} 
            <button onClick={showLess}>Read less</button> 
          </div>
        }
        // In the final case, we show a text with ellipsis and a `Read more` button
        const toShow = content.substring(0, limit) + "...";
        return <span dangerouslySetInnerHTML={{ __html: toShow }} />
        
      }

    return (
        
  
        <section className="container-fluid spac_01 topColgs" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="common_sec_h">Featured News</h3>
                        <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                            {topNewsArr.map((el, idx)=> (
                                //let eventlink = '/news-and-event/'+el.slug;
                                <div className="item" key={`colgs${idx}`}>
                                    <div className="testi_wrp">
                                        <div className="testi_hover"></div>
                                        <div className="inner_wrp">
                                            <div className="test_content_wrp col-lg-12 featurednewssection">
                                                {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                                <img alt={el.title} src={process.env.REACT_APP_IMG_URI+el.cover_image} className="full_wdth" />
                                                <h3>{el.title}</h3>                                                
                                                {/* <p> <LongText content = {el.body} limit = {50} /></p> */}
                                                <div className="college_button_wrp">
                                                    {/* <GlobalPopupEnquiry /> */}
                                                    <a className='active_bt' href={'/news-and-event/'+el.slug}>View Details</a>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturedNews;