import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';

import { config } from 'src/config';
import moment from 'moment';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const TextSection: FC<{ schlorshipdata: any }> = ({ schlorshipdata }) => {
    const [num, setNum] = React.useState(331231);

    return (
        <section className="container-fluid" data-aos="fade-up" style={{padding: '50px 0px 0px'}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="main-heading left-align">Scholarships in <span>India</span></h3>

                        <span dangerouslySetInnerHTML={{ __html: schlorshipdata.description }} />

                    </div>
                </div>
            </div>
        </section>
    );
};

export default TextSection;