import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import { useParams } from "react-router-dom";

import OwlCarousel from 'react-owl-carousel'; 
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const TopColleges: FC<{topCollegeArr:any,coursedetail:any, courseCollegedata:any}> = ({coursedetail,topCollegeArr, courseCollegedata}) => {
    const { slug } = useParams();
    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    let linkcollege = '/colleges?stream=' + slug;

    return (
        <section className="container-fluid spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal exam_section_slider examdesignslider courseviewcollege">
                        <h3 className="common_sec_h">Top <span>{coursedetail.stream_name}</span> Colleges</h3>
                         {/*<OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                            {topCollegeArr.map((el, idx)=>(

                                <div className="item" key={`colgs${idx}`}>
                                    <div className="testi_wrp">
                                        <div className="testi_hover"></div>
                                        <div className="inner_wrp">
                                            <div className="test_content_wrp col-lg-12 wrapcontent">
                                                <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div>
                                                <img src={process.env.REACT_APP_IMG_URI+el.CollegeGalleriess[0].image} className="full_wdth" />
                                                <h3>{el.name}</h3>
                                                {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> 
                                                <div className="college_button_wrp">
                                                <GlobalPopupEnquiry />
                                                    <a href={`../college/${el.slug}`}>View Details</a>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))} */}
 
                            {/* {courseCollegedata && courseCollegedata.length > 0 ? 
                                <>
                                    {courseCollegedata && courseCollegedata.slice(0, 20).map((el, idx)=>(
                                        <div className="item" key={`colgs${idx}`}>
                                            <div className="testi_wrp">
                                                <div className="testi_hover"></div>
                                                <div className="inner_wrp">
                                                    <div className="test_content_wrp col-lg-12 wrapcontent">
                                                        {el.college_groupss.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.college_groupss.avg_rating}</div> : '' }
                                                        <img src={process.env.REACT_APP_IMG_URI+el.college_groupss.logo} className="full_wdth" alt={el.college_groupss.name} />
                                                        <h3>{el.college_groupss.name}</h3>
                                                       
                                                        <div className="college_button_wrp">
                                                        <GlobalPopupEnquiry />
                                                            <a href={`../college/${el.college_groupss.slug}`}>View Details</a>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            : ('') } 

                        </OwlCarousel>*/}
                        <OwlCarousel {...owlOptions} className="loop_colleges loop_Exams loop_nav owl-carousel owl-theme">
                        {courseCollegedata && courseCollegedata.length > 0 ? 
                                <>
                        {courseCollegedata && courseCollegedata.map((el, idx)=>(
                            <div className="item" key={`topexm${idx}`}>
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12 wrapexamcontent">
                                            {el.college_groupss?.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.college_groupss.avg_rating}</div> : '' }
                                            <img alt={el.college_groupss?.name} src={process.env.REACT_APP_IMG_URI+el.college_groupss?.logo} className="full_wdth" />
                                            <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{el.college_groupss?.name}</span>}>
                                                <h3 className='leftalign'>{el.college_groupss?.name}</h3>
                                            </BootstrapTooltip>
                                            {/* <h3>{el.name}</h3> */}
                                            {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}
                                            <div className="college_button_wrp">
                                            <GlobalPopupEnquiry />
                                            <a href={`../college/${el.college_groupss?.slug}`}>View Details</a>
                                            {/* <a href={`college/${el.slug}`}>View Details</a> */}
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                         </>
                            : ('') }
                        </OwlCarousel>
                    </div>
                </div>
                <div className="spac_02"></div>
                <div className="button-section text-center">
                    <a href={linkcollege} className="button btn">See All</a>
                </div>
            </div>
        </section>
    );
};

export default TopColleges;