
import Marquee from "react-fast-marquee";
import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import axios from 'src/utils/axiosintercpt';


interface Props {
    className?: any;
    title?: any;
    pagename?: any;

}



const NewsMarquee: FC<Props> = ({ className, title, pagename, ...rest }) => {

    let newsarray1 = ["kea-released-schedule-neet-ug-1st-round-seat-allotment", "snap-2021-results-announced"]
    const [newsarray, setnewsarray] = useState<any>([]);

    const getnewspagedata = async () => {
        try {
            const paramss = {}
            paramss['page'] = 1;
            paramss['size'] = '10';
            paramss['columnname'] = 'created_at';
            paramss['orderby'] = 'desc';
            const response1 = await axios.get('api/website/home/news', { params: paramss });
            
            setnewsarray(response1.data.data)

        } catch (err) {
            // console.error(err);
        }
    }

    useEffect(() => {

        getnewspagedata();
    }, []);

    return (
        <>
            <div className="d-none d-sm-block ">
                <Marquee pauseOnClick={true} speed={50} pauseOnHover={true} delay={3} >

                    {newsarray.map((el, idx) => {

                        let newslink = '/news-and-event/' + el.slug;





                        return (
                            <div>&nbsp; &nbsp;    News {idx + 1} : <a href={newslink}>{el.title}</a> ,</div>

                        )



                    })}



                </Marquee>

            </div>
        </>
    );
};

export default NewsMarquee;