import React, { FC } from 'react';
import './styles.css';

import { config } from 'src/config';

const Features: FC<{ coursedetail: any}> = ({ coursedetail }) => {
    
    return (
        <section className="features-infra container-fluid spac_01 light-grey-bg " >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <h3 className="main-heading">Facilities and <b>Infrastructure</b></h3>
                    <div className="spac_02"></div>
                        <ul>  
                            {coursedetail.college_amenities && coursedetail.college_amenities.map((i, index) => {
                                return (
                                <>
                                    <li><a href="" onClick={ (event) => event.preventDefault() } className='disabled'><img alt={i.amenitiename.amenities_name} width="100%" height="80" src={i.amenitiename.amenities_logo ? (config.baseUrlfromconfig + i.amenitiename.amenities_logo):''}/><h3>{i.amenitiename.amenities_name}</h3></a></li>
                                </>
                                )
                            })}
                        </ul>
                    </div>             
                </div>     
            </div>
        </section>
    );
};

export default Features;