import React, { FC } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses1.jpg';
import CallNow from 'src/assets/img/call-now.svg';
import SearchIcon from 'src/assets/img/icons/search.svg';
import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
import { config, pConfig } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Helmet from 'react-helmet';
import GetAppIcon from '@material-ui/icons/GetApp';


const SearchHeader: FC<{ collegedetail: any, slug:any }> = ({ collegedetail, slug }) => {
    const imageUrl = collegedetail.logo ? config.baseUrlfromconfig + collegedetail.logo : ManagementCourseBG;
    return (
      <>
        <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(
              {
                "@context": "https://schema.org/",
                "@type": "CollegeOrUniversity",
                "name": `${collegedetail.meta_title}`,
                "logo": `${imageUrl}`,
                "url" : `${pConfig.siteUrl}college/${slug}`,
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": `${collegedetail.address}`
                }
              
            }
          )}
        </script>
      </Helmet>
      <section className="inner-top-header removesomepadding" id="hero" style={{ background: `url(${ManagementCourseBG})` }}   >
      {/* <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${ManagementCourseBG})` }}   > */}
        <div className="hero-container d-flex">
          <div className="container" >
            <div className="row college-block">
              <div className="top-college-header">
                <h1><img style={{ width: `80px` }} src={imageUrl} alt={collegedetail.name} /> {collegedetail.name}  </h1>
                {collegedetail.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {collegedetail.avg_rating}</div> : ''}
                {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                <ul className='inlineflex'>
                  <li><i className="bi bi-pin-map-fill"></i>&nbsp; {collegedetail.address}</li>
                  <li>Approvals and Recognitions:&nbsp;<strong>{
                    collegedetail.collegeRecognition && collegedetail.collegeRecognition.map((element, index) => {
                      return (
                        <>
                          {index == 0 ? ' ' + element.recognitionname.recognition_approval_name : ', ' + element.recognitionname.recognition_approval_name}
                        </>
                      )
                    })
                  }</strong></li>
                  <li>Est. Year:&nbsp;<strong>{collegedetail.established}</strong></li>
                  <li>Affiliation:&nbsp;<strong>
                    {
                      collegedetail.college_affiliation && collegedetail.college_affiliation.map((element, index) => {

                        return (
                          <>
                            {index == 0 ? ' ' + element.affiliationname.other_affiliations_name : ', ' + element.affiliationname.other_affiliations_name}
                          </>
                        )

                      })
                    }</strong>
                  </li>
                  <li>Gender Accepted:&nbsp;<strong>{collegedetail.genders_accepted}</strong></li>
                  <li>Ownership:&nbsp;<strong>{collegedetail.college_type}</strong></li>
                  <li>Campus Size:&nbsp;<strong>{collegedetail.campus_size} {collegedetail.campus_size_type}</strong></li>
                </ul>
                <div className="spac_02"></div>
                <div className="col-lg-12 button-section college-button-download">
                  <GlobalPopupEnquiry className="medical-button btn" />

                  <a href="#reviewssection" className="reviews-button btn college-write-button">Write a Review</a>

                  <GlobalPopupEnquiry  pagename={'Download Brochure'} className="broucher-button btn " title= {<><GetAppIcon/>Download Brochure</> }  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SearchHeader;