import React, { FC, useState } from 'react';
import './styles.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { useParams } from 'react-router';

const TopColleges: FC<{topCollegeArr:any, subcourse:any}> = ({topCollegeArr, subcourse}) => {
    const { streamslug, slug } = useParams();
    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };
    let linkcourse = '/colleges?course=' + slug;

    return (
        <>
        {subcourse.course && subcourse.course.length > 0 ? 
            <section className="container-fluid spac_01 topColgs" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 our_portal">
                            <h3 className="common_sec_h">Top Colleges</h3>
                            <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                                {/* {topCollegeArr.map((el, idx)=>(

                                    <div className="item" key={`colgs${idx}`}>
                                        <div className="testi_wrp">
                                            <div className="testi_hover"></div>
                                            <div className="inner_wrp">
                                                <div className="test_content_wrp col-lg-12 wrapcontent">
                                                    <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div>

                                                    <img src={process.env.REACT_APP_IMG_URI+el.CollegeGalleriess[0].image} className="full_wdth" />
                                                    <h3>{el.name}</h3>
                                                    <div className="college_button_wrp">
                                                        <a href="#" className="active_bt">Apply Now</a>
                                                        <a href={`../../college/${el.slug}`}>View Details</a>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}

                                {subcourse.course.map((el, idx)=>(

                                    <div className="item" key={`colgs${idx}`}>
                                        <div className="testi_wrp">
                                            <div className="testi_hover"></div>
                                            <div className="inner_wrp">
                                                <div className="test_content_wrp col-lg-12 wrapcontent">
                                                    {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                                    {el.college.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.college.avg_rating}</div> : '' }
                                                    <img alt={el.college.name} src={process.env.REACT_APP_IMG_URI+el.college.logo} className="full_wdth" />
                                                    <h3>{el.college.name}</h3>
                                                    {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}
                                                    <div className="college_button_wrp">
                                                        <GlobalPopupEnquiry />
                                                        <a href={`../../college/${el.college.slug}`}>View Details</a>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </OwlCarousel>
                            </div>
                    </div>
                    <div className="spac_02"></div>
                    <div className="button-section text-center">
                        <a href={linkcourse} className="button btn">See All</a>
                    </div>
                </div>
            </section>
            :
            ('')
        }
        </>
    );
};

export default TopColleges;