import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

//import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import LocationSection from './LocationSection';
import WhatWeDo from './WhatWeDo';
import PortalSection from './ProtalsSection';

import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
  root: {}
}));

const AboutUsPage: FC = () => {
  //const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [countBlockInfo, setCountBlockInfo] = useState({});
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  const [aboutusdata, setAboutusdata] = useState([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"},{data: "count_block"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    setCountBlockInfo(heroResp.data.data.count_block);
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const getAboutus = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/about/get');
      if (isMountedRef.current) {
          //console.log(response.data.data);
          setAboutusdata(response.data.data);
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);


  useEffect(()=>{
    getHeroData();
    getAboutus();
  }, []);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription} />
            <link rel="canonical" href={`${pConfig.siteUrl}about`} />
        </Helmet>
        <Header/>
        {/* <Breadcrumbs/> */}
        <OverviewSection aboutusdata={aboutusdata} />
        <WhatWeDo countBlockInfo={countBlockInfo}/>
        <PortalSection/>
        <LocationSection/>
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default AboutUsPage;