import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import CollegeIMG from 'src/assets/img/college.jpg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import Pagination from '@material-ui/lab/Pagination/Pagination';
import { config } from 'src/config';
import moment from 'moment';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OverviewSection: FC<{ newsListing: any, newsListingNew, isFetching, isFetching1, getNewsData, totalPages, handlePageChange1, handlePageChange, totalPages1, page, page1, getNewsEventData, topCollegeArr, promoBannerArr: any }> = ({ newsListing, newsListingNew, isFetching, isFetching1, getNewsData, totalPages, handlePageChange1, handlePageChange, totalPages1, page, page1, getNewsEventData, topCollegeArr, promoBannerArr }) => {
    //const [num, setNum] = React.useState(331231);
    //console.log('1111');
    //console.log(newsListingNew);
    //console.log('1111');

    const owlOptions = {
        margin: 20, nav: false, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: { items: 1 },
            400: { items: 1 },
            600: { items: 1 },
            700: { items: 2 }
        },
    };

    const retrievRowsByColNum = (data, colPerRow) => {
        const rows = [];
        let cols = [];
        for (let i = 0; i < data.length; i++) {
            cols.push(data[i]);
            if ((i + 1) % (colPerRow) === 0) {
                rows.push(cols);
                cols = [];
            }
        }
        if (cols && cols.length > 0) {
            rows.push(cols);
        }
        //console.log(rows);
        return rows;
    };

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate">
                <div className="row">
                    <div className="col-lg-8 overviews-course left-exam-section" >
                        <h3 className="main-heading left-align">Entrance <span>Exams</span></h3>
                        {
                            newsListing && newsListing.map((c) => {
                                return (
                                    <div className="col-xl-4 col-lg-4 col-md-6 blog-post-entrancenews">
                                        <div className="entrance-exam-news entrancenews">
                                            <img alt={c.title} src={c.cover_image ? config.baseUrlfromconfig + c.cover_image : EntranceExam} />
                                            <h2><span>{c.title}</span></h2>
                                            <div className="college_button_wrp">
                                                <a className="active_bt" href={'/news-and-event/' + c.slug}>View Details</a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {totalPages && totalPages > 1 ? <><div className="spac_02"></div>
                            <div className="button-section text-center clearfix">
                                <Pagination
                                    page={page}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={handlePageChange}
                                    count={totalPages}
                                    className="pagination"
                                />
                            </div></> : ''}

                        {/*}
                        <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                            {(retrievRowsByColNum(newsListing, 4)).map((i, k) => i && i.length > 0 && 
                                <>
                                    <div className="item" key={`news${k}`} >
                                            {i.map((c, ck) => 
                                                <div key={ck} className="entrance-exam-news entrancenews">
                                                    <img alt={c.title} src={c.cover_image ? config.baseUrlfromconfig + c.cover_image : EntranceExam} />
                                                    <h2><span>{c.title}</span></h2>
                                                    <div className="college_button_wrp">
                                                        <a className="active_bt" href={'/news-and-event/' + c.slug}>View Details</a>
                                                    </div>
                                                      
                                                </div>
                                                // <div key={`key-${ck}`}>col {c}</div>
                                            )}
                                        <br />
                                    </div>
                                </>
                            )}
                        </OwlCarousel>
                        
                                            {*/}

                        <div className="spac_02"></div>
                        {newsListingNew && newsListingNew.length > 0 ? <>
                            <h3 className="main-heading left-align">General <span>News</span></h3>
                            {
                                newsListingNew && newsListingNew.map((i, x) => {
                                    let eventlink = '/news-and-event/' + i.slug;
                                    return (
                                        <div className="col-xl-4 col-lg-4 col-md-6 blog-post-entrancenews">
                                            <div key={x} className="entrance-exam-news entrancenews">
                                                <img alt={i.title} src={i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
                                                <h2>{i.title}</h2>
                                                {/* <div>Date: <b>{moment(i.created_at).format('D MMM YYYY')}</b></div> */}
                                                <div className="college_button_wrp">
                                                    <a className="active_bt" href={'/news-and-event/' + i.slug}>View Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </> : ''}


                        {totalPages1 && totalPages1 > 1 ? <><div className="spac_02"></div>
                            <div className="button-section text-center clearfix">
                                <Pagination
                                    page={page1}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={handlePageChange1}
                                    count={totalPages1}
                                    className="pagination"
                                />
                            </div></> : ''}

                        {/**}
                        <div className="spac_02"></div>

                        {newsListingNew && newsListingNew.length > 0 ? <>   <div className="button-section text-center clearfix">
                            {isFetching1 && <p>Loading more data...</p>}
                            {
                                totalPages1 && totalPages1 !== page1 && !isFetching1 && (
                                    <button className="button btn" onClick={() => getNewsEventData(page1 + 1, newsListingNew)}>Load more</button>
                                )


                            }
                        </div></> : ''}

                                                
{*/}

                        <div className="spac_02"></div>
                    </div>

                    <div className="col-lg-4" >
                        {topCollegeArr && topCollegeArr.length > 0 ? <>
                            <div className="widgets get-details-now-form right-widget">

                                <h4 className="title-heading">Upcoming <span>Admissions</span></h4>
                                {
                                    topCollegeArr && topCollegeArr.map((el, idx) => {
                                        //console.log(el);
                                        return (
                                            <div className="coming-exam-block">
                                                <span className="icon">
                                                    <img alt={el.name} src={el.logo ? process.env.REACT_APP_IMG_URI + el.logo : CollegeIMG} />
                                                    {/* <div>12<sub>th</sub><br/> Jan</div> */}
                                                </span>
                                                <a href={'/college/' + el.slug} className='nohyperlink'> <h2>{el.name}</h2></a>
                                            </div>
                                        )
                                    })
                                }
                                <div className="button-section text-center">
                                    <a href={'/admission'} className="button btn">View All</a>
                                </div>

                            </div> </> : ''}

                        {
                            promoBannerArr && promoBannerArr.map((i) => {
                                let banner_image = i.image ? config.baseUrlfromconfig + i.image : TopManagementCourseBG;
                                return (
                                    <>
                                        <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${banner_image})` }}>
                                            <div className="course-center">
                                                <h2>&nbsp;</h2>
                                                <p>&nbsp;</p>
                                                {/* <h2>Top Management Courses in Bangalore</h2>
                                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p> */}
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }

                        {/* <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top <span>Management</span> Courses in <span>Bangalore</span> </h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );

    // return (
    //     <section className="course-overviews-section ">
    //         <div className="container aos-init aos-animate" >
    //             <div className="row">
    //                 <div className="col-lg-8 overviews-course left-exam-section" >
    //                     <h3 className="main-heading left-align">Entrance <span>Exams</span></h3>

    //                     <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
    //                         {newsListing.map((i, x) => (

    //                             <div className="item" key={`news${x}`}>
    //                                 <div key={x} className="entrance-exam-news">
    //                                     <img src={i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
    //                                     <h2><a href={`/news-and-event/${i.slug}`}>{i.title}</a></h2>
    //                                     <div className="college_button_wrp">
    //                                         <a className="active_bt" href={'/news-and-event/' + i.slug}>View Details</a>
    //                                     </div>
    //                                     {/* <div>Date: <b>{moment(i.created_at).format('D MMM YYYY')}</b></div> */}
    //                                 </div>
    //                             </div>
    //                         ))}

    //                     </OwlCarousel>
    //                     {
    //                         newsListing && newsListing.map((i, x) => {
    //                             let eventlink = '/news-and-event/' + i.slug;
    //                             return (
    //                                 <>
    //                                     <div key={x} className="entrance-exam-news">
    //                                         <img src={i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
    //                                         <h2><a href={eventlink}>{i.title}</a></h2>
    //                                         <div className="college_button_wrp">
    //                                             <a className="active_bt" href={'/news-and-event/' + i.slug}>View Details</a>
    //                                         </div>
    //                                         {/* <div>Date: <b>{moment(i.created_at).format('D MMM YYYY')}</b></div> */}
    //                                     </div>
    //                                 </>
    //                             )
    //                         })
    //                     }
    //                     <div className="spac_02"></div>
    //                     <div className="button-section text-center clearfix">
    //                         {isFetching && <p>Loading more data...</p>}
    //                         {

    //                             totalPages && totalPages !== page && !isFetching && (
    //                                 <button className="button btn" onClick={() => getNewsData(page + 1, newsListing)}>Load more</button>
    //                             )}
    //                     </div>
    //                     <div className="spac_02"></div>
    //                     {newsListingNew && newsListingNew.length > 0 ? <>
    //                         <h3 className="main-heading left-align">General <span>News</span></h3>
    //                         {
    //                             newsListingNew && newsListingNew.map((i, x) => {
    //                                 let eventlink = '/news-and-event/' + i.slug;
    //                                 return (
    //                                     <div key={x} className="entrance-exam-news">
    //                                         <img src={i.cover_image ? config.baseUrlfromconfig + i.cover_image : EntranceExam} />
    //                                         <h2><a href={eventlink}>{i.title}</a></h2>
    //                                         {/* <div>Date: <b>{moment(i.created_at).format('D MMM YYYY')}</b></div> */}
    //                                         <div className="college_button_wrp">
    //                                             <a className="active_bt" href={'/news-and-event/' + i.slug}>View Details</a>
    //                                         </div>
    //                                     </div>
    //                                 )
    //                             })
    //                         }

    //                     </> : ''}

    //                     <div className="spac_02"></div>

    //                     {newsListingNew && newsListingNew.length > 0 ? <>   <div className="button-section text-center clearfix">
    //                         {isFetching1 && <p>Loading more data...</p>}
    //                         {
    //                             totalPages1 && totalPages1 !== page1 && !isFetching1 && (
    //                                 <button className="button btn" onClick={() => getNewsEventData(page1 + 1, newsListingNew)}>Load more</button>
    //                             )


    //                         }
    //                     </div></> : ''}

    //                     <div className="spac_02"></div>
    //                 </div>

    //                 <div className="col-lg-4" >
    //                     {topCollegeArr && topCollegeArr.length > 0 ? <>
    //                         <div className="widgets get-details-now-form right-widget">

    //                             <h4 className="title-heading">Upcoming <span>Admissions</span></h4>
    //                             {
    //                                 topCollegeArr && topCollegeArr.map((el, idx) => {
    //                                     console.log(el);
    //                                     return (
    //                                         <div className="coming-exam-block">
    //                                             <span className="icon">
    //                                                 <img src={el.logo ? process.env.REACT_APP_IMG_URI + el.logo : CollegeIMG} />
    //                                                 {/* <div>12<sub>th</sub><br/> Jan</div> */}
    //                                             </span>
    //                                             <a href={'/college/' + el.slug}> <h2>{el.name}</h2></a>
    //                                         </div>
    //                                     )
    //                                 })
    //                             }
    //                             <div className="button-section text-center">
    //                                 <a href={'/admission'} className="button btn">View All</a>
    //                             </div>

    //                         </div> </> : ''}

    //                     <div className="widgets right-lerge-news top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
    //                         <div className="course-center">
    //                             <h2>Top <span>Management</span> Courses in <span>Bangalore</span> </h2>
    //                             <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section>
    // );
};

export default OverviewSection;