import React, { FC } from 'react';
//import './styles.css';

const Breadcrumbs: FC <{viewDetails : any}>= ({viewDetails}) => {
    
    return (
        <div className="breadcrumbs">	
            <div className="container" >
            <ol>
                <li><a href="/" className="active blue-color">Home</a> </li>
                <li><a href="/news-and-event" className="active blue-color">News and Events</a> </li>
                <li><a href="">{viewDetails.title}</a></li>
            </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;