import React, { FC } from 'react';
import './styles.css';

import BestCollegeBG from 'src/assets/img/best-colleges.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';


const Interested: FC = () => {
    //const [num, setNum] = React.useState(331231);

    return (
        <section className="best-college container-fluid spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="row best-management-in-course text-center" style={{ background: `url(${BestCollegeBG})` }} >
                        <div className="course-center">
                            <h2>Are You Eligible for <span>NRI Quota?</span></h2>
                            <p>Connect with our experts to avail admission assistance for NRI quota seats.</p>
                            <div className="text-center"><GlobalPopupEnquiry  className="btn btn btn-primary"  title="Request Call Back" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Interested;