import React, { FC } from 'react';
import './styles.css';
import AllCollegeAdmissionBG from 'src/assets/img/all-college-adminsion-bg.jpg';
import EnquiryFormTalkToExpert from 'src/components/EnquiryFormTalkToExpert';
//import EnquiryDetailForm from 'src/components/EnquiryDetailForm';

const Breadcrumbs: FC = () => {
    
    return (
        <section className="all-colleges-admission container-fluid spac_01 talksection" style={{ background: `#3e6eb0 url(${AllCollegeAdmissionBG})`, backgroundSize: 'cover' }}  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 " data-aos="fade-up"> 
                        <h2>Still Confused? We'll Help</h2>
                        {/* <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p> */}
                    </div> 

                    <div className="col-lg-6 col-md-6 contact-form" data-aos="fade-up"> 
                        <h2>Talk to our Experts</h2>
                        {/* <EnquiryDetailForm type="admissions"/> */}
                         <EnquiryFormTalkToExpert type={'Exam'} page={'blogdetail'} /> 
                        {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="form-group">
                                <input type="text" name="name" className="form-control" id="name" placeholder="First Name"  />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" name="email" id="email" placeholder="Email"  />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Phone Number"  />
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" name="location" id="location" placeholder="Location"  />
                            </div>                     
                            <div className="text-left"><button type="submit">Submit</button></div>
                        </form>*/}
                    </div> 
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;