import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';

import { config } from 'src/config';
import moment from 'moment';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const LocationSection: FC<{}> = ({ }) => {
    const [num, setNum] = React.useState(331231);

    return (
        <section className="college-locations container-fluid spac_02" data-aos="fade-up">
            <div className="container ">
                <div className="row">
                    <h3 className="main-heading  left-align">Bangalore Location</h3>
                    <div className="col-lg-12">
                        <div className="border-radius-20 google-map">
                            <iframe width="100%" height="580" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8249163825776!2d77.59618631482145!3d12.91897199089042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15064e73a073%3A0x2ad435cfe4393ce6!2sBangalorestudy.com!5e0!3m2!1sen!2sin!4v1574357161268!5m2!1sen!2sin" scrolling="no" ></iframe>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </section>
    );
};

export default LocationSection;