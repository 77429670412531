import React, { FC , useEffect, useState } from 'react';
// import React, { FC, useEffect, useState } from 'react';

import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses1.jpg';
import NHGEnquiryForm from 'src/components/NHGEnquiryForm/GlobalPopupEnquiry';

// import CallNow from 'src/assets/img/call-now.svg';
// import SearchIcon from 'src/assets/img/icons/search.svg';
// import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
import { config, pConfig } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Helmet from 'react-helmet';
import GetAppIcon from '@material-ui/icons/GetApp';

const SearchHeader: FC<{ schooldetail: any, slug: any }> = ({ schooldetail, slug }) => {
    const imageUrl = schooldetail.school_logo ? config.baseUrlfromconfig + schooldetail.school_logo : '';

    const [gurukul, setGurukul] = useState(false);

    const linksToCheck = [
        'new-horizon-gurukul-school-bangalore',
        'new-horizon-vidya-mandir-nhvm-bangalore',
        'new-horizon-public-school-nhps-bangalore',
        'new-horizon-international-school-nhis-bangalore',
       
      ];


      const getcheck = () => {
        try {
            const currentURL = window.location.href;
            const isGurukul = linksToCheck.some(link => currentURL.includes(link));
            // console.log('Current URL:', currentURL);
            // console.log('Is Gurukul:', isGurukul);
            setGurukul(isGurukul);
          } catch (error) {
            console.error('Error in URL checking:', error);
          }
          
      };

    // const getcheck = async () => {
    //     if (/new-horizon-gurukul-nhg-vidyagiri-prasanthi-nilayam-anantapur/.test(window.location.href)) {
    //         setgurukul(true);
    //     }
    // }


    useEffect(() => {
        getcheck();

    }, []);


    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org/",
                            "@type": "HighSchool",
                            "name": `${schooldetail.school_name}`,
                            "logo": `${imageUrl}`,
                            "url": `${pConfig.siteUrl}school/${slug}`,
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": `${schooldetail.address}`
                            }
                        }
                    )}
                </script>
            </Helmet>
            <section className="inner-top-header" id="hero" style={{ background: `url(${ManagementCourseBG})` }} >
            {/* <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${ManagementCourseBG})` }} > */}
                <div className="hero-container d-flex">
                    <div className="container" >
                        <div className="row college-block">
                            <div className="top-college-header">
                                <h1><img src={imageUrl} style={{ width: `80px` }} alt={schooldetail.school_name} /> {schooldetail.school_name}</h1>
                                {schooldetail.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {schooldetail.avg_rating}</div> : ''}
                                {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                <ul className='inlineflex' style={{display:'inlin-grid'}}>
                                    <li><i className="bi bi-pin-map-fill"></i>&nbsp; {schooldetail.address}</li>
                                    {/* <li>Approvals and Recognitions: <strong>{
                                        schooldetail.schoolrecognition && schooldetail.schoolrecognition.map((element, index) => {

                                            return (
                                                <>
                                                    {index == 0 ? ' ' + element.schrecognitionname.recognition_approval_name : ', ' + element.schrecognitionname.recognition_approval_name}
                                                </>
                                            )

                                        })
                                    } </strong></li> */}
                                    <li>Est Year:&nbsp;<strong>{schooldetail.established}</strong></li>
                                    {/* <li>Affiliation: <strong>   {
                                        schooldetail.schoolaffiliations && schooldetail.schoolaffiliations.map((element, index) => {

                                            return (
                                                <>
                                                    {index == 0 ? ' ' + element.schaffiliationname.other_affiliations_name : ', ' + element.schaffiliationname.other_affiliations_name}
                                                </>
                                            )

                                        })
                                    } </strong></li> */}
                                    <li>Gender Accepted:&nbsp;<strong>{schooldetail.genders_accepted}</strong></li>
                                    <li>Board:&nbsp;<strong>
                                    {
                                        schooldetail.boardschools && schooldetail.boardschools.map((element, index) => {

                                            return (
                                                <>
                                                    {index == 0 ? ' ' + element.schbrdname.name : ', ' + element.schbrdname.name}
                                                </>
                                            )

                                        })
                                    }
                                        </strong></li>
                                    {/* <li>School Level:&nbsp;<strong>{schooldetail.schoollevel && schooldetail.schoollevel.level}</strong></li> */}
                                    <li>School Level:&nbsp;<strong>{
                                        schooldetail.schoollevels && schooldetail.schoollevels.map((element, index) => {

                                            return (
                                                <>
                                                    {index == 0 ? ' ' + element.schlevelname.level_name : ', ' + element.schlevelname.level_name}
                                                </>
                                            )

                                        })
                                    }</strong></li>
                                </ul>
                                <div className="spac_02"></div>
                                <div className="col-lg-12 button-section">
                                {gurukul ? <NHGEnquiryForm className="medical-button btn" />:<GlobalPopupEnquiry className="medical-button btn" />}

                                    <a href="#reviewssection" className="reviews-button btn">Write a Review</a>
                                    {/* <GlobalPopupEnquiry  pagename={'Download Brochure'} className="broucher-button btn" title= {<><GetAppIcon/>Download Brochure</> }  /> */}
                                    {gurukul ? <NHGEnquiryForm className="broucher-button btn" title= {<><GetAppIcon/>Download Brochure</> }  />:<GlobalPopupEnquiry  pagename={'Download Brochure'} className="broucher-button btn" title= {<><GetAppIcon/>Download Brochure</> }  />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SearchHeader;