import React, { FC } from 'react';
//import './styles.css';

const Breadcrumbs: FC = () => {
    
    return (
        <div className="breadcrumbs">	
            <div className="container" >
            <ol>
                <li><a href="/" className="active blue-color">Home</a> </li>
                <li><a href="">Scholarships in India</a></li>
            </ol>
            </div>
	    </div>
    );
};

export default Breadcrumbs;