import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

import { pConfig } from 'src/config';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const TopColleges: FC<{topCollegeArr:any}> = ({topCollegeArr}) => {
    console.log("topCollegeArr inside",topCollegeArr);

    const owlOptions = {
        margin: 10, nav: true, autoplay: true,autoplayTimeout: 3000,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true,loop:true},
            400: {items:1, dots:false, nav:true,loop:true},
            600: {items: 1, dots:false, nav:true,loop:true},
            700: {items: 2, dots:false, nav:true,loop:true},
            1000:{
                items:3,
                nav:true,
                loop:true
            },
            1440:{
                items:4,
                nav:true,
                loop:true
            }
        },
    };
    let url=pConfig.siteUrl+"college/";

    return (
        <>
        <section className="container-fluid spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal exam_section_slider examdesignslider">
                        <h3 className="common_sec_h">Top Colleges</h3>

                        <OwlCarousel {...owlOptions} className="loop_colleges loop_Exams loop_nav owl-carousel owl-theme">
                        {topCollegeArr && topCollegeArr.map((el, idx)=>(
                            <div className="item" key={`topexm${idx}`}>
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12 wrapexamcontent">
                                            {el.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.avg_rating}</div> : '' }
                                            <img alt={el.name} src={process.env.REACT_APP_IMG_URI+el.CollegeGalleriess[0].image} className="full_wdth" />
                                            <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{el.name}</span>}>
                                                <h3 className='leftalign'>{el.name}</h3>
                                            </BootstrapTooltip>
                                            {/* <h3>{el.name}</h3> */}
                                            {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}
                                            <div className="college_button_wrp">
                                            <GlobalPopupEnquiry />
                                            <a href={url+el.slug}>View Details</a>
                                            {/* <a href={`college/${el.slug}`}>View Details</a> */}
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </OwlCarousel>
                    </div>
                </div>
                <div className="button-section text-center">
                    <a href={'/colleges'} className="button btn">View All</a>
                </div>
            </div>
        </section>
  
        {/* <section className="container-fluid spac_01 topColgs" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="common_sec_h">Top Colleges</h3>
                        <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                            {topCollegeArr && topCollegeArr.map((el, idx)=>(

                                <div className="item" key={`colgs${idx}`}>
                                    <div className="testi_wrp">
                                        <div className="testi_hover"></div>
                                        <div className="inner_wrp">
                                            <div className="test_content_wrp col-lg-12 wrapcontent">
                                                {el.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.avg_rating}</div> : '' }
                                                <img alt={el.name} src={process.env.REACT_APP_IMG_URI+el.logo} className="full_wdth" />
                                                <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{el.name}</span>}>
                                                    <h3>{el.name}</h3>
                                                </BootstrapTooltip>
                                                {/* <h3>{el.name}</h3> */}
                                                {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> 
                                                <div className="college_button_wrp">
                                                   
                                                    <GlobalPopupEnquiry />
                                                    <a href={`college/${el.slug}`}>View Details</a>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </OwlCarousel>
                    </div>
                </div>
                <div className="button-section text-center">
                    <a href={'/colleges'} className="button btn">View All</a>
                </div>
            </div>
        </section> */}
        </>
    );
};

export default TopColleges;