import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import Talk from './Talk';
import TopColleges from './TopColleges';
import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
const useStyles = makeStyles(() => ({
    root: {}
}));

const AdmissionPage: FC = () => {
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);
    const isMountedRef = useIsMountedRef();
    const [courseStreamArr, setCourseStreamArr] = useState([]);
    //const { slug } = useParams();
    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [isAdmissionLoaded, setAdmissionLoaded] = useState(false);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);

        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        setHeroLoaded(true);
    }

    const [admissionview, setadmissionview] = useState([]);
    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);

    const getadmissionview = useCallback(async () => {
        try {
            const response = await axios.get('api/website/page/get/admissions');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setadmissionview(response.data.data);
                setmetatitle(response.data.data.meta_title);
                setmetadescription(response.data.data.meta_description);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const [collegedetail, setcollegedetail] = useState([]);
    //const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [stream, setstream] = useState([]);
    const [HasMore, setHasMore] = useState(true);
    const [ownerships, setOwnerships] = useState([]);
    const [city, setcity] = useState([]);
    const [accreditations, setAccreditations] = useState([]);
    const [managments, setmanagments] = useState([]);
    const [searchFilters, setSearchFilters] = React.useState<any>([]);

    const searchByFilters = (filters) => {
        //console.log('filetsr:-', filters);
        setPage(1);
        setSearchFilters(filters);
    };

    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(15);
    const [total, setTotal] = useState<number>(0);
    const [totalpage, setTotalpage] = useState<number>(0);

    const getstream = useCallback(async () => {
        try {
            //const response = await axios.get('api/website/home/group');
            const response = await axios.get('api/website/home/streams?page=1&size=10000&columnname=listing_order&orderby=asc');

            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setstream(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getOwnerships = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/collegetype');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setOwnerships(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getcity = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/citys');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setcity(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getAccreditations = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/accreditaions');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setAccreditations(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getmanagments = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/managments');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setmanagments(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getcollegelist = useCallback(async (page, collegedetail, searchFilters: any, limit) => {
        setIsFetching(true);
        try {
            let usePagination = true;
            let pages = page;
            let count = limit;
            const params = {}
            //params['page'] =page;
            //params['size'] = '12';
            params['page'] = pages;
            params['size'] = count;
            /*  params['columnname'] = 'listing_order';
            params['orderby'] = 'ASC';
            params['searchText'] = '0';
            params['searchfrom'] = '0';
            params['page'] = '0'; */

            if (searchFilters && searchFilters != '' && Object.keys(searchFilters).length !== 0) 
            {
                // let dtkeyarr = ['poc_start_from', 'poc_start_to', 'poc_end_from', 'poc_end_to', 'last_activity_from_date', 'last_activity_to_date', 'video_call_schedule_from', 'video_call_schedule_to'];
                let dtkeyarr = [];
                for (let key of Object.keys(searchFilters)) {
                    let value = searchFilters[key];

                    if (value && value !== '' && value !== null && value !== undefined) {
                        if (Array.isArray(value) === true) {
                            // array type keys
                            if (value.length > 0) {
                                params[key] = [value];
                            }
                        }
                    }
                }
            }

            // const id = slug;//'engineering';
            const response = await axios.get('api/website/home/topcollegebangalore', { params: params });
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                //setPage(page);
                //setcollegedetail([...collegedetail, ...response.data.data]);
                setcollegedetail(response.data.data);
                // setPage((prevPageNumber) => prevPageNumber + 1);
                setHasMore(response.data.data.length > 0);
                setIsFetching(false);

                let pagetobeshow = response.data.currentPage;
                setPage(pagetobeshow);
                setLimit(count);
                setTotal(response.data.totalItems);
                let pagecount = Math.ceil(response.data.totalItems / count);
                setTotalpage(pagecount);
                setAdmissionLoaded(true);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const handlePageChange = (event: any, value: number): void => {
        //alert(value);
        // setPage(value);
        getcollegelist(value, [], searchFilters, limit);
    };

    useEffect(() => {
        getadmissionview();
        getHeroData();
        getstream();
        getcity();
        getOwnerships();
        getAccreditations();
        getmanagments();
        getcollegelist(page, collegedetail, searchFilters, limit);
    }, [searchFilters]);

    /*useEffect(() => {
        getcollegelist(1, [], searchFilters, limit);
    }, [searchFilters]);*/

    useEffect(() => {
        //getHeroData();
        
    }, []);

    return (
        <>
            {isAdmissionLoaded ? 
            <>
                <Helmet>
                    <title>{metatitle}</title>
                    <meta name="description" content={metadescription} />
                    <link rel="canonical" href={`${pConfig.siteUrl}admission`} />
                </Helmet>
                <Header />
                <Breadcrumbs />
                <OverviewSection admissionview={admissionview} searchByFilters={(filters) => searchByFilters(filters)}
                    page={page} totalpage={totalpage} handlePageChange={handlePageChange}
                    stream={stream} ownerships={ownerships} city={city} accreditations={accreditations} managments={managments} getcollegelist={getcollegelist} collegedetail={collegedetail} isFetching={isFetching} HasMore={HasMore} />
                <Talk />
                {/*  <TopColleges/> */}
            </>
            :
                <div style={{ display: 'flex', justifyContent: 'center', height:'600px',flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div> 
            }
        </>
    );
};

export default AdmissionPage;