import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';

import College from 'src/assets/img/college.jpg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';
import moment from 'moment';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OverviewSection: FC<{ aboutusdata: any }> = ({ aboutusdata }) => {
    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate">
                <div className="row">
                    <div className="col-lg-12 overviews-course left-exam-section text-center">
                        <h3 className="main-heading">WHO WE ARE?</h3>
                        <p className='blackcolor'><span dangerouslySetInnerHTML={{ __html: aboutusdata.who_we_are }} /></p>
                    </div>
                    <div className="spac_02"></div>
                    <div className="col-lg-6 overviews-course left-exam-section text-center">
                        <h3 className="main-heading">OUR MISSION</h3>
                        <p className='blackcolor'><span dangerouslySetInnerHTML={{ __html: aboutusdata.our_mission }} /></p>
                    </div>

                    <div className="col-lg-6 overviews-course left-exam-section text-center">
                        <h3 className="main-heading">OUR VISION</h3>
                        <p className='blackcolor'><span dangerouslySetInnerHTML={{ __html: aboutusdata.our_vision }} /></p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;