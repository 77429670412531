import axios from 'axios';
import { pConfig } from 'src/config';
import { config } from 'src/config';

 const axiosInstance = axios.create({
  // baseURL: "http://localhost:8081/",
  baseURL: pConfig.baseUrl,
  
 });


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
