import React, { FC } from 'react';
import './styles.css';

import InterestedCourseBG from 'src/assets/img/interested-in-course-bg1.png';
import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const Breadcrumbs: FC<{collegedetail : any}> = ({collegedetail}) => {
    return (
        <section className="container-fluid spac_01 "  data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="interested-in-course text-center"  style={{ background: `#fff url(${InterestedCourseBG})` }}>
                        <div className="course-center">   
                            <p>Interested to know more about this Board? Get more details now.</p>
                            <h2>{collegedetail.name}</h2>
                            <div className="col-lg-12 button-section">
                                <div className="text-center"><GlobalPopupEnquiry  className="btn btn btn-primary"  title="Request Call Back" /></div>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>
        </section>
    );
};

export default Breadcrumbs;