import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TopSchools from './TopSchools';
import Talk from './Talk';
import Interested from './Interested';
import Scholarship from './Scholarship';
import { pConfig } from 'src/config';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Helmet from 'react-helmet';

const useStyles = makeStyles(() => ({
    root: {}
}));

const HomePage: FC = () => {
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);
    const isMountedRef = useIsMountedRef();
    const [bannerArr, setBannerArr] = useState([]);
    const [countBlockInfo, setCountBlockInfo] = useState({});
    const [courseStreamArr, setCourseStreamArr] = useState([]);

    const [topCollegeArr, setTopCollegeArr] = useState([]);
    const [topUniversityArr, setTopUniversityArr] = useState([]);
    const [topExamArr, setTopExamArr] = useState([]);
    const [testimonialsArr, setTestimonialsArr] = useState([]);

    const [blogArr, setBlogArr] = useState([]);
    const [newsArr, setNewsArr] = useState([]);

    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [isTopColLoaded, setTopColLoaded] = useState(false);
    const [isBlogLoaded, setBlogLoaded] = useState(false);
    const [promoBannerArr, setPromoBannerArr] = useState<any>([]);
    const [isSchoolLoaded, setSchoolLoaded] = useState(false);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "course_streams_head" }]));
        const heroResp = await axios.post('api/website/home/header', formData);

        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);
        setHeroLoaded(true);
    }

    const getCollageUnivData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "college" }, { data: "university" }, { data: "exam" }, { data: "testimonial" }]));
        const univColgResp = await axios.post('api/website/home/topcrousel', formData);

        setTopCollegeArr(univColgResp.data.data.topcollege.data);
        setTopUniversityArr(univColgResp.data.data.topuniversity.data);
        setTopExamArr(univColgResp.data.data.topexam.data);
        setTestimonialsArr(univColgResp.data.data.testimonials.data)
        setTopColLoaded(true);
    }

    const geBlogData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "blogs" }, { data: "news" }]));
        const blogResp = await axios.post('api/website/home/blogsandnews', formData);

        console.log(blogResp.data.data.blogs.data)
        setBlogArr(blogResp.data.data.blogs.data);
        setNewsArr(blogResp.data.data.news.data);


        setBlogLoaded(true);
    }

    const [schooldetail, setSchooldetail] = useState([]);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);
    const [boards, setBoards] = useState([]);
    const [schooltypes, setSchooltypes] = useState([]);
    const [HasMore, setHasMore] = useState(true);
    const [schoollevels, setSchoolLevels] = useState([]);
    const [city, setcity] = useState([]);
    const [schoolclassification, setSchoolclassification] = useState([]);
    const [managments, setmanagments] = useState([]);
    const [levels,seLevels] = useState([]);

    const [searchFilters, setSearchFilters] = React.useState<any>([]);
    const [schoolblog, setSchoolBlog] = React.useState<any>([]);
    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);

    const searchByFilters = (filters) => {
        //console.log('filetsr:-', filters);
        setPage(1);
        setSearchFilters(filters);
    };

    const getBoards = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/schoolboards');
            if (isMountedRef.current) {
                //console.log("response.data.data");
                //console.log(response.data.data);
                setBoards(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getSchooltypes = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/schooltypes');
            if (isMountedRef.current) {
                //console.log("response.data.data");
                //console.log(response.data.data);
                setSchooltypes(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getSchoolLevels = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/schoollevels');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setSchoolLevels(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getcity = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/citys');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setcity(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getSchoolclassification = useCallback(async () => {
        try {
            const response = await axios.get('api/website/home/schoolclassification');
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setSchoolclassification(response.data.data);
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    const getSchoollist = useCallback(async (page, schooldetail, searchFilters: any) => {
        setIsFetching(true);
        try {
            const params = {}
            //params['page'] =page;
            //params['size'] = '12';
            /*  params['columnname'] = 'listing_order';
            params['orderby'] = 'ASC';
            params['searchText'] = '0';
            params['searchfrom'] = '0';
            params['page'] = '0'; */

            if (searchFilters && searchFilters != '' && Object.keys(searchFilters).length !== 0) {
                // let dtkeyarr = ['poc_start_from', 'poc_start_to', 'poc_end_from', 'poc_end_to', 'last_activity_from_date', 'last_activity_to_date', 'video_call_schedule_from', 'video_call_schedule_to'];
                let dtkeyarr = [];
                for (let key of Object.keys(searchFilters)) {
                    let value = searchFilters[key];
                    if (value && value !== '' && value !== null && value !== undefined) {
                        if (Array.isArray(value) === true) {
                            // array type keys
                            if (value.length > 0) {
                                params[key] = [value];
                            }
                        }
                    }
                }
            }

            // const id = slug;//'engineering';
            const response = await axios.get('api/website/home/topschoolbangalore?page=1&size=12&columnname=listing_order&orderby=asc', { params: params });
            if (isMountedRef.current) {
                //  alert("asfd");
                //console.log("response.data.data");
                //console.log(response.data.data);
                setPage(page);
                setSchooldetail([...schooldetail, ...response.data.data]);
                //setSchooldetail(response.data.data);
                //setPage((prevPageNumber) => prevPageNumber + 1);
                setHasMore(response.data.data.length > 0);
                setIsFetching(false);
                setSchoolLoaded(true)
            }
        } catch (err) {
            console.error(err);
        }
    }, [isMountedRef]);

    
  const getschoolblog = useCallback(async () => {
    try {
     
      const response = await axios.get('api/website/blog/get?columnname=listing_order&orderby=asc&home_view_status=school_page');
      if (isMountedRef.current) {
        setSchoolBlog(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=All_school_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);
  const getLevels = useCallback(async () => {
    try {
        const response = await axios.get('api/website/home/schoollevels');
        if (isMountedRef.current) {
            //  alert("asfd");
            console.log("response.data.data");
            console.log(response.data.data);
            seLevels(response.data.data);
        }
    } catch (err) {
        console.error(err);
    }
}, [isMountedRef]);

    useEffect(() => {
        getHeroData();
        //getCollageUnivData();
        //geBlogData();
        getSchoollist(page, schooldetail, searchFilters);
        getLevels();
        getBoards();
        getSchooltypes();
        getSchoolLevels();
        getcity();
        getSchoolclassification();
        getschoolblog();
        getpromobanner();
    }, []);

    useEffect(() => {
        getSchoollist(1, [], searchFilters);
    }, [searchFilters]);

    return (
        <>
            {isSchoolLoaded ? 
            <>
            <Helmet>
                <title>{metatitle && metatitle != '' ? metatitle : 'Top Schools in Bangalore | Bangalorestudy.com'}</title>
                <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for School admission? Bangalorestudy.com helps you with a list of Schools to pursue your education in Bangalore. Call us now'} />
                <link rel="canonical" href={`${pConfig.siteUrl}school`} />
            </Helmet>
            <Header getSchoollist={getSchoollist}/>
            <Breadcrumbs />
            <OverviewSection
                searchByFilters={(filters) => searchByFilters(filters)} getSchoollist={getSchoollist} page={page}
                boards={boards} schooltypes={schooltypes} levels={levels} schoollevels={schoollevels} city={city} schoolclassification={schoolclassification} getschoollist={schooldetail} schooldetail={schooldetail} isFetching={isFetching} HasMore={HasMore}  />
            <TopSchools />
            <Talk />
            <Interested  promoBannerArr={promoBannerArr} />
            <Scholarship schoolblog={schoolblog} />
            </>
        :
            <div style={{ display: 'flex', justifyContent: 'center', height:'600px',flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div> 
        }
        </>
    );
};

export default HomePage;