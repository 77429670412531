import React, { FC, useEffect, useState, useContext } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';
import HeroArea from './HeroArea';
import CounterSection from './CounterSection';
import TrendingCourses from './TrendingCourses';
import TopColleges from './TopColleges';
import TopUniversities from './TopUniversities';
import MidFormBanner from './MidFormBanner';
import ExamsSection from './ExamsSection';
import LatestBlog from './LatestBlog';
import WebCounters from './WebCounters';
import ServicesSection from './ServicesSection';
import Testimonials from './Testimonials';
import ProtalsSection from './ProtalsSection';
import { pConfig } from 'src/config';
import { count } from 'console';
import Helmet from 'react-helmet';
import LazyLoad from 'react-lazyload';

const useStyles = makeStyles(() => ({
    root: {}
}));

const HomePage: FC = () => {
    const classes = useStyles();
    const setCtx = useContext(SettingsContext);

    const [bannerArr, setBannerArr] = useState([]);
    const [countBlockInfo, setCountBlockInfo] = useState({});
    const [courseStreamArr, setCourseStreamArr] = useState([]);

    const [newcourseStreamArr, setNewCourseStreamArr] = useState([]);

    const [topCollegeArr, setTopCollegeArr] = useState([]);
    const [topUniversityArr, setTopUniversityArr] = useState([]);
    const [topExamArr, setTopExamArr] = useState([]);
    const [testimonialsArr, setTestimonialsArr] = useState([]);

    const [blogArr, setBlogArr] = useState([]);
    const [newsArr, setNewsArr] = useState([]);

    const [isHeroLoaded, setHeroLoaded] = useState(false);
    const [isTopColLoaded, setTopColLoaded] = useState(false);
    const [isBlogLoaded, setBlogLoaded] = useState(false);

    const getHeroData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "banners" }, { data: "course_streams_head" }, { data: "count_block" }]));
        const heroResp = await axios.post('api/website/home/header', formData);

        setBannerArr(heroResp.data.data.banners);
        setCountBlockInfo(heroResp.data.data.count_block);
        setCourseStreamArr(heroResp.data.data.course_streams_head);

        setCtx.setCourseData(heroResp.data.data.course_streams_head);

    }

    const getCourseData = async () => {
        const formData = new FormData();
        //formData.append('getdata', JSON.stringify([{data: "college"},{data: "university"},{data: "exam"},{data: "testimonial"}]));
        const getstreams = await axios.get('api/website/home/streams?columnname=listing_order&orderby=asc&home_view_status=home_page&page=1&size=10000');

        setNewCourseStreamArr(getstreams.data.data);
        setHeroLoaded(true);
    }


    const getCollageUnivData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "college" }, { data: "university" }, { data: "exam" }, { data: "testimonial" }]));
        const univColgResp = await axios.post('api/website/home/topcrousel', formData);
        if (univColgResp.data.data) {
            setTopCollegeArr(univColgResp.data.data.topcollege.data);
            setTopUniversityArr(univColgResp.data.data.topuniversity.data);
            setTopExamArr(univColgResp.data.data.topexam.data);
            setTestimonialsArr(univColgResp.data.data.testimonials.data)
        }
        setTopColLoaded(true);
    }

    const geBlogData = async () => {
        const formData = new FormData();
        formData.append('getdata', JSON.stringify([{ data: "blogs" }, { data: "news" }]));
        const blogResp = await axios.post('api/website/home/blogsandnews', formData);

        if (blogResp.data.data) {
            if (blogResp.data.data.blogs.data && blogResp.data.data.blogs.data != 'No blog found')
                setBlogArr(blogResp.data.data.blogs.data);


            if (blogResp.data.data.news.data)
                setNewsArr(blogResp.data.data.news.data);

        }


        setBlogLoaded(true);
    }

    const [metatitle, setmetatitle] = useState<any>([]);
    const [metadescription, setmetadescription] = useState<any>([]);

    const [homevideosdata, sethomevideosdata] = useState<any>([]);

    const gethomedata = async () => {
        const response = await axios.get('api/website/page/get/home');
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description)
    }

    const getvideosdata = async () => {
        const response = await axios.get('api/website/home/hompagevideos?columnname=listing_order&orderby=asc');
        sethomevideosdata(response.data.data)
    }

    useEffect(() => {
        getHeroData();
        getCollageUnivData();
        geBlogData();
        getCourseData();
        gethomedata();
        getvideosdata();
    }, []);

    return (
        <>
            <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription} />
                <link rel="canonical" href={`${pConfig.siteUrl}`} />
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "url": `${pConfig.siteUrl}`,
                            "potentialAction": {
                                "@type": "SearchAction",
                                "target": `${pConfig.siteUrl}search?q={search_term_string}`,
                                "query-input": "required name=search_term_string"
                            }
                        }
                    )}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "LocalBusiness",
                            "name": "Bangalore Study",
                            "image": `${pConfig.siteUrl}home_page/images/logo.png`,
                            "@id": `${pConfig.siteUrl}contact`,
                            "url": `${pConfig.siteUrl}`,
                            "telephone": "+91-9036020016",
                            "priceRange": "INR",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "#3/75, 'D' Main, East End,Jayanagar 9th Block,Bangalore - 560069",
                                "addressLocality": "Bengaluru",
                                "postalCode": "560069",
                                "addressCountry": "IN"
                            },
                            "geo": {
                                "@type": "GeoCoordinates",
                                "latitude": 12.918769,
                                "longitude": 77.598285
                            },
                            "openingHoursSpecification": {
                                "@type": "OpeningHoursSpecification",
                                "dayOfWeek": [
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday",
                                    "Saturday"
                                ],
                                "opens": "10:00",
                                "closes": "19:00"
                            }
                        }
                    )}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "Website",
                            "name": "Bangalore Study",
                            "url": `${pConfig.siteUrl}`,
                            "headline": "Bangalore Study | #1 College Admissions in Bangalore",
                            "description": "Bangalorestudy.com by Learntech Edu Solution Pvt. Ltd is an online education platform that operates for counselling students on college admissions",
                            "image": {
                                "@type": "ImageObject",
                                "url": `${pConfig.siteUrl}home_page/images/logo.png`,
                                "sameAs": [
                                    "https://www.facebook.com/bangalorestudy/",
                                    "https://twitter.com/BangaloreStudy2",
                                    "https://www.youtube.com/channel/UCZP40_ivVcdelNOVhmQFr7w",
                                    "https://www.instagram.com/bangalorestudy/"
                                ]
                            }
                        }
                    )}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@graph":
                                [
                                    {
                                        "@context": "https://schema.org",
                                        "@type": "SiteNavigationElement",
                                        "@id": `${pConfig.siteUrl}colleges#menu-item-1`,
                                        "name": "Colleges in Bangalore",
                                        "url": `${pConfig.siteUrl}colleges`,
                                    },
                                    {
                                        "@context": "https://schema.org",
                                        "@type": "SiteNavigationElement",
                                        "@id": `${pConfig.siteUrl}universities/#menu-item-2`,
                                        "name": "Universities in Bangalore",
                                        "url": `${pConfig.siteUrl}universities`
                                    },
                                    {
                                        "@context": "https://schema.org",
                                        "@type": "SiteNavigationElement",
                                        "@id": `${pConfig.siteUrl}services#menu-item-3`,
                                        "name": "Services",
                                        "url": `${pConfig.siteUrl}services`
                                    },
                                    {
                                        "@context": "https://schema.org",
                                        "@type": "SiteNavigationElement",
                                        "@id": `${pConfig.siteUrl}services/#menu-item-11`,
                                        "name": "Blog",
                                        "url": `${pConfig.siteUrl}blog`
                                    },
                                    {
                                        "@context": "https://schema.org",
                                        "@type": "SiteNavigationElement",
                                        "@id": `${pConfig.siteUrl}contact/#menu-item-12`,
                                        "name": "Contact Us",
                                        "url": `${pConfig.siteUrl}contact`
                                    }
                                ]
                        }
                    )}
                </script>
            </Helmet>
            {isHeroLoaded && isTopColLoaded && isBlogLoaded ?
                <>
                    {isHeroLoaded && <>
                        <HeroArea bannerArr={bannerArr} />
                        <CounterSection countBlockInfo={countBlockInfo} homevideosdata={homevideosdata} />
                 
                            <TrendingCourses courseStreamArr={newcourseStreamArr} />
                   

                    </>}

                    {isTopColLoaded && <>
                 
                            <TopColleges topCollegeArr={topCollegeArr} />
                            <TopUniversities topUniversityArr={topUniversityArr} />
                    </>}
                        <MidFormBanner />

                    {isTopColLoaded &&

                            <ExamsSection topExamArr={topExamArr} />

                    }


                    {isBlogLoaded &&
                            <LatestBlog blogArr={blogArr} newsArr={newsArr} />
                             }

                    {/* <LazyLoad height={200} offset={100}> */}
                        <WebCounters />
                        <ServicesSection />
                    {/* </LazyLoad> */}

                    {isTopColLoaded && <LazyLoad height={200} offset={100}> <Testimonials testimonialsArr={testimonialsArr} />
                    </LazyLoad>}

                    <LazyLoad height={200} offset={100}>
                        <ProtalsSection />
                    </LazyLoad>
                    {/* <ProtalsSection /> */}
                </>
                :
                <p style={{ height: '8000px' }}>Loading</p>}
        </>
    );
};

export default HomePage;