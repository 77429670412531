import React, { FC } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses.jpg';
// import CallNow from 'src/assets/img/call-now.svg';
// import SearchIcon from 'src/assets/img/icons/search.svg';
// import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
import { config, pConfig } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import Helmet from 'react-helmet';

const SearchHeader: FC<{collegedetail : any, slug:any}> = ({collegedetail, slug}) => {
    //const [num, setNum] = React.useState(331231);
    const logoUrl = collegedetail.logo ? config.baseUrlfromconfig + collegedetail.logo : ManagementCourseBG;
    //const city_name = collegedetail.city ? collegedetail.city.city_name : '';
    return (
        <>
        <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(
              {
                "@context": "https://schema.org/",
                "@type": "CollegeOrUniversity",
                "name": `${collegedetail.meta_title}`,
                "logo": `${logoUrl}`,
                "url" : `${pConfig.siteUrl}board/${slug}`,
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": `${collegedetail.address}`
                }
              }
            )}
            </script>
        </Helmet>
        <section className="inner-top-header" id="hero" style={{ background: `url(${ManagementCourseBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row college-block">
                        <div className="top-college-header">
                            <h1><img alt={collegedetail.name} src={logoUrl} style={{width:`130px`}} /> {collegedetail.name} </h1>
                            {collegedetail.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {collegedetail.avg_rating}</div> : '' }
                            <ul className='inlineflex'>
                                <li><i className="bi bi-pin-map-fill"></i>&nbsp; {collegedetail.address}</li>
                                <li>Approvals and Recognition:&nbsp;<strong>
                                {collegedetail.collegeRecognition && collegedetail.collegeRecognition.map((i, index) => {
                                const length = collegedetail.collegeRecognition.length;
                                    var custome = '';                    
                                    if(length == index + 1){
                                    custome = '';
                                    }else{
                                    custome = ',';
                                    }
                                    return (
                                        <>
                                        {i.recognitionname.recognition_approval_name}
                                        {custome}
                                        </>
                                        
                                        )
                                    })}
                                </strong></li>
                                <li>Est year:&nbsp;<strong>{collegedetail.established}</strong></li>
                                <li>Affiliations:&nbsp;<strong>
                                {collegedetail.college_affiliation && collegedetail.college_affiliation.map((i, index) => {
                                    const length = collegedetail.college_affiliation.length;
                                    var custome = '';                    
                                    if(length == index + 1){
                                    custome = '';
                                    }else{
                                    custome = ',';
                                    }
                                    return (
                                        <>
                                        {i.affiliationname.other_affiliations_name}
                                        {custome}
                                        </>
                                        
                                        )
                                    })}    
                                </strong></li>
                                <li>Genders Accepted:&nbsp;<strong>{collegedetail.genders_accepted}</strong></li>
                            </ul>
                            <div className="spac_02"></div>
                            <div className="col-lg-12 button-section">
                            <GlobalPopupEnquiry className="medical-button btn floatright" />
                               {/*  <a href="#" className="medical-button btn floatright">Apply Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
};

export default SearchHeader;