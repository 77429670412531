import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import GetMoreDetails from 'src/assets/img/get-more-details.jpg';
import EducationCap from 'src/assets/img/education-cap.png';
import HeadingBg from 'src/assets/img/heading-bg.png';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import InterestedinCourseBG from 'src/assets/img/interested-in-course-bg1.png';
import CollegeIMG from 'src/assets/img/college.jpg';
import StreamIMG from 'src/assets/img/stream.jpg';
import CityIMG from 'src/assets/img/city.jpg';
import AccreditaionIMG from 'src/assets/img/accreditaion.jpg';
import { config } from 'src/config';
import SidebarFilter from 'src/components/SidebarFilter';
import NotDataFoundView from 'src/views/errors/NotDataFoundView';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';


import {

    Button,
    SvgIcon,
    IconButton,
    Hidden
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';

import {
    Image as ImageIcon,
    Edit as EditIcon,
    ArrowRight as ArrowRightIcon,
    Search as SearchIcon,
    Filter as FilterIcon

} from 'react-feather';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    }, drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
} type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Breadcrumbs: FC<{
    schooldetail: any, levels, isFetching, HasMore, getschoollist, boards, schoollevels, city, schoolclassification, searchByFilters, getSchoollist, page, schooltypes
}> = ({ schooldetail, levels, isFetching, HasMore, getschoollist, boards, schoollevels, city, schoolclassification, searchByFilters, getSchoollist, page, schooltypes }) => {
    const [num, setNum] = React.useState(331231);

    const [boardsselected, setBoardsselected] = useState<any>([]);
    const [schoollevelsselected, setSchoollevelsselected] = useState<any>([]);
    const [cityselected, setCityselected] = useState<any>([]);
    const [schoolclassificationselected, setSchoolClassificationselected] = useState<any>([]);
    const [schooltypesselected, setSchoolTypesselected] = useState<any>([]);
    const classes = useStylesBootstrap();
    const [ApplyFilters, setApplyFilters] = useState<any>([]);
    const [levelsselected, setLevelsselected] = useState<any>([]);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };
    const handleSidebarFilter = async (type, data) => {

        if (type == 'Board')
            setBoardsselected(data);

        if (type == 'Levels')
            setLevelsselected(data);

        if (type == 'School Level')
            setSchoollevelsselected(data);

        if (type == 'City')
            setCityselected(data);


        if (type == 'Genders Accepted')
            setSchoolClassificationselected(data);

        if (type == 'School Types')
            setSchoolTypesselected(data);
    };

    const updateappliedFilters = async () => {
        let appliedFilters = {
            'board_id': boardsselected ? boardsselected : [],
            'school_level_id': schoollevelsselected ? schoollevelsselected : [],
            'city_id': cityselected ? cityselected : [],
            'classification': schoolclassificationselected ? schoolclassificationselected : [],
            'level_id': levelsselected ? levelsselected : [],
            'school_type_id': schooltypesselected ? schooltypesselected : [],
        }
        setApplyFilters(appliedFilters);
        searchByFilters(appliedFilters);
    }

    useEffect(() => {
        updateappliedFilters();
    }, [boardsselected, schoollevelsselected, cityselected, schoolclassificationselected, levelsselected, schooltypesselected]);


    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="">
                    <div className="overviews-course">
                        <div className="row">
                            <h3 className="main-heading text-left">Top <b>Schools</b> in Karnataka</h3>
                            <div className="spac_02"></div>

                            <Hidden lgUp mdUp smUp>

                                <Button onClick={toggleDrawer('top', true)}>
                                    <SvgIcon
                                        fontSize="small"
                                        color="action"
                                    >
                                        <SearchIcon />
                                    </SvgIcon>  {'Filter'}
                                </Button>
                                <Drawer className="drawerCl" anchor={'top'} open={state['top']} onClose={toggleDrawer('top', false)}>
                                    <AppBar position="fixed">
                                        <div className={classes.drawerHeader}>
                                            <IconButton className="btn-close" onClick={toggleDrawer('top', false)}>

                                            </IconButton>
                                        </div>
                                    </AppBar>

                                    <div>

                                        <div className="left-widgets widgets" style={{ marginTop: '52px' }}>
                                            {schooltypes ?
                                                <SidebarFilter
                                                    data={schooltypes}
                                                    icon={StreamIMG}
                                                    type={'School Types'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No School Types'
                                            }
                                        </div>
                                        <div className="left-widgets widgets">
                                            {boards ?
                                                <SidebarFilter
                                                    data={boards}
                                                    icon={StreamIMG}
                                                    type={'Board'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No Boards'
                                            }


                                        </div>


                                        <div className="left-widgets widgets">
                                            {
                                                levels ?
                                                    <SidebarFilter
                                                        data={levels}
                                                        icon={AccreditaionIMG}
                                                        type={'Levels'}
                                                        handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                    />
                                                    : 'No Levels'
                                            }

                                        </div>

                                        <div className="left-widgets widgets">
                                            {city ?
                                                <SidebarFilter
                                                    data={city}
                                                    icon={StreamIMG}
                                                    type={'City'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No City'
                                            }

                                        </div>

                                        <div className="left-widgets widgets">
                                            {schoolclassification ?
                                                <SidebarFilter
                                                    data={schoolclassification}
                                                    icon={StreamIMG}
                                                    type={'Genders Accepted'}
                                                    handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                                />
                                                : 'No Genders Accepted'
                                            }

                                        </div>
                                    </div>

                                </Drawer>
                            </Hidden>







                            <div className="col-lg-3 col-md-3 sidebar-widgets d-none d-sm-block d-lg-block d-md-block" >

                                <div className="left-widgets widgets">
                                    {schooltypes ?
                                        <SidebarFilter
                                            data={schooltypes}
                                            icon={StreamIMG}
                                            type={'School Types'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No School Types'
                                    }
                                </div>
                                <div className="left-widgets widgets">
                                    {boards ?
                                        <SidebarFilter
                                            data={boards}
                                            icon={StreamIMG}
                                            type={'Board'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Boards'
                                    }


                                </div>

                                <div className="left-widgets widgets">
                                    {
                                        levels ?
                                            <SidebarFilter
                                                data={levels}
                                                icon={AccreditaionIMG}
                                                type={'Levels'}
                                                handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                            />
                                            : 'No Levels'
                                    }

                                </div>

                                <div className="left-widgets widgets">
                                    {city ?
                                        <SidebarFilter
                                            data={city}
                                            icon={StreamIMG}
                                            type={'City'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No City'
                                    }

                                </div>

                                <div className="left-widgets widgets">
                                    {schoolclassification ?
                                        <SidebarFilter
                                            data={schoolclassification}
                                            icon={StreamIMG}
                                            type={'Genders Accepted'}
                                            handleSidebarFilter={(type, data) => handleSidebarFilter(type, data)}
                                        />
                                        : 'No Genders Accepted'
                                    }

                                </div>
                            </div>

                            <div className="col-lg-9 col-md-9 top-colleges" >
                                {
                                    schooldetail && schooldetail.map((i) => {
                                        let collegelink = '/school/' + i.school_slug;
                                        return (
                                            <div className="col-xl-4 col-lg-4 col-md-6">
                                                <div className="topcollege-box schoolbox">
                                                    <img alt={i.school_name} src={i.school_logo ? config.baseUrlfromconfig + i.school_logo : CollegeIMG} className="full_wdth" />
                                                    <div className="college-content">
                                                        <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{i.school_name}</span>}>
                                                            <h3>{i.school_name}</h3>
                                                        </BootstrapTooltip>
                                                        {i.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {i.avg_rating}</div> : ''}
                                                        <p>{i.citys.city_name}
                                                        </p>
                                                        <div className="clearfix"></div>
                                                        <hr />
                                                        <div><b>School Details</b></div>
                                                        <ul>
                                                            <li>Est. Year: <b>{i.established}</b></li>
                                                            <li>Ownership: <b>{i.schooltype ? i.schooltype.type : ''}</b></li>
                                                        </ul>
                                                        <div className="college_button_wrp ">
                                                            <GlobalPopupEnquiry />&nbsp;<a href={collegelink}>View Details</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearfix"></div>
                                            </div>
                                        )
                                    })
                                }
                                {schooldetail.length < 1 ? (
                                    <NotDataFoundView />
                                ) : ''}
                                {/* <div className="spac_02"></div>
                                <div className="button-section text-center">
                                    {isFetching && <p>Loading more data...</p>}
                                    {!isFetching && HasMore && (
                                        <button className="button btn" 
                                        
                                        onClick={() => getSchoollist(page+1,schooldetail,ApplyFilters)}
                                        >Load more</button>
                                    )}
                                </div>
                                <div className="spac_02"></div> */}
                                {schooldetail.length > 12 ? (
                                    <>
                                        <div className="clearfix"></div>
                                        <div className="spac_02"></div>
                                        <div className="button-section text-center">
                                            {isFetching && <p>Loading more data...</p>}
                                            {!isFetching && HasMore && (
                                                <button className="button btn" onClick={getschoollist}>Load more</button>
                                            )}
                                        </div>
                                        <div className="spac_02"></div>
                                    </>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;