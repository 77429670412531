import React, { FC, useEffect, useState, useContext,useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { makeStyles } from '@material-ui/core';

import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import OverviewSection from './OverviewSection';
import TextSection from './TextSection';

import { pConfig } from 'src/config';  
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useParams } from "react-router-dom";
import Helmet from 'react-helmet';
import axios1 from 'axios';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ScholarshipPage: FC = () => {
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const isMountedRef = useIsMountedRef();
  const [courseStreamArr, setCourseStreamArr] = useState([]);
  const { slug } = useParams();
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [topCollegeArr, setTopCollegeArr] = useState([]);
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);
  let cancelToken;

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    setCourseStreamArr(heroResp.data.data.course_streams_head);
    
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }
  const [schlorshipdata, setschlorshipdata] = useState([]);
  const [schlorshiplist, setgetschlorshiplist] = useState([]);

  const getschlorship = useCallback(async () => {    
    try {
      const response = await axios.get('api/website/page/get/scholarship');
      if (isMountedRef.current) {
          //console.log("response.data.data");
          //console.log(response.data.data);
          setschlorshipdata(response.data.data);
          setmetatitle(response.data.data.meta_title);
          setmetadescription(response.data.data.meta_description);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const [limit, setLimit] = useState<number>(4);
  const [total, setTotal] = useState<number>(0);
  const [totalpage, setTotalpage] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [isgetload, setIsgetload] = useState<boolean>(true);
  const [searchValue, setSearchValue] = React.useState<any>([]);

  const searchByValue = (textvalue) => {
    setPage(1);
    setSearchValue(textvalue);
  };

  const getschlorshiplist = useCallback(async (page, limit, searchValue) => {  
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.")
    }
    cancelToken = axios1.CancelToken.source()  
    try {
      setIsgetload(true);
      const params = {}
      let usePagination = true;
     // alert(page);
      let pages = page;
      let count = limit;
      params['usePagination'] = usePagination;
    //  params['data_type'] = 'board';
      params['page'] = pages;
      params['size'] = count;
      if(searchValue && searchValue!='')
      {
        params['searchText'] = searchValue;
        params['searchfrom'] = 'name';
      }
      
      const response = await axios.get('api/website/home/scholarship',{params: params});
      if (isMountedRef.current) {
        //  alert("asfd");
          //console.log("response.data.data");
          //console.log(response.data.data);
          setgetschlorshiplist(response.data.data);
          let pagetobeshow = response.data.currentPage;
          setIsgetload(false);
          setPage(pagetobeshow);
          setLimit(count);
          setTotal(response.data.totalItems);
          let pagecount = Math.ceil(response.data.totalItems / count);
          setTotalpage(pagecount);
        }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const [upcomingdataexam, setupcomingdataexam] = useState([]);
  const [promoBannerArr, setPromoBannerArr] = useState<any>([]);

  const getupcomingdataexam = useCallback(async () => {
    try {
     
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/upcomingexam');
      if (isMountedRef.current) {
      //  alert("asfd");
        console.log("response.data.data");
        console.log(response.data.data);
        setupcomingdataexam(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const handlePageChange = (event: any, value: number): void => {
    getschlorshiplist(value, limit, searchValue);
  };

  const getCollageUnivData = async ()=>{
    const params = {}
    params['page'] =page;
    params['size'] = '8';
    const univColgResp = await axios.get('api/website/home/topcollegebangalore', {params: params});
    //console.log(univColgResp);
    //console.log('her');
    setTopCollegeArr(univColgResp.data.data);    
  }

  const getpromobanner = useCallback(async () => {
    try {
      const response = await axios.get('api/website/promobanner?promo_banner=All_Scholarship_page');
      if (isMountedRef.current) 
      {
        setPromoBannerArr(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(()=>{
    getHeroData();
    getschlorship();
    getschlorshiplist(page, limit, searchValue);
    //getupcomingdataexam();
    getCollageUnivData();
    getpromobanner();
  }, [searchValue]);

  return (
    <>
    {/* {isTopColLoaded ? 
      <> */}
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}scholarship`} />
        </Helmet>
        <Header schlorshiplist={schlorshiplist}/>
        <Breadcrumbs />
        <TextSection schlorshipdata={schlorshipdata}/>
        <OverviewSection upcomingdataexam={upcomingdataexam} topCollegeArr={topCollegeArr} schlorshiplist={schlorshiplist} page={page} totalpage={totalpage} handlePageChange={handlePageChange} searchByValue={(textvalue) => searchByValue(textvalue)} promoBannerArr={promoBannerArr}/>
      {/* </>
      :
      <p style={{height: '8000px'}}>Loading</p> } */}
    </>
  );
};

export default ScholarshipPage;