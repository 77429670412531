import React, { FC } from 'react';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import './styles.css';

const CoursesList: FC<{ courseStreamArr: any, totalPages, isFetching, getCollageUnivData, page }> = ({ courseStreamArr, totalPages, isFetching, getCollageUnivData, page }) => {
    //console.log("courseStreamArr");
    //console.log(courseStreamArr);
    return (
        <section className="our-courses-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="main-heading">Courses <b>in India</b></h3>
                        {/* <h3 className="main-heading">Our <b>Courses</b></h3> */}
                        <p className="text-center">Choosing a professional course to pursue can be difficult.<br />Explore details about all the courses available in India and make the right choice.
                        </p>
                        <div className="spac_02"></div>
                        <div className="row">

                            {courseStreamArr.length > 0 ? (
                                <>
                                    {courseStreamArr.map((course) => {
                                        //console.log(document);
                                        //const documenturl = course.imagepath;
                                        //let linkedit="/app/management/users/edit/" + user.id;
                                        let linkslug = '/courses/' + course.stream_slug;
                                        return (
                                            <div className="col-xl-3 col-lg-4 col-md-6 text-center" >
                                                <div className="outer-hover-courses-box">
                                                    <div className="courses-box">
                                                        <div className="course_icons"><img src={course.icon ? process.env.REACT_APP_IMG_URI + course.icon : process.env.REACT_APP_IMG_URI + 'stream_logo/engineering.svg'} /></div>
                                                        <h2>{course.stream_name}</h2>
                                                        {/*<ul>
                                                            {course.str.slice(0, 2).map(i => {
                                                                let courseinnerlink = '/courses/' + course.stream_slug + "/" + i.course_stream_slug;
                                                                return (
                                                                    <li><a title={i.course_stream_name} href={courseinnerlink}>{i.course_short_name ? i.course_short_name : i.course_stream_name.slice(0, 20)}</a></li>
                                                                )
                                                            })}
                                                            {/* <li><a href="">BBA</a></li>
                                                            <li><a href="">MBA</a></li> 
                                                        </ul> */}
                                                        <ul>
                                                            <li><p>&nbsp;</p></li>
                                                        </ul>
                                                        <div className="college_button_wrp courseenquirybtn">
                                                            <GlobalPopupEnquiry />
                                                            <a  href={linkslug}>View Courses</a>
                                                        </div>
                                                    </div>
                                                    <div className="courses-box-hover" style={{display: 'none'}}>
                                                        <h2>{course.stream_name}</h2>
                                                        <ul>
                                                            {course.str.slice(2, 5).map(i => {
                                                                //let courseinnerlink="course/"
                                                                //let courseinnerlink = '/courses/' + course.stream_slug + "/" + i.course_stream_slug;

                                                                let courseinnerlink = ''; 
                                                                let class_name = 'cursor_block'; 
                                                                if(i.description && i.course_short_name && i.description != '' && i.course_short_name !='')
                                                                {
                                                                    class_name = '';
                                                                    courseinnerlink = '/courses/' + course.stream_slug + "/" + i.course_stream_slug;
                                                                }
                                                                return (
                                                                    <li><a className={class_name} title={i.course_stream_name} href={courseinnerlink}>{i.course_short_name ? i.course_short_name : i.course_stream_name.slice(0, 20)}</a></li>
                                                                )
                                                            })}
                                                            {/* <li><a href="">BBA</a></li>
                                                                <li><a href="">BBA</a></li>
                                                                <li><a href="">MBA</a></li> */}
                                                        </ul>
                                                        <a className="button btn" href={linkslug}>View Courses</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className="spac_02"></div>
                                        <div className="button-section text-center clearfix">                           
                                            {isFetching && <p>Loading more data...</p>}
                                            { 
                                                
                                                totalPages && totalPages !== page && !isFetching && (
                                                <button className="button btn" onClick={() => getCollageUnivData(page+1,courseStreamArr)}>Load more</button>
                                            )}
                                        </div>
                                </>
                            ) : (
                                <></>
                            )}

                            {/* <div className="col-lg-3 col-md-6 text-center" >

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Management</h2>
                                        <ul>
                                            <li><a href="">BBA</a></li>
                                            <li><a href="">MBA</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Management</h2>
                                        <ul>
                                            <li><a href="">BBA</a></li>
                                            <li><a href="">BBA</a></li>
                                            <li><a href="">MBA</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">


                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Architecture</h2>
                                        <ul>
                                            <li><a href="">B.Arch</a></li>
                                            <li><a href="">M.Arch</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Architecture</h2>
                                        <ul>
                                            <li><a href="">B.Arch</a></li>
                                            <li><a href="">M.Arch</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Engineering</h2>
                                        <ul>
                                            <li><a href="">B.Arch</a></li>
                                            <li><a href="">M.Arch</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Engineering</h2>
                                        <ul>
                                            <li><a href="">B.E</a></li>
                                            <li><a href="">M.E</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Medical</h2>
                                        <ul>
                                            <li><a href="">MBBS</a></li>
                                            <li><a href="">M.D</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Medical</h2>
                                        <ul>
                                            <li><a href="">MBBS</a></li>
                                            <li><a href="">M.D</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>


                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Nursing</h2>
                                        <ul>
                                            <li><a href="">DGNM</a></li>
                                            <li><a href="">B.Sc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Nursing</h2>
                                        <ul>
                                            <li><a href="">DGNM</a></li>
                                            <li><a href="">B.Sc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>



                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Law</h2>
                                        <ul>
                                            <li><a href="">LLB</a></li>
                                            <li><a href="">LLM</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Law</h2>
                                        <ul>
                                            <li><a href="">LLB</a></li>
                                            <li><a href="">LLM</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Vocational</h2>
                                        <ul>
                                            <li><a href="">B.Voc</a></li>
                                            <li><a href="">M.Moc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Vocational</h2>
                                        <ul>
                                            <li><a href="">B.Voc</a></li>
                                            <li><a href="">M.Moc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>



                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Pharmacy</h2>
                                        <ul>
                                            <li><a href="">B.Pharm</a></li>
                                            <li><a href="">M.Pharm</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Pharmacy</h2>
                                        <ul>
                                            <li><a href="">B.Pharm</a></li>
                                            <li><a href="">M.Pharm</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Agriculture</h2>
                                        <ul>
                                            <li><a href="">B.Sc</a></li>
                                            <li><a href="">M.Sc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Agriculture</h2>
                                        <ul>
                                            <li><a href="">B.Sc</a></li>
                                            <li><a href="">M.Sc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Aviation</h2>
                                        <ul>
                                            <li><a href="">B.E</a></li>
                                            <li><a href="">M.E</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Aviation</h2>
                                        <ul>
                                            <li><a href="">B.E</a></li>
                                            <li><a href="">M.E</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>


                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Commerce</h2>
                                        <ul>
                                            <li><a href="">B.Com</a></li>
                                            <li><a href="">M.Com</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Commerce</h2>
                                        <ul>
                                            <li><a href="">B.Com</a></li>
                                            <li><a href="">M.Com</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>


                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Arts</h2>
                                        <ul>
                                            <li><a href="">B.Ed</a></li>
                                            <li><a href="">M.Ed</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Arts</h2>
                                        <ul>
                                            <li><a href="">B.Ed</a></li>
                                            <li><a href="">M.Ed</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>


                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Veterinary</h2>
                                        <ul>
                                            <li><a href="">B.V.Sc</a></li>
                                            <li><a href="">M.V.Sc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Veterinary</h2>
                                        <ul>
                                            <li><a href="">B.V.Sc</a></li>
                                            <li><a href="">M.V.Sc</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Ayurveda</h2>
                                        <ul>
                                            <li><a href="">BAMS</a></li>
                                            <li><a href="">MD</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Ayurveda</h2>
                                        <ul>
                                            <li><a href="">BAMS</a></li>
                                            <li><a href="">MD</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div>


                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">

                                <div className="outer-hover-courses-box">
                                    <div className="courses-box">
                                        <div className="course_icons"><img src="assets/img/managment.png" /></div>
                                        <h2>Management</h2>
                                        <ul>
                                            <li><a href="">BBA</a></li>
                                            <li><a href="">MBA</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                    <div className="courses-box-hover">
                                        <h2>Management</h2>
                                        <ul>
                                            <li><a href="">BBA</a></li>
                                            <li><a href="">MBA</a></li>
                                        </ul>
                                        <a className="button btn" href="management-course.html">View Courses</a>
                                    </div>
                                </div>


                            </div> */}






                        </div>


                    </div>
                </div>

            </div>
        </section>
    );
};

export default CoursesList;