import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import SettingsContext from 'src/contexts/SettingsContext';
import axios from 'src/utils/axiosintercpt';
import { CircularProgress, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Breadcrumbs from './Breadcrumbs';
import Header from './InnerHeader';
import { useParams,useHistory } from "react-router-dom";
import OverviewSection from './OverviewSection';
import TopColleges from './TopColleges';
import TopUniversities from 'src/views/HomeNew/TopUniversities/index';
import Features from './Features';
import Interested from './Interested';
import Location from './Location';

import { pConfig } from 'src/config';  
import Helmet from 'react-helmet';
import GlobalReviewSection from 'src/components/GlobalReviewSection';


const useStyles = makeStyles(() => ({
  root: {}
}));

const UniversityView: FC = () => {
  const history = useHistory();
  const { slug } = useParams();
  const classes = useStyles();
  const setCtx = useContext(SettingsContext);
  const [courseStreamArr, setCourseStreamArr] = useState([]);

  const isMountedRef = useIsMountedRef();

  const [topUniversityArr, setTopUniversityArr] = useState([]);
  const [coursedetail, setcoursedetail] = useState<any>([]);
  const [isHeroLoaded, setHeroLoaded] = useState(false);
  const [isUniColLoaded, setUniColLoaded] = useState(false);
  const [isBlogLoaded, setBlogLoaded] = useState(false);
  
  const [metatitle, setmetatitle] = useState<any>([]);
  const [metadescription, setmetadescription] = useState<any>([]);

  const getHeroData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "course_streams_head"}]));
    const heroResp = await axios.post('api/website/home/header', formData);

    //setBannerArr(heroResp.data.data.banners);
    //setCountBlockInfo(heroResp.data.data.count_block);
    setCourseStreamArr(heroResp.data.data.course_streams_head);
    setCtx.setCourseData(heroResp.data.data.course_streams_head);
    setHeroLoaded(true);
  }

  const getCollageUnivData = async ()=>{
    const formData = new FormData();
    formData.append('getdata', JSON.stringify([{data: "university"}]));
    const univColgResp = await axios.post('api/website/home/topcrousel', formData);
    setTopUniversityArr(univColgResp.data.data.topuniversity.data);
  }

  const getdatabystreamid = useCallback(async () => {
    try {
      const id = slug;//'engineering';
      const response = await axios.get('api/website/home/college/get/'+id);
      if (isMountedRef.current) {
        //console.log("response.data.data");
        //console.log(response.data.data);

      
        if(response.data.data && response.data.data.type && response.data.data.type =='college')
        {
          history.push("/college/"+response.data.data.slug);
        }
        if(response.data.data && response.data.data.type && response.data.data.type =='board')
        {
          history.push("/board/"+response.data.data.slug);
        }
        setcoursedetail(response.data.data);
        setmetatitle(response.data.data.meta_title)
        setmetadescription(response.data.data.meta_description)
        setUniColLoaded(true);
      }
    } catch (err) {
      console.error(err);
      history.push("/404");
    }
  }, [isMountedRef]);

  const formattedData = coursedetail && coursedetail.faqs && coursedetail.faqs.map((item) => ({
    "@type": "Question",
    "name": item.questions,
    "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answers,
    },
  }));

  useEffect(()=>{
    getHeroData();
    getCollageUnivData();
    getdatabystreamid();
  }, []);

  if(!coursedetail)
    return null;

  return (
    <>
     {isUniColLoaded ? 
      <> 
        <Helmet>
          <title>{metatitle && metatitle != '' ? metatitle : 'Get Admission at Top Colleges in Bangalore'}</title>
          <meta name="description" content={metadescription && metadescription != '' ?  metadescription : 'Looking for College/ University/ School admission? Bangalorestudy.com helps you with a list of Colleges, Universities and Schools to pursue your education in Bangalore. Call us now'} />
          <link rel="canonical" href={`${pConfig.siteUrl}university/${slug}`} />
          <script type="application/ld+json">
            {JSON.stringify(
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": formattedData,
                }
            )}
          </script>
        </Helmet>
        <Header coursedetail={coursedetail} slug={slug} />
        <Breadcrumbs coursedetail={coursedetail}/>
        <OverviewSection coursedetail={coursedetail}/>
        <Features coursedetail={coursedetail}/>
        <Interested coursedetail={coursedetail}/>
        <Location coursedetail={coursedetail}/>
        <GlobalReviewSection type="university" item_id={coursedetail && coursedetail.id?coursedetail.id:0}  />
        {/* item_id={coursedetail.id?coursedetail.id:0} */}
        <TopUniversities topUniversityArr={topUniversityArr}/>
       </>
      :
        <div style={{ display: 'flex', justifyContent: 'center', height:'600px',flexWrap: 'wrap', alignContent: 'space-around' }}><CircularProgress /></div> }
    </>
  );
};

export default UniversityView;