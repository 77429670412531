import React, { FC } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses.jpg';

const SearchHeader: FC = () => {

    return (
        <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${ManagementCourseBG})` }} >
            <div className="container" >
                <div className="row">
                    <div className="study-aborad-block top-course-header text-center">
                        {/* <h1>WELCOME TO <span>BANGALORESTUDY.COM </span></h1> */}
                        <h1 className="lowercase">Welcome to <span>Bangalorestudy.com </span></h1>
                        <h4>Create an account or log into Bangalorestudy.com. Stay up-to-date with Top Colleges, Universities and Exams updates.</h4>
                    </div>
                </div>
                <div className="spac_02"></div>
            </div>
        </section>
    );
};

export default SearchHeader;