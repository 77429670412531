import React, { FC, useRef, useState } from 'react';
import axios from 'src/utils/axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import './styles.css';
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers, useField } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import PhoneNumberField from 'src/components/PhoneInput'

const styles = {
  search: {
    position: "relative",
  }
}



const EnquiryForm: FC<{}> = () => {
  const captchaRef = useRef(null)
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();


  return (

    <Formik
      initialValues={{

        fullname: '',
        phoneNumber: '',
        Email: '',
        CourseInMind: '',
        College: '',
        Location: '',
        message: ''


      }}
      validationSchema={
        Yup.object().shape({
          fullname: Yup.mixed().required('Name  is Required'),
          phoneNumber: Yup.string().matches(/^\+91-[6789]\d{9}$|^\+(?!91-)\d{1,3}-\d{1,10}$/, 'Phone number is not valid').required("Phone Number is required"),
          Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        })
      }

      onSubmit={async (values, actions) => {


        const formData = new FormData();
        formData.append('name', values.fullname);
        formData.append('contact', values.phoneNumber);
        formData.append('email', values.Email);
        formData.append('course_in_mind', values.CourseInMind);
        formData.append('college_name', values.College);
        formData.append('location', values.Location);
        formData.append('description', values.message);
        formData.append('current_url', window.location.href);
        let url = 'api/website/home/enquiry';
        // return alert(JSON.stringify(formData));

        try {

          let response = await axios.post(url, formData);
          // console.log('response: ', response);

          if (response.data.status) {


            actions.setSubmitting(true);
            actions.resetForm({
              values: {

                fullname: '',
                phoneNumber: '',
                Email: '',
                CourseInMind: '',
                College: '',
                Location: '',
                message: ''

              },
            });
 
            enqueueSnackbar('Thank you. We will get back to you.', {
              variant: 'success',
            });
            history.push("/thank-you");


          }
        } catch (err) {
          console.error(err);

          enqueueSnackbar("Please try again", {
            variant: 'error',

          });
        }


        captchaRef.current.reset();












      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit}>
          <p>&nbsp;</p>
          <div className="form-group">
            {/* <label htmlFor="fullName">Full Name</label>*/}
            <Field type="text" name="fullname"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.fullname}
              className="form-control" id="name" placeholder="Enter Name" required />
            {props.errors.fullname && props.touched.fullname && <p className="error">{props.errors.fullname}</p>}
          </div>
          <div className="form-group">
            {/*   <label>Email</label>*/}
            <Field type="email" className="form-control" name="Email"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.Email}
              id="Email" placeholder="Enter Email"
              required />
            {props.errors.Email && props.touched.Email && <p className="error">{props.errors.Email}</p>}
          </div>
          {/* <div className="row"> */}

          <div className="form-group">
            {/*   <label>Phone Number</label>*/}

            <PhoneNumberField />
            {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>}
            {/* <Field type="tel" className="form-control" name="phoneNumber"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.phoneNumber}
                id="PhoneNumber" placeholder="Phone Number"
                required />
              {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>} */}
          </div>

          {/* </div> */}

          <div className="form-group">
            {/*  <label>Course In Mind</label>*/}
            <Field type="text" className="form-control" name="CourseInMind"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.CourseInMind}
              id="CourseInMind" placeholder="Course In mind" required
            />
          </div>

          {/* <div className="form-group">
            <label>College</label>
            <Field type="text" className="form-control" name="College"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.College}
              id="College" placeholder="Enter College" required
            />
          </div> */}

          <div className="form-group">
            {/* <label>Location</label>*/}
            <Field type="text" className="form-control" name="Location"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.Location}
              id="Location" placeholder="Enter Location" required

            />
          </div>

          {/* <div className="form-group mrb" >
            <textarea className="form-control" name="message" id="inputID"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.message} rows={3} placeholder="Type Your Message"
            ></textarea >
          </div> */}
          {/* <ReCAPTCHA
            sitekey="6LcbLSEjAAAAAEnZuOWLNsIJgCP3tptUhe2SpBIb"
            ref={captchaRef}
          /> */}

          <div className="text-center"><button type="submit" className="submit_message home_submit_message">Talk to Experts</button></div>



        </form>
      )}
    </Formik>




  );
};

export default EnquiryForm;