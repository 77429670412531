import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import CollegeIMG from 'src/assets/img/college.jpg';
import SidebarFilter from 'src/components/SidebarFilter';
import StreamIMG from 'src/assets/img/stream.jpg';
import CityIMG from 'src/assets/img/city.jpg';
import AccreditaionIMG from 'src/assets/img/accreditaion.jpg';
import ManagementiconIMG from 'src/assets/img/management-icon.jpg';
import NotDataFoundView from 'src/views/errors/NotDataFoundView';
import College from 'src/assets/img/college.jpg';
import EntranceExam from 'src/assets/img/entrance-exam.jpg';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { config } from 'src/config';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination/Pagination';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const OverviewSection: FC < {teamview, teamdata, isFetching}> = ({teamview, teamdata,isFetching})=> {

    return (
        <section className="course-overviews-section ">
            <div className="container aos-init aos-animate" >
               
                <div className="row">
                    <div className="col-lg-12 overviews-course left-exam-section" >
                        <h3 className="main-heading left-align">Meet the <span>Backbones</span></h3>
                        <span dangerouslySetInnerHTML={{ __html: teamview.description }} />
                    </div>
                </div>

                <div className="row">
                    <div className="spac_02"></div>
                    <div className="col-lg-12 top-colleges ourteam" >
                        <h3 className="main-heading left-align">The <span>Board</span></h3>
                        {
                            teamdata && teamdata.filter(o => o.type == 'Board').map((i, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="topcollege-box">
                                        <img alt={i.name} src={ i.photo ? config.baseUrlfromconfig + i.photo : CollegeIMG} className="full_wdth" />
                                        <div className="college-content">
                                            <h3>{i.name}</h3>
                                            <div className="clearfix"></div>
                                            <p>{i.designation}</p>
                                            <div className="college_button_wrp">
                                                <a href={i.linked_in_link}>Connect with LinkedIn</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="spac_02"></div>
                    </div>
                </div>

                <div className="row">
                    <div className="spac_02"></div>
                    <div className="col-lg-12 top-colleges ourteam" >
                        <h3 className="main-heading left-align">The <span>Team</span></h3>
                        {
                            teamdata && teamdata.filter(o => o.type == 'Team').map((i, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-6">
                                    <div className="topcollege-box">
                                        <img alt={i.name} src={ i.photo ? config.baseUrlfromconfig + i.photo : CollegeIMG} className="full_wdth" />
                                        <div className="college-content">
                                            <h3>{i.name}</h3>
                                            <div className="clearfix"></div>
                                            <p>{i.designation}</p>
                                            <div className="college_button_wrp">
                                                <a href={i.linked_in_link}>Connect with LinkedIn</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="spac_02"></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OverviewSection;