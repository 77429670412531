import React, { FC } from 'react';
import './styles.css';
import AllCollegeAdmissionBG from 'src/assets/img/all-college-adminsion-bg.jpg';
import EnquiryFormTalkToExpert from 'src/components/EnquiryFormTalkToExpert';

const Talk: FC = () => {

    return (
        <section className="all-colleges-admission container-fluid spac_01" style={{ background: `#3e6eb0 url(${AllCollegeAdmissionBG})` }} data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 " data-aos="fade-up">
                        <h3 className="colourwhite">Did you Choose a School?</h3>
                        <p>Did you find a desired School for you/ your ward?<br/>Need more Assistance?<br/>Get customised counseling from our Experts now!</p>
                    </div>

                    <div className="col-lg-6 col-md-6 contact-form" data-aos="fade-up">
                        <h2>Talk to Our Experts</h2>
                  
<EnquiryFormTalkToExpert type={'School'}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Talk;