import React, { FC, useRef, useState } from 'react';
import axios from 'src/utils/axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useSnackbar } from 'notistack';
import { Field, Form, Formik, FormikHelpers, useField } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import PhoneNumberField from 'src/components/PhoneInput'
const styles = {
    search: {
        position: "relative",
    }
}

interface EnquiryFormFilterProps {
    type?: any;
}

const EnquiryFormAboutUs: FC<EnquiryFormFilterProps> = ({ type, ...rest }) => {
    const captchaRef = useRef(null)
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const phoneRegExp = /^\+91-[6789]\d{9}$|^\+(?!91-)\d{1,3}-\d{1,10}$/;
    return (
        <Formik
            initialValues={{
                fullname: '',
                phoneNumber: '',
                Email: '',
                dob: '',
                CourseInMind: '',
                College: '',
                gender: '',
                location: '',
                description: ''
            }}
            validationSchema={
                Yup.object().shape({
                    //dob: Yup.date().max(new Date(Date.now()), "Enter valid date").required('Date of birth  is Required'), 
                    fullname: Yup.mixed().required('Name  is Required'),
                    //   gender: Yup.mixed().required('Gender  is Required'),
                    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is required"),
                    Email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                })
            }
            onSubmit={async (values, actions) => {

                const formData = new FormData();
                formData.append('name', values.fullname);
                formData.append('contact', values.phoneNumber);
                formData.append('email', values.Email);
                //  formData.append('gender', values.gender);
                /*  if(values.dob)
                formData.append('dob',values.dob); */

                if (type == 'Location')
                    formData.append('location', values.College);
                else if (type == 'Newsletter')
                    formData.append('Newsletter', '');
                else
                    formData.append('college_name', values.College);

                formData.append('course_in_mind', values.CourseInMind);
                formData.append('location', values.location);
                formData.append('description', values.description);
                //formData.append('location', values.Location);
                // formData.append('description', values.message);
                formData.append('current_url', window.location.href);
                let url = 'api/website/home/enquiry';
                // return alert(JSON.stringify(formData));

                try {
                    let response = await axios.post(url, formData);
                    if (response.data.status) 
                    {
                        actions.setSubmitting(true);
                        actions.resetForm({
                            values: {
                                gender: '',
                                dob: '',
                                fullname: '',
                                phoneNumber: '',
                                Email: '',
                                CourseInMind: '',
                                College: '',
                                location: '',
                                description: ''
                            },
                        });
                        enqueueSnackbar('Thank you. We will get back to you.', {
                            variant: 'success',
                        });
                        history.push("/thank-you");
                    }
                } catch (err) {
                    console.error(err);
                    enqueueSnackbar("Please try again", {
                        variant: 'error',

                    });
                }
                captchaRef.current.reset();
            }}
        >
            {props => (
                <form onSubmit={props.handleSubmit}>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <Field type="text" name="fullname"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullname}
                                className="form-control" id="name" placeholder="Enter Name" />
                            {props.errors.fullname && props.touched.fullname && <p className="error">{props.errors.fullname}</p>}
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-lg-6 form-group">
                            <Field type="email" className="form-control" name="Email"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.Email}
                                id="Email" placeholder="Enter Email"
                            />
                            {props.errors.Email && props.touched.Email && <p className="error">{props.errors.Email}</p>}
                        </div>
                        <div className="col-lg-6 form-group react-phone-p-0">
                        <PhoneNumberField />
                            {props.errors.phoneNumber && props.touched.phoneNumber && <p className="error">{props.errors.phoneNumber}</p>}
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <Field type="text" name="CourseInMind"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.CourseInMind}
                                className="form-control" id="name" placeholder={type == 'aboutus' ? "Enter Course": 'Interested Course?'} />
                            {props.errors.CourseInMind && props.touched.CourseInMind && <p className="error">{props.errors.CourseInMind}</p>}
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <Field type="text" name="location"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.location}
                                className="form-control" id="name" placeholder={type == 'aboutus' ? "Enter Location": 'Location'} />
                            {props.errors.location && props.touched.location && <p className="error">{props.errors.location}</p>}
                        </div>  
                    </div>
                    <div className="row">
                        <div className="col-lg-12 form-group">
                            <textarea className="form-control" name="description"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.description} rows={5} placeholder="Type Your Message" required
                            ></textarea >
                            {props.errors.description && props.touched.description && <p className="error">{props.errors.description}</p>}
                        </div>  
                    </div>
                    {/*   <div className="row">
                        <div className="col-lg-12 form-group">
                        <Field as="select" name="gender"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.gender}
                        className="form-control" id="gender" placeholder="Select gender" >
                        <option value="">Select gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </Field>
                        {props.errors.gender && props.touched.gender && <p className="error">{props.errors.gender}</p>}
                    </div>  </div> */}
                    {/* <div className="row">
                            <div className="col-lg-12 form-group">
                            <Field type="date" name="dob"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.dob}
                            className="form-control" id="dob" placeholder="Date of birth"  />
                            {props.errors.dob && props.touched.dob && <p className="error">{props.errors.dob}</p>}
                        </div>  </div>
                        <div className="row">
                        <div className="col-lg-12 form-group">
                        
                            <Field type="text" className="form-control" name="CourseInMind"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.CourseInMind}
                            id="CourseInMind" placeholder="Course in mind (Optional)" 
                            />   </div>
                        </div> */}
                    <br />
                    <div className="text-center"><button type="submit" className="submit_message">Submit</button></div>
                </form>
            )}
        </Formik>
    );
};

export default EnquiryFormAboutUs;