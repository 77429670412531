import React, { FC } from 'react';
import './styles.css';
import NewsBG from 'src/assets/img/Team1.jpg';
//import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const Header: FC = () => {
    return (
        <section className="inner-top-header" id="hero" style={{ background: ` url(${NewsBG})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1>Our <span>Team</span></h1>
                            <h4 className='bg-dark1'>Get to know the team behind Bangalorestudy.com</h4>
                        </div>
                    </div>
                    <div className="spac_02"></div>
                </div>
            </div>
        </section>
    );
};

export default Header;