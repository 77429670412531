import React, { FC } from 'react';
import './styles.css';
import CourseBg from 'src/assets/img/course-bg.jpg';


const TopCourses: FC<{coursedata:any}> = ({coursedata}) => {
    
    return (
        <section className="explore-top-courses container-fluid  spac_01" style={{background: `#eff3f6 url(${CourseBg})`}}   data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        <h3 className="main-heading"> Top <b>Courses</b></h3>
                        <p ><span dangerouslySetInnerHTML={{ __html: coursedata.description }} /> </p>
                        {/* <div className="spac_02"></div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Management</h2> 
                                    <ul>
                                    <li><a href="">Management Courses</a></li>
                                    <li><a href="">Management Courses</a></li>
                                    <li><a href="">Management Courses</a></li>
                                    <li><a href="">Management Courses</a></li>
                                    <li><a href="">Management Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Architecture</h2> 
                                    <ul>
                                    <li><a href="">Architecture Courses</a></li>
                                    <li><a href="">Architecture Courses</a></li>
                                    <li><a href="">Architecture Courses</a></li>
                                    <li><a href="">Architecture Courses</a></li>
                                    <li><a href="">Architecture Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Engineering</h2> 
                                    <ul>
                                    <li><a href="">Engineering Courses</a></li>
                                    <li><a href="">Engineering Courses</a></li>
                                    <li><a href="">Engineering Courses</a></li>
                                    <li><a href="">Engineering Courses</a></li>
                                    <li><a href="">Engineering Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Vocational</h2> 
                                    <ul>
                                    <li><a href="">Vocational Courses</a></li>
                                    <li><a href="">Vocational Courses</a></li>
                                    <li><a href="">Vocational Courses</a></li>
                                    <li><a href="">Vocational Courses</a></li>
                                    <li><a href="">Vocational Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Pharmacy</h2> 
                                    <ul>
                                    <li><a href="">Pharmacy Courses</a></li>
                                    <li><a href="">Pharmacy Courses</a></li>
                                    <li><a href="">Pharmacy Courses</a></li>
                                    <li><a href="">Pharmacy Courses</a></li>
                                    <li><a href="">Pharmacy Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Commerce</h2> 
                                    <ul>
                                    <li><a href="">Commerce Courses</a></li>
                                    <li><a href="">Commerce Courses</a></li>
                                    <li><a href="">Commerce Courses</a></li>
                                    <li><a href="">Commerce Courses</a></li>
                                    <li><a href="">Commerce Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Agriculture</h2> 
                                    <ul>
                                    <li><a href="">Agriculture Courses</a></li>
                                    <li><a href="">Agriculture Courses</a></li>
                                    <li><a href="">Agriculture Courses</a></li>
                                    <li><a href="">Agriculture Courses</a></li>
                                    <li><a href="">Agriculture Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 text-center" data-aos="fade-up">		           
                                <div className="explore-box">
                                    <h2>Ayurveda</h2> 
                                    <ul>
                                    <li><a href="">Ayurveda Courses</a></li>
                                    <li><a href="">Ayurveda Courses</a></li>
                                    <li><a href="">Ayurveda Courses</a></li>
                                    <li><a href="">Ayurveda Courses</a></li>
                                    <li><a href="">Ayurveda Courses</a></li>
                                    </ul> 
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>	
    );
};

export default TopCourses;