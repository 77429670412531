import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import './styles.css';
import 'animate.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const ExamsSection: FC<{topExamArr:any}> = ({topExamArr}) => {
    const owlOptions = {
        margin: 20, nav: true, autoplay: true,autoplayTimeout: 3000,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true,loop:true},
            400: {items:1, dots:false, nav:true,loop:true},
            600: {items: 1, dots:false, nav:true,loop:true},
            700: {items: 2,loop:true},
          
            1000:{
                items:3,
                nav:true,
                loop:true
            },
            1440:{
                items:4,
                nav:true,
                loop:true
            }
        },
    };

    return (

        <section className="container-fluid spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal exam_section_slider">
                        <h3 className="common_sec_h">Exams</h3>

                        <OwlCarousel {...owlOptions} className="loop_colleges loop_Exams loop_nav owl-carousel owl-theme">
                        {topExamArr.map((el, idx)=>(
                            <div className="item" key={`topexm${idx}`}>
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12 wrapexamcontent">
                                            {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                            <img alt={ el.exam_short_name ? el.exam_short_name : el.exam_title} src={process.env.REACT_APP_IMG_URI+el.cover_image} className="full_wdth" />
                                            <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{el.exam_title}</span>}>
                                                <h3>{ el.exam_short_name ? el.exam_short_name : el.exam_title}</h3>
                                            </BootstrapTooltip>
                                            {/* <h3>{el.name}</h3> */}
                                            {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}
                                            <div className="college_button_wrp">
                                            <GlobalPopupEnquiry />
                                                <a href={`exams/${el.slug}`}>View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="testi_wrp">
                                    <div className="inner_wrp" style={{background:`linear-gradient(359deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) fixed, url(${process.env.REACT_APP_IMG_URI+el.cover_image})`, backgroundSize: 'contain'}}>
                                        <div className="test_content_wrp col-lg-12 text_upper">
                                            <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div>
                                            <span className="short_title">&nbsp;</span>
                                            <h3>{el.exam_title}</h3>
                                            {/* <p>{el.meta_title}</p> 
                                            <div className="college_button_wrp">
                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                        </div>
                                        <div className="overlay_grad"></div>
                                    </div>
                                </div> */}
                            </div>
                        ))}
                        </OwlCarousel>
                    </div>
                </div>
                <div className="button-section text-center">
                    <a href={'/exams'} className="button btn">View All</a>
                </div>
            </div>
        </section>

    );
};

export default ExamsSection;