import React, { FC, useState } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses.jpg';
import { config } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const SearchHeader: FC < {subcourse:any,  collegeslug: any}> = ({subcourse, collegeslug})=> {



let coursesname = subcourse.course  ? subcourse.course.course_stream_name : null;
let collegename = subcourse.college  ? subcourse.college.name : null;

    const imageUrl = subcourse.brochure && subcourse.brochure != null ? config.baseUrlfromconfig + subcourse.brochure : ManagementCourseBG;
    console.log(imageUrl);
    return (
        <section className="inner-top-header" id="hero" style={{ background: `linear-gradient(359deg, rgb(0 0 0 / 80%), rgb(0 0 0 / 80%)) fixed, url(${imageUrl})` }} >
            <div className="hero-container d-flex">
                <div className="container" >
                    <div className="row top-course-block">
                        <div className="top-course-header">
                            <h1 className='collegecourseh1tag'>{coursesname}</h1>
                            <p><a href={`../../../college/${collegeslug}`} style={{color: 'white'}}>{collegename}</a></p>
                            <div className='floatleft'>
                                <GlobalPopupEnquiry  className="apply-now-btn" />
                            </div>
                            
                       
                        </div>
                    </div>
                    <div className="spac_02"></div>
              
                </div>
            </div>
        </section>
    );
};

export default SearchHeader;