import React, { useState } from 'react';
import type { FC } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik,Field,useField} from 'formik';
import { useSnackbar } from 'notistack';

import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Link,
  Button,
  FormHelperText,
  TextField,
  makeStyles,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useAuth from '../../../../src/hooks/useAuth';
import useIsMountedRef from '../../../../src/hooks/useIsMountedRef';
import { Visibility, VisibilityOff } from '@material-ui/icons';

interface JWTLoginProps {
  className?: string;
  updatestateofview?: (type) => void;
}

const useStyles = makeStyles(() => ({
  root: {}
}));

const JWTLoginNew: FC<JWTLoginProps> = ({ className,updatestateofview, ...rest }) => {
  const classes = useStyles();
  const { loginuser } = useAuth() as any;
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { enqueueSnackbar } = useSnackbar();

  const linktoforget = (e): any => { // event is implicitly passed to the function
    e.preventDefault();
    updatestateofview('forgetstep1')
}
  


  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().max(255).required('Username is required'),//email('Must be a valid email')
        password: Yup.string().required('Password is required')//Yup.string().min(8).max(25).required('Password is required')
      })}
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await loginuser(values.email, values.password);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);

            enqueueSnackbar('Successfully Loggedin', {
              variant: 'success',
            });
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            // if(err.success == false)
            // {
            //   setErrors({ submit: err.message });
            // }
            setErrors({ submit: err.message });
            //setErrors({ submit: err.message });
            setSubmitting(false);
            
          enqueueSnackbar(err.message, {
            variant: 'error',});
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >

            
<div className="form-group">

<Field type="text" name="email"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.email}
           className="form-control" id="email" placeholder="Mobile Number or Email Id"  />
         {errors.email && touched.email && <p className="error">{errors.email}</p>}
  </div>
     <div className="form-group">
     <div className="input-group">

     <Field   
      type={showPassword ? "text" : "password"}  name="password"
           onChange={handleChange}
           onBlur={handleBlur}
           value={values.password}
           className="form-control" id="password" placeholder="Password" 
            />
<button className="input-group-text" style={{"height": "59px","background": "white"}}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </button>

</div>

        {errors.password && touched.password && <p className="error">{errors.password}</p>}
            </div>
          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}

<Box
              my={2}
              
            >
           {/*  <Link
                onClick={linktoforget} 
                variant="body2"
                color="error"
                style={{fontSize:'1rem'}}
              >
               Forget Password
          </Link>*/}
            </Box>
          <Box mt={2}>
          <div className="text-center"><button type="submit">Login</button></div>
          </Box>

        </form>
      )}
    </Formik>
  );
};

JWTLoginNew.propTypes = {
  className: PropTypes.string,
  updatestateofview: PropTypes.func,
};

export default JWTLoginNew;
