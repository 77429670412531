import React, { FC, useState } from 'react';
import './styles.css';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import { useParams } from 'react-router';

const useStylesBootstrap = makeStyles(() => ({
    arrow: {
        color: '#000',
    },
    tooltip: {
        backgroundColor: '#000',
    },
}));

function BootstrapTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const TopColleges: FC<{subcourse:any}> = ({ subcourse}) => {
    const queryParams = new URLSearchParams(window.location.search);
    const coursedata = queryParams.get('course');
    const { collegeslug, courseslug } = useParams();
    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    let linkcourse = '/colleges?course=' + courseslug;

    console.log("collegearr");
    console.log(subcourse);

    return (
        <>
        {subcourse && subcourse.length > 0 ? 
            <section className="container-fluid spac_01 topColgs" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 our_portal exam_section_slider examdesignslider courseviewcollege">
                            <h3 className="common_sec_h">Top Colleges</h3>
                            <OwlCarousel {...owlOptions} className="loop_colleges loop_Exams loop_colleges_0 loop_nav owl-carousel owl-theme">
                  

                                {subcourse.map((el, idx)=>(
                                    <>
                                    {el.college_groupss ? 
                                        <>
                                        <div className="item" key={`topexm${idx}`}>
                                            <div className="testi_wrp">
                                                <div className="testi_hover"></div>
                                                <div className="inner_wrp">
                                                    <div className="test_content_wrp col-lg-12 wrapexamcontent">
                                                        {el.college_groupss && el.college_groupss.avg_rating && el.college_groupss.avg_rating!=null ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {el.college_groupss.avg_rating}</div> : '' }
                                                        <img alt={el.college_groupss.name} src={process.env.REACT_APP_IMG_URI+el.college_groupss.logo} className="full_wdth" />
                                                        <BootstrapTooltip placement="top" title={<span style={{ fontSize: "14px" }}>{el.college_groupss?.name}</span>}>
                                                            <h3 className='leftalign'>{el.college_groupss?.name}</h3>
                                                        </BootstrapTooltip>
                                                        
                                                        <div className="college_button_wrp">
                                                            <GlobalPopupEnquiry />
                                                            <a href={`../../college/${el.college_groupss.slug}`}>View Details</a>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        </>
                                    : '' }
                                    </>
                                    
                                ))}

                            </OwlCarousel>
                        </div>
                    </div>
                    <div className="spac_02"></div>
                    <div className="button-section text-center">
                        <a href={linkcourse} className="button btn">See All</a>
                    </div>
                </div>
            </section>
            :
            ('')
        }
        </>
    );
};

export default TopColleges;