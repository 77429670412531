import React, { FC, useEffect, useState, useContext, useCallback } from 'react';
import './styles.css';
import EnquiryDetailForm from 'src/components/EnquiryDetailForm';
import TopManagementCourseBG from 'src/assets/img/top-managment-course.jpg';
import { config } from 'src/config';

const Overview: FC<{ servicesdata: any, promoBannerArr: any }> = ({ servicesdata, promoBannerArr }) => {

    return (
        <section id="entrance-exam-section" className="services course-overviews-section">
            <div className="container aos-init aos-animate" >
                
                <div className="row">
                    <div className="col-lg-8 left-exam-section" >
                        <div className="col-lg-12 overviews-course">
                            <div className="row">
                                <div className="col-lg-12" >
                                    <ul className="nav nav-tabs" id="myTab">
                                        {/* <li className="nav-item"><a href="#overview" className="nav-link active" data-bs-toggle="tab">The Main Things</a></li> */}
                                        <li className="nav-item"><a href="#career_planning" className="nav-link active" data-bs-toggle="tab">For Students</a></li>
                                        {/* <li className="nav-item"><a href="#fees_back_offers" className="nav-link" data-bs-toggle="tab">Fees Back Offers</a></li> */}
                                        {servicesdata.recognition_and_approvals && servicesdata.recognition_and_approvals !='' && servicesdata.recognition_and_approvals !='null' ? 
                                            <li className="nav-item" ><a href="#for_institutions" className="nav-link" data-bs-toggle="tab">For Institutions</a></li>
                                        : ''}
                                    </ul>

                                    <div className="tab-content left-overviews-section">

                                        {/* <div className="tab-pane fade show active" id="overview">
                                            <span dangerouslySetInnerHTML={{ __html: servicesdata.what_we_are_doing }} />
                                        </div> */}
                                        <div className="tab-pane fade show active bs-editor-text" id="career_planning">
                                            <span dangerouslySetInnerHTML={{ __html: servicesdata.career_planning }} />
                                        </div>
                                        {/* <div className="tab-pane fade show" id="fees_back_offers">
                                            <span dangerouslySetInnerHTML={{ __html: servicesdata.fees_back_offers }} />
                                        </div> */}
                                        <div className="tab-pane fade show bs-editor-text"  id="for_institutions">
                                            <span dangerouslySetInnerHTML={{ __html: servicesdata.recognition_and_approvals }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="widgets get-details-now-form right-widget">
                            <h4 className="title-heading">Get <span>Details</span> Now</h4>
                            <EnquiryDetailForm/>
                            {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form">

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Your Name" />
                                </div>

                                <div className="row">
                                    <div className="col-lg-6 form-group">
                                        <input type="email" name="name" className="form-control" id="name" placeholder="Enter Email" />
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <input type="tel" className="form-control" name="email" id="email" placeholder="Enter Number" />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Course" />
                                </div>

                                <div className="form-group">
                                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Enter Location" />
                                </div>

                                <div className="form-group">
                                    <textarea className="form-control" name="message" placeholder="Type Your Message" ></textarea>
                                </div>
                                <div className="text-center"><button type="submit">Submit</button></div>
                            </form> */}
                        </div>

                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}

                        {/* <div className="widgets top-manag-course-widget text-center" style={{ background: `url(${TopManagementCourseBG})` }}>
                            <div className="course-center">
                                <h2>Top Management Courses in Bangalore</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Overview;