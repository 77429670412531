import React, { FC } from 'react';
import './styles.css';

import BengaluruCollege from 'src/assets/img/Bengaluru-college-Karnataka.jpg';
import { config } from 'src/config';

const Breadcrumbs: FC<{collegedetail : any}> = ({collegedetail}) => {
    const [num, setNum] = React.useState(331231);
    const logoUrl = collegedetail.logo ? config.baseUrlfromconfig + collegedetail.logo : BengaluruCollege;
    return (
        <section className="college-locations container-fluid spac_01" data-aos="fade-up">
            <div className="container">
                <div className="row">

                    <h3 className="main-heading textleft"><b>Location</b></h3>
                    <div className="spac_02"></div>

                    <div className="col-lg-1 col-md-1 d-none  d-lg-block d-md-block"></div>
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="college-location border-radius-20">
                            <a href="#"><img src={logoUrl}  alt="Bengaluru-college-Karnataka" className="img-fluid addresslogo" /></a>
                            <div className="college-location-details  blue-bg">
                                <h2>Address</h2>
                                <p><i className="bi bi-pin-map-fill"></i> {collegedetail.address} </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-5  col-sm-12">
                        <div className="border-radius-20 google-map">
                        <iframe src={collegedetail.map} width="100%" height="458" ></iframe>   
                        </div>
                    </div>  <div className="col-lg-1 col-md-1 d-none d-lg-block d-md-block"></div>

                </div>
            </div>
        </section>
    );
};

export default Breadcrumbs;