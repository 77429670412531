import React, { FC, useState } from 'react';
import './styles.css';
import { config } from 'src/config';

import College from 'src/assets/img/college.jpg';
import CourseBG from 'src/assets/img/course-bg.jpg'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';

const TopColleges: FC<{ blogcolleges: any, blogdata: any, showcolleges: any }> = ({ blogcolleges, blogdata, showcolleges }) => {
    
    const owlOptions = {
        margin: 20, nav: true, autoplay: false,
        responsiveClass: true,
        responsive: {
            0: {items:1, dots:false, nav:true},
            400: {items:1, dots:false, nav:true},
            600: {items: 1, dots:false, nav:true},
            700: {items: 2, dots:false, nav:true},
            1000:{
                items:3,
                nav:true,
                loop:false
            },
            1440:{
                items:4,
                nav:true,
                loop:false
            }
        },
    };

    return (
        <>
        {showcolleges ?
            <>
        <section className="top-management-colleges container-fluid spac_01 light-color-bg" style={{ background: `#eff3f6 url(${CourseBG})` }} data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 our_portal">
                        
                        <h3 className="main-heading">Top 10 <b>{blogdata && blogdata.groups ? blogdata.groups.group: ''}</b> Colleges in <b>Bangalore</b></h3>
                        <div className="spac_02"></div>
                        <OwlCarousel {...owlOptions} className="loop_colleges loop_colleges_0 loop_nav owl-carousel owl-theme">
                            {
                                blogcolleges && blogcolleges.map((i,x) => {                                                             
                                    let collegelink = '/college/'+i.college_groupss.slug;
                                    return (
                                        
                                        <div className="item">
                                            <div className="testi_wrp">
                                                <div className="testi_hover"></div>
                                                <div className="inner_wrp">
                                                    <div className="test_content_wrp col-lg-12 wrapblogdetailcontent">
                                                        {i.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {i.avg_rating}</div> : '' }
                                                        <img alt={i.college_groupss.name} src={ i.college_groupss.logo ? config.baseUrlfromconfig + i.college_groupss.logo : College} className="full_wdth" />
                                                        <h3>{i.college_groupss.name}</h3>
                                                        {/* <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p> */}
                                                        <div className="college_button_wrp">
                                                            <GlobalPopupEnquiry className="active_bt" />
                                                            <a href={collegelink}>View Details</a>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                            {/* <div className="item">
                                <div className="testi_wrp">
                                    <div className="testi_hover"></div>
                                    <div className="inner_wrp">
                                        <div className="test_content_wrp col-lg-12">
                                            <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div>
                                            <img src={College} className="full_wdth" />
                                            <h3>Indian Institute of Technology, Bombay</h3>
                                            <p>Lorem ipsum dolor sit amet, conseaunt ut labore et...</p>

                                            <div className="college_button_wrp">

                                                <a href="#" className="active_bt">Apply Now</a><a href="#">View Details</a>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            
                        </OwlCarousel>
                        
                    </div>
                </div>
                <div className="spac_02"></div>
                <div className="button-section text-center">
                    <a href="/colleges" className="button btn">View All</a>
                </div>
            </div>
        </section>
        </>
        : '' }
        </>
    );
};

export default TopColleges;