import React, { FC } from 'react';
import './styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GlobalReviewSection from 'src/components/GlobalReviewSection';
import { config } from 'src/config';
const Breadcrumbs: FC<{ schooldetail: any }> = ({ schooldetail }) => {

    let sidevideo = schooldetail.schoolgallery && schooldetail.schoolgallery.length > 0 || schooldetail.video_url ? { display: "block", columns: "col-lg-8 col-md-12" } : { display: "none", columns: "col-md-12" };
    let allimages = schooldetail.schoolgallery && schooldetail.schoolgallery.length > 0 ? true : false;
    let aaa = schooldetail.video_url ? schooldetail.video_url : "";

    let aaa11 = '';


    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = aaa.match(regExp);

    aaa11 = (match && match[2].length === 11) ? match[2] : null;
    let youtubefullurl = 'https://www.youtube.com/embed/' + aaa11;

    return (
        <section className="course-overviews-section container-fluid  spac_01" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 overviews-course">
                        <div className="row">

                            <div className="col-md-12" >
                                <ul className="nav nav-tabs" id="myTab">
                                    <li className="nav-item"><a href="#overview" className="nav-link active" data-bs-toggle="tab">Info</a></li>
                                    <li className="nav-item"><a href="#ug" className="nav-link" data-bs-toggle="tab">Extracurriculars</a></li>
                                    <li className="nav-item"><a href="#pg" className="nav-link" data-bs-toggle="tab">Reviews</a></li>
                                    <li className="nav-item"><a href="#faq" className="nav-link" data-bs-toggle="tab">FAQs</a></li>
                                    {allimages ? <li className="nav-item"><a href="#gallery" className="nav-link" data-bs-toggle="tab">Gallery</a></li> : ""}

                                </ul>

                                <div className="tab-content left-overviews-section bs-editor-text">

                                    <div className="tab-pane fade show active" id="overview">
                                        <div className="row">
                                            <div className={sidevideo.columns}> <span dangerouslySetInnerHTML={{ __html: schooldetail.about }} /></div>
                                            <div className="col-lg-4 col-md-12 sidebar-widgets " style={{ display: sidevideo.display }}>

                                                {
                                                    schooldetail.video_url && schooldetail.video_url != null ?



                                                        <>
                                                            <div className="widgets text-center p-1">
                                                                <div className="iframe-container">
                                                                    <iframe
                                                                        src={youtubefullurl}
                                                                        className="i-frame-image"
                                                                        frameBorder="0"
                                                                        allowFullScreen
                                                                    ></iframe>
                                                                </div>
                                                            </div>

                                                        </>


                                                        : ''
                                                }



                                            </div>
                                        </div>


                                    </div>

                                    <div className="tab-pane fade pt-0 " id="ug">
                                        <span dangerouslySetInnerHTML={{ __html: schooldetail.extra_curricular }} />
                                    </div>


                                    <div className="tab-pane fade" id="faq">
                                        <h5>FAQs</h5>
                                        {schooldetail.schfaqs && schooldetail.schfaqs.map((i, index) => {
                                            return (
                                                <>
                                                    <Accordion>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id={`panel${index}a-header`}
                                                        >
                                                            <Typography>{index + 1 + "."} {i.questions}</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Typography>
                                                                {i.answers}
                                                            </Typography>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                    {/* <span><b>Question:</b>{i.questions}</span><br></br>
                                                <span><b>Answer:</b> {i.answers}</span><br></br> */}
                                                </>
                                            )
                                        })}

                                    </div>
                                    <div className="tab-pane fade" id="gallery">

                                        <div className="row g-2 g-md-5">
                                            {
                                                schooldetail.schoolgallery && schooldetail.schoolgallery.length > 0 ?

                                                    (schooldetail.schoolgallery.map((val) =>

                                                        <>
                                                            <div className="col-12 col-md-4">

                                                                <div className="testi_wrp">
                                                                    {/* <div className="testi_hover"></div> */}
                                                                    {/* <div className="inner_wrp"> */}
                                                                    <div className="test_content_wrp col-lg-12 wrapexamcontent">

                                                                        <img alt={val.image} src={process.env.REACT_APP_IMG_URI + val.image} className="full_wdth" />


                                                                        <div className="clearfix"></div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                </div>
                                                                {/* <div className="widgets top-manag-course-widget text-center scool-galleryimage" style={{ background: `url(${config.baseUrlfromconfig + val.image})` }}>
                                                                    <div className="course-center">
                                                                        <h2>&nbsp;</h2>
                                                                        <p>&nbsp;</p>

                                                                    </div>
                                                                </div> */}

                                                            </div>
                                                        </>
                                                    ))

                                                    : ''
                                            }
                                        </div>

                                    </div>


                                </div>

                            </div>
                            {/* <div className="col-lg-4 col-md-12 sidebar-widgets " style={{ display: sidevideo.display }}>

                                {
                                    schooldetail.video_url && schooldetail.video_url != null ?



                                        <>
                                            <div className="widgets top-manag-course-widget text-center p-1">
                                                <iframe src={youtubefullurl} style={{ width: '100%', height: '310px' }}></iframe>

                                            </div>
                                        </>


                                        : ''
                                }



                            </div> */}


                        </div>
                    </div>
                </div>




            </div>
        </section>
    );
};

export default Breadcrumbs;