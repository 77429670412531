import React, { FC, useEffect, useState } from 'react';
import './styles.css';
import ManagementCourseBG from 'src/assets/img/management-courses1.jpg';
// import CallNow from 'src/assets/img/call-now.svg';
// import SearchIcon from 'src/assets/img/icons/search.svg';
// import IIITBLOGO from 'src/assets/img/iiitb_logo.png';
import { config, pConfig } from 'src/config';
import GlobalPopupEnquiry from 'src/components/GlobalPopupEnquiry';
import AllianceuniEnquiryForm from 'src/components/AllianceuniEnquiryForm/GlobalPopupEnquiry';
import Helmet from 'react-helmet';
import GetAppIcon from '@material-ui/icons/GetApp';

const SearchHeader: FC<{ coursedetail: any, slug: any }> = ({ coursedetail, slug }) => {

    const [allianceuni, setallianceuni] = useState(false);



    const getcheck = async () => {
        if (/alliance-university-chandapura-bangalore/.test(window.location.href)) {
            setallianceuni(true);
        }
    }


    useEffect(() => {
        getcheck();

    }, []);
    const logoUrl = coursedetail.logo ? config.baseUrlfromconfig + coursedetail.logo : ManagementCourseBG;
    const city_name = coursedetail.city ? coursedetail.city.city_name : '';
    // const collegeRecognition = coursedetail.collegeRecognition ? coursedetail.collegeRecognition: '';
    // console.log(collegeRecognition);
    //console.log('here');
    const affilationheading = coursedetail.college_affiliation && coursedetail.college_affiliation.map((i, index) => {
        const length = coursedetail.college_affiliation.length;
        var custome = '';
        if (length == index + 1) {
            custome = '';
        } else {
            custome = ',';
        }
        return i.affiliationname.other_affiliations_name + custome;
    });

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org/",
                            "@type": "CollegeOrUniversity",
                            "name": `${coursedetail.meta_title}`,
                            "logo": `${logoUrl}`,
                            "url": `${pConfig.siteUrl}university/${slug}`,
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": `${coursedetail.address}`
                            }
                        }
                    )}
                </script>
            </Helmet>
            <section className="inner-top-header removesomepadding" id="hero" style={{ background: `url(${ManagementCourseBG})` }} >
                <div className="hero-container d-flex">
                    <div className="container" >
                        <div className="row college-block">
                            <div className="top-college-header">
                                <h1><img alt={coursedetail.name} src={logoUrl} style={{ width: `80px` }} /> {coursedetail.name} </h1>
                                {coursedetail.avg_rating ? <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> {coursedetail.avg_rating}</div> : ''}
                                {/* <div className="rating_box"><i className="bi bi-star-fill rating_act"></i> 4.8</div> */}
                                <ul className='inlineflex'>
                                    <li><i className="bi bi-pin-map-fill"></i>&nbsp; {city_name}, India</li>
                                    <li>Approvals and Recognitions: <strong>&nbsp;
                                        {coursedetail.collegeRecognition && coursedetail.collegeRecognition.map((i, index) => {
                                            const length = coursedetail.collegeRecognition.length;
                                            var custome = '';
                                            if (length == index + 1) {
                                                custome = '';
                                            } else {
                                                custome = ',';
                                            }
                                            return (
                                                <>
                                                    {i.recognitionname.recognition_approval_name}
                                                    {custome}
                                                </>

                                            )
                                        })}
                                    </strong></li>
                                    <li>Est. Year: <strong>&nbsp;{coursedetail.established}</strong></li>
                                    {affilationheading && affilationheading != '' ?
                                        <>
                                            <li>Affiliation:&nbsp;<strong>{affilationheading}</strong></li>
                                        </>
                                        : ''}
                                    <li>Genders Accepted.:&nbsp;<strong>{coursedetail.genders_accepted}</strong></li>
                                    <li>Ownership:&nbsp;<strong>{coursedetail.college_type}</strong></li>
                                    <li>Campus Size:&nbsp;<strong>{coursedetail.campus_size} {coursedetail.campus_size_type}</strong></li>
                                </ul>
                                <div className="spac_02"></div>
                                <div className="col-lg-12 button-section college-button-download">
                                    {/* <GlobalPopupEnquiry className="medical-button btn" /> */}


                                    {allianceuni ? <AllianceuniEnquiryForm className="medical-button btn" />:<GlobalPopupEnquiry className="medical-button btn" />}
                                    <a href="#reviewssection" className="reviews-button btn">Write a Review</a>
                                    <GlobalPopupEnquiry pagename={'Download Brochure'} className="broucher-button btn" title={<><GetAppIcon />Download Brochure</>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SearchHeader;